import { React, createRef } from "react";
import { Component } from "react";
import { axiosConfig, ValidateEmail, normalize } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listSubClients,
  resendActivation,
  updateSubClient,
  listSubClientRights,
  addSubCLientRights,
  ListSubClientAssignRoles,
  editSubClientAssignRole,
} from "./../../../config/client_api_calls";

import $ from "jquery";

import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/build/index.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Select from "react-select";
import countryList from "react-select-country-list";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const auth = new HelperClass();

class SubClients extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      serviceData: [],
      errorMessage: "",
      serviceId: "",
      successMessage: "",
      errorMessageEdit: "",
      successMessageEdit: "",
      errorMessageRights: "",
      successMessageRights: "",
      waitMessage: "",
      tableLoader: true,
      tableLoaderListing: true,
      activation_button: false,
      activation_tick: false,
      activation_cross: false,
      client_accountno: "",
      subclient_accountno: "",
      client_name: "",
      client_email: "",
      client_phone: "",
      client_address: "",
      client_address2: "",
      client_comp: "",
      client_title: "",
      client_city: "",
      client_state: "",
      client_zip: "",
      client_country: "",
      client_status: "",
      client_password: "",
      client_c_password: "",
      allRights: [],
      assignedMainRights: [],
      assignedSubRights: [],
      passwordShown: false,
      confirmPasswordShown: false,
      notifyCheckBox: true,
      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      assignRoleData: [],
      assignSubRoleData: [],
      changedRolesRights: [],
      changedSubRolesRights: [],
      errorMessageSubClientRole: "",
      successMessageSubClientRole: "",
      subassignRoleData: [],
      subassignSubRoleData: [],
      subchangedRolesRights: [],
      subchangedSubRolesRights: [],
      checkedRoleIds: [],
      checkedSubRoleIds: [],
      checkedName: "",
      checkedEmail: "",

      errorName: "",
      errorClientStatus: "",
      errorClientCompany: "",
      errorClientEmail: "",
      errorClientPhone: "",
      errorClientAddress: "",
      errorClientCity: "",
      errorClientState: "",
      errorClientZip: "",
      errorClientCountry: "",
      errorClientPassword: "",
      errorCPassword: "",

      useGooglePlaces: true,
      toggleButtonText: "Allow PO Box Address",

      columnServices: [
        { name: "Name" },
        { name: "Company" },
        {
          name: "Phone",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatPhoneNumber(value)}</div>;
            },
          },
        },
        { name: "Email" },
        {
          name: "Date Added",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{auth.formatDateTime(value)}</div>;
            },
          },
        },
        {
          name: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setStatus(value, tableMeta)}</div>;
            },
          },
        },
        { name: "ID", options: { display: "false" } },
        { name: "Address", options: { display: "false" } },
        { name: "Address2", options: { display: "false" } },
        { name: "Comp", options: { display: "false" } },
        { name: "City", options: { display: "false" } },
        { name: "State", options: { display: "false" } },
        { name: "Zip", options: { display: "false" } },
        { name: "Country", options: { display: "false" } },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.showDetails(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Service Details"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>Edit Sub Client</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.subclientAssignRole(value, tableMeta)
                                  }
                                  title="Assign Role"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-grid-c"></em>
                                  <span>Assign Role</span>
                                </a>
                              </li>
                              {tableMeta.rowData[5] === "inactive" ? (
                                <li>
                                  <a
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    onClick={() =>
                                      this.resendActivation(value, tableMeta)
                                    }
                                    title="Resend Activation"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <em className="icon ni ni-signin"></em>

                                    <span>Resend Activation</span>
                                  </a>
                                </li>
                              ) : null}
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
    this.phoneInputRef = createRef();
    this.countryOptions = countryList()
      .getData()
      .filter(
        (country) => country.label !== "United States Minor Outlying Islands"
      );
  }

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  formatPhoneNumber = (phoneNumber) => {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
    if (phoneNumberObj) {
      if (phoneNumberObj.country === "US") {
        const nationalNumber = phoneNumberObj
          .formatNational()
          .replace(/\D/g, ""); // e.g. "2123727200"
        const areaCode = nationalNumber.slice(0, 3);
        const firstPart = nationalNumber.slice(3, 6);
        const secondPart = nationalNumber.slice(6);
        return `(${areaCode}) ${firstPart}-${secondPart}`;
      }
      return phoneNumberObj.formatInternational();
    }
    return phoneNumber; // Return original number if parsing fails
  };

  subclientAssignRole = async (value, tableMeta) => {
    const agentId = tableMeta.rowData[6];
    const agentEmail = tableMeta.rowData[3];
    this.setState({
      errorMessageSubClientRole: "",
      successMessageSubClientRole: "",
      subassignRoleData: [],
      subassignSubRoleData: [],
      subchangedRolesRights: [],
      subchangedSubRolesRights: [],
      subclientRoleLoader: true,
      subdisableRoleBtn: false,
      subdisableSubRoleBtn: false,
      rolesSubClientName:
        tableMeta.rowData[0] + " (" + tableMeta.rowData[1] + ")",
      checkedName: tableMeta.rowData[0],
      checkedEmail: tableMeta.rowData[3],
    });
    window.$("#modalSubClientAssignRole").modal("show");
    this.getSubClientRoleData(agentId, agentEmail);
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      useGooglePlaces: !prevState.useGooglePlaces,
      toggleButtonText:
        prevState.toggleButtonText === "Allow PO Box Address"
          ? "Allow Simple Address"
          : "Allow PO Box Address",
    }));
  };

  getSubClientRoleData = async (agentId, agentEmail) => {
    const listSubClientAssignRoles = await ListSubClientAssignRoles(
      auth.getAccount(),
      auth.getToken(),
      agentId,
      agentEmail
    );

    console.log("ListSubClientRoles Res: ", listSubClientAssignRoles.data.data);
    if (
      listSubClientAssignRoles.data.status === 403 ||
      listSubClientAssignRoles.data.errors === "authentication missing" ||
      listSubClientAssignRoles.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listSubClientAssignRoles.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      listSubClientAssignRoles.data.status === 200 &&
      listSubClientAssignRoles.data.message === "success"
    ) {
      const assignRoleData = listSubClientAssignRoles.data.data.roles;
      const assignSubRoleData = listSubClientAssignRoles.data.data.subroles;
      const allRoleData = listSubClientAssignRoles.data.data.Allroles.map(
        (role) => {
          const isActiveRole = assignRoleData.some(
            (assignedRole) =>
              assignedRole.alt === role.alt && assignedRole.isactive === 1
          );
          return {
            ...role,
            isChecked: isActiveRole,
          };
        }
      );

      const allSubRoleData = listSubClientAssignRoles.data.data.Allsubroles.map(
        (subRole) => {
          const isActiveSubRole = assignSubRoleData.some(
            (assignedSubRole) =>
              assignedSubRole.alt === subRole.alt &&
              assignedSubRole.isactive === 1
          );
          return {
            ...subRole,
            isChecked: isActiveSubRole,
          };
        }
      );

      this.setState({
        assignRoleData,
        assignSubRoleData,
        allRoleData,
        allSubRoleData,
        subclientRoleLoader: false,
      });

      const initialActiveRoles = assignRoleData
        .filter((role) => role.isactive === 1)
        .map((role) => ({
          alt: role.alt,
          isChecked: true,
        }));

      const initialActiveSubRoles = assignSubRoleData
        .filter((subRole) => subRole.isactive === 1)
        .map((subRole) => ({
          id: subRole.id,
          isChecked: true,
        }));

      this.setState({
        changedRolesRights: initialActiveRoles,
        changedSubRolesRights: initialActiveSubRoles,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  changedAllRolesRights = (event) => {
    const { name, checked } = event.target;

    // Update checkedRoleIds in the state
    const { checkedRoleIds } = this.state;
    const updatedCheckedRoleIds = checked
      ? [...checkedRoleIds, name] // add ID if checked
      : checkedRoleIds.filter((id) => id !== name); // remove ID if unchecked

    this.setState({ checkedRoleIds: updatedCheckedRoleIds });

    // Update allRoleData with isChecked property
    const { allRoleData, allSubRoleData } = this.state;
    const updatedAllRoleData = allRoleData.map((role) => {
      if (role.id.toString() === name) {
        return {
          ...role,
          isChecked: checked,
        };
      }
      return role;
    });

    // Update associated sub roles
    const updatedAllSubRoleData = allSubRoleData.map((subRole) => {
      if (subRole.role_id.toString() === name) {
        return {
          ...subRole,
          isChecked: checked,
        };
      }
      return subRole;
    });

    this.setState({
      allRoleData: updatedAllRoleData,
      allSubRoleData: updatedAllSubRoleData,
    });
  };

  changedAllSubRolesRights = (event) => {
    const { name, checked } = event.target;

    // Update checkedSubRoleIds in the state
    const { checkedSubRoleIds } = this.state;
    const updatedCheckedSubRoleIds = checked
      ? [...checkedSubRoleIds, name] // add ID if checked
      : checkedSubRoleIds.filter((id) => id !== name); // remove ID if unchecked

    this.setState({ checkedSubRoleIds: updatedCheckedSubRoleIds });

    // Update allSubRoleData with isChecked property
    const { allSubRoleData } = this.state;
    const updatedAllSubRoleData = allSubRoleData.map((subRole) => {
      if (subRole.tabdetailid.toString() === name) {
        return {
          ...subRole,
          isChecked: checked,
        };
      }
      return subRole;
    });

    this.setState({ allSubRoleData: updatedAllSubRoleData });
  };

  handleSubmitRoleCheckboxSub = async (name, email) => {
    this.setState({
      errorMessageSubClientRole: "",
      successMessageSubClientRole: "",
      subdisableRoleBtn: true,
    });

    const { allRoleData, allSubRoleData } = this.state;

    const CheckRoleFields = allRoleData.map((role) => ({
      id: role.id,
      isactive: role.isChecked ? 1 : 0,
    }));

    const CheckSubRoleFields = allSubRoleData.map((role) => ({
      id: role.tabdetailid,
      isactive: role.isChecked ? 1 : 0,
    }));

    const saveResponce = await editSubClientAssignRole(
      auth.getAccount(),
      auth.getToken(),
      CheckRoleFields,
      CheckSubRoleFields,
      name,
      email
    );

    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (saveResponce.data.status === 404) {
      // window.location.replace("/error");
      this.setState({
        errorMessageSubClientRole:
          "There is some error while updating the Sub Client Role.",
        successMessageSubClientRole: "",
        subdisableRoleBtn: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessageSubClientRole: "",
        successMessageSubClientRole: "Sub Client Role updated successfully.",
        subdisableRoleBtn: false,
      });
      setTimeout(() => {
        //this.props.props.history.push("/agent_roles");
        window.$("#modalSubClientAssignRole").modal("hide");
      }, 3000);
    } else {
      this.setState({
        errorMessageSubClientRole: saveResponce.data.message,
        successMessageSubClientRole: "",
        subdisableRoleBtn: false,
      });
      //window.location.replace("/error");
    }
  };

  setStatus = (value, tableMeta) => {
    let domain_str = tableMeta.rowData[5];
    //console.log("domain_str: ", domain_str);
    if (domain_str === "active") {
      return (
        <div>
          <span className="badge badge-outline-secondary">
            {" "}
            <em className="icon ni ni-done" title="Active"></em> Active
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-outline-danger">
            {" "}
            <em className="icon ni ni-alert-c"></em> Inactive
          </span>
        </div>
      );
    }
  };

  defaultText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = tableMeta.rowData[5];
    if (is_default === "No") {
      return (
        <a
          style={{ cursor: "pointer" }}
          onClick={() => this.setDefaultService(value, tableMeta)}
          disabled={
            this.props.is_msa_signed === "No" ||
            this.props.defaultSettings === "missing"
              ? true
              : false
          }
        >
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-signin"></em> Make Default
          </span>
        </a>
      );
    } else {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    }
  };

  defaultMarketing = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = value.split("###");
    let marketing_content = is_default[0];
    let marketing_link = is_default[1];
    if (marketing_content === "link") {
      return (
        <a href={marketing_link} terget="_blank">
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-signin"></em> Product Info
          </span>
        </a>
      );
    } else if (marketing_content === "pdf") {
      return (
        <a href={marketing_link} terget="_blank">
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-download"></em> View PDF
          </span>
        </a>
      );
    } else {
      return "";
    }
  };

  async componentDidMount() {
    const servicesResponce = await listSubClients(
      auth.getClientAccount(),
      auth.getClientToken()
    );
    console.log("Available Sub Clients: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else if (servicesResponce.data.status === 200) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else {
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
      //window.location.replace("/error");
    }
  }

  setMarkUp = async (value, tableMeta) => {
    let service_id = tableMeta.rowData[0];

    window.$("#modalForm").modal("show");
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  /*formatPhoneNumber = (value, tableMeta) => {
    const cleaned = ("" + value).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return value;
  };*/

  phoneUtil = PhoneNumberUtil.getInstance();

  isPhoneValid = (phone) => {
    try {
      return this.phoneUtil.isValidNumber(
        this.phoneUtil.parseAndKeepRawInput(phone)
      );
    } catch (error) {
      return false;
    }
  };

  handleFocus = () => {
    // Move cursor to the end of the input value
    const input = this.phoneInputRef.current;
    if (input) {
      const value = input.value;
      input.setSelectionRange(value.length, value.length);
    }
  };

  handleAddressSelect = async (place) => {
    const { description } = place.value;
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          description
        )}&key=${process.env.REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY}`
      );
      const data = await response.json();

      if (data.status === "OK") {
        const addressComponents = data.results[0].address_components;
        const formattedAddress = data.results[0].formatted_address;
        let city = null;
        let state = null;
        let country = null;
        let zipCode = null;
        let streetAddress = "";
        let neighborhood = null;
        let locality = null;
        let address2 = "";
        addressComponents.forEach((component) => {
          if (component.types.includes("neighborhood")) {
            neighborhood = component.long_name;
          } else if (component.types.includes("locality")) {
            locality = component.long_name;
          } else if (component.types.includes("administrative_area_level_1")) {
            state = component.short_name;
          } else if (component.types.includes("country")) {
            country = component.long_name;
          } else if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
          } else if (component.types.includes("subpremise")) {
            address2 = component.long_name;
          } else if (
            ![
              "neighborhood",
              "sublocality",
              "sublocality_level_1",
              "sublocality_level_2",
              "administrative_area_level_2",
              "administrative_area_level_3",
              "postal_code_suffix",
            ].includes(component.types[0])
          ) {
            if (streetAddress !== "") {
              streetAddress += " ";
            }
            streetAddress += component.long_name;
          }
        });
        // Check if neighborhood is part of the formatted address
        if (neighborhood && formattedAddress.includes(neighborhood)) {
          city = neighborhood;
        } else {
          city = locality;
        }
        this.setState({
          client_city: city && city !== "" ? city : "",
          client_state: state && state !== "" ? state : "",
          client_zip: zipCode && zipCode !== "" ? zipCode : "",
          client_country: country && country !== "" ? country : "",
          client_address:
            streetAddress && streetAddress !== "" ? streetAddress : "",
          client_address2: address2 && address2 !== "" ? address2 : "",
        });
      } else {
        console.error("Reverse geocoding request failed:", data.status);
      }
    } catch (error) {
      console.error("Error fetching reverse geocoding data:", error);
    }
  };

  handleChangeCountry = (event) => {
    console.log("event.label: ", event.label);
    const country = event.label;
    this.setState({
      client_country: country,
    });
  };

  resendActivation = async (value, tableMeta) => {
    let client_accountno = tableMeta.rowData[6];
    this.setState({
      activation_button: true,
      activation_tick: false,
      activation_cross: false,
    });
    window.$("#modalAlertResend").modal("show");
    const kycResponce = await resendActivation(
      auth.getClientAccount(),
      auth.getClientToken(),
      client_accountno
    );
    //console.log("resendActivation: ", kycResponce.data);
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: true,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_found"
    ) {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: true,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      this.setState({
        activation_button: false,
        activation_tick: true,
        activation_cross: false,
      });
    } else {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: true,
      });
    }
    setTimeout(() => {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: false,
      });
      window.$("#modalAlertResend").modal("hide");
    }, 4000);
  };

  showDetails = async (value, tableMeta) => {
    let client_accountno = tableMeta.rowData[6];
    let client_name = tableMeta.rowData[0];
    let client_email = tableMeta.rowData[3];
    let client_phone = tableMeta.rowData[2];
    let client_status = tableMeta.rowData[5];
    let client_address = tableMeta.rowData[7];
    let client_address2 = tableMeta.rowData[8];
    let client_comp = tableMeta.rowData[9];
    let client_city = tableMeta.rowData[10];
    let client_state = tableMeta.rowData[11];
    let client_zip = tableMeta.rowData[12];
    let client_country = tableMeta.rowData[13];
    let client_title = tableMeta.rowData[14];

    this.setState({
      client_accountno: client_accountno,
      client_name: client_name,
      client_email: client_email,
      client_phone: client_phone,
      client_status: client_status,
      client_address: client_address,
      client_address2: client_address2,
      client_comp: client_comp,
      client_city: client_city,
      client_state: client_state,
      client_zip: client_zip,
      client_country: client_country,
      client_title: client_title,
    });

    window.$("#modalFormDetail").modal("show");
  };

  client_rights = async (value, tableMeta) => {
    console.log("client_rights: ", value);
    this.setState({
      tableLoaderListing: true,
    });
    window.$("#modalFormRights").modal("show");
    const servicesResponce = await listSubClientRights(
      auth.getClientAccount(),
      auth.getClientToken(),
      value
    );
    console.log("Client Rights Data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        tableLoaderListing: false,
        errorMessageRights: "There is some error while getting the rights.",
      });
    } else if (servicesResponce.data.status === 200) {
      this.setState({
        subclient_accountno: value,
        allRights: servicesResponce.data.allRights,
        assignedMainRights: servicesResponce.data.assignedMainRights,
        assignedSubRights: servicesResponce.data.assignedSubRights,
        tableLoaderListing: false,
      });
    } else {
      this.setState({
        tableLoaderListing: false,
        errorMessageRights: "There is some error while getting the rights.",
      });
      //window.location.replace("/error");
    }
  };

  formSaveRights = async () => {
    let { client_accountno, client_parent, subclient_accountno } = this.state;
    var sub_selected_menus = [];
    var main_selected_menus = $("input:checkbox.vdrSelected:checked")
      .map(function () {
        sub_selected_menus = $(
          "input:checkbox.vdrSelectedSub" + this.value + ":checked"
        )
          .map(function () {
            return this.value;
          })
          .get();
        console.log("sub_selected_menus: ", sub_selected_menus);
        let insideArray = {
          main_id: this.value,
          subclient_accountno: subclient_accountno,
          submenus: sub_selected_menus,
        };
        return insideArray;
      })
      .get();

    console.log("main_selected_services: ", main_selected_menus);

    const servicesResponce = await addSubCLientRights(
      auth.getClientAccount(),
      auth.getClientToken(),
      subclient_accountno,
      JSON.stringify(main_selected_menus)
    );
    console.log("Add Rights Result: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "client_not_found"
    ) {
      //window.location.replace("/error");
      this.setState({
        successMessageRights: "",
        disabled: false,
        errorMessageRights: "Sub client not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        successMessageRights: "Rights added successfully.",
        errorMessageRights: "",
        disabled: false,
      });
    } else {
      this.setState({
        successMessageRights: "",
        disabled: false,
        errorMessageRights: "There is some error while adding the rights.",
      });
    }
    setTimeout(() => {
      this.setState({
        successMessageRights: "",
        disabled: false,
        errorMessageRights: "",
      });
    }, 4000);
  };

  phoneUtil = PhoneNumberUtil.getInstance();

  isPhoneValid = (phone) => {
    try {
      return this.phoneUtil.isValidNumber(
        this.phoneUtil.parseAndKeepRawInput(phone)
      );
    } catch (error) {
      return false;
    }
  };

  formUpdateSubmit = async () => {
    let {
      client_title,
      client_name,
      client_email,
      client_password,
      client_c_password,
      client_phone,
      client_accountno,
      client_comp,
      client_city,
      client_state,
      client_zip,
      client_address,
      client_address2,
      client_country,
      client_status,
    } = this.state;
    this.setState({
      successMessageEdit: "",
      errorMessageEdit: "",
      errorName: "",
      errorClientStatus: "",
      errorClientCompany: "",
      errorClientEmail: "",
      errorClientPhone: "",
      errorClientAddress: "",
      errorClientCity: "",
      errorClientState: "",
      errorClientZip: "",
      errorClientCountry: "",
      errorClientPassword: "",
      errorCPassword: "",
    });
    const modalBody = document.getElementById("modalFormDetail");
    if (modalBody) {
      setTimeout(() => {
        modalBody.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 0);
    }

    let is_validated = true;
    if (client_name === "") {
      this.setState({
        errorName: "error",
        errorMessageEdit: "Client Name is required.",
      });
      is_validated = false;
    } else if (client_comp === "") {
      this.setState({
        errorName: "",
        errorClientCompany: "error",
        errorMessageEdit: "Company is required.",
      });
      is_validated = false;
    } else if (client_phone === "") {
      this.setState({
        errorClientCompany: "",
        errorClientPhone: "error",
        errorMessageEdit: "Phone is required.",
      });
      is_validated = false;
    } else if (!this.isPhoneValid(this.state.client_phone)) {
      this.setState({
        errorClientCompany: "",
        errorClientPhone: "error",
        errorMessageEdit: "Invalid phone format.",
      });
      is_validated = false;
    } else if (this.state.client_address === "") {
      this.setState({
        errorClientPhone: "",
        errorClientAddress: "error",
        errorMessageEdit: "Address is required.",
      });
      is_validated = false;
    } else if (this.state.client_city === "") {
      this.setState({
        errorClientAddress: "",
        errorClientCity: "error",
        errorMessageEdit: "City is required.",
      });
      is_validated = false;
    } else if (this.state.client_state === "") {
      this.setState({
        errorClientCity: "",
        errorClientState: "error",
        errorMessageEdit: "State is required.",
      });
      is_validated = false;
    } else if (this.state.client_zip === "") {
      this.setState({
        errorClientState: "",
        errorClientZip: "error",
        errorMessageEdit: "Zip is required.",
      });
      is_validated = false;
    } else if (this.state.client_country === "") {
      this.setState({
        errorClientZip: "",
        errorClientCountry: "error",
        errorMessageEdit: "Country is required.",
      });
      is_validated = false;
    } else if (client_password !== "") {
      if (client_password.length < 8) {
        this.setState({
          errorClientCountry: "",
          errorClientPassword: "error",
          errorMessageEdit: "Use 8 characters or more for your password",
        });
        is_validated = false;
      } else if (client_c_password === "") {
        this.setState({
          errorClientPassword: "",
          errorCPassword: "error",
          errorMessageEdit: "Confirm password is required.",
        });
        is_validated = false;
      } else if (client_c_password.length < 8) {
        this.setState({
          errorClientPassword: "",
          errorCPassword: "error",
          errorMessageEdit:
            "Use 8 characters or more for your confirm password",
        });
        is_validated = false;
      } else if (client_password.trim() !== client_c_password.trim()) {
        this.setState({
          errorClientPassword: "",
          errorCPassword: "error",
          errorMessageEdit: "Password and confirm password do not match.",
        });
        is_validated = false;
      }
    }
    if (is_validated) {
      let apiResponce = await updateSubClient(
        auth.getClientAccount(),
        auth.getClientToken(),
        client_name,
        client_title,
        client_email,
        client_password,
        client_phone,
        client_accountno,
        client_address,
        client_address2,
        client_zip,
        client_country,
        client_city,
        client_state,
        client_comp,
        client_status,
        this.state.notifyCheckBox.toString()
      );

      // Check the response correctly
      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 409 &&
        apiResponce.data.message === "Address validation failed"
      ) {
        const formattedErrorMessage = apiResponce.data.errors
            ? String(apiResponce.data.errors).replace(/,/g, "<br />")
            : "Address validation failed";
        this.setState({
          errorMessageEdit: formattedErrorMessage,
          disabled: false,
        });
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "Success"
      ) {
        this.setState({
          successMessageEdit: "Sub client updated successfully.",
          tableLoader: false,
        });
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "already_exists"
      ) {
        this.setState({
          errorMessageEdit:
            "Email already exists. Please try a different email address.",
          disabled: false,
          tableLoader: false,
        });
        return false;
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "fail"
      ) {
        this.setState({
          errorMessageEdit: "Something went wrong, try again later.",
          disabled: false,
          tableLoader: false,
        });
        return false;
      } else {
        this.setState({
          errorMessageEdit: "Something went wrong, try again later.",
          disabled: false,
          tableLoader: false,
        });
      }
    }

    setTimeout(() => {
      this.setState({
        errorMessageEdit: "",
        successMessageEdit: "",
      });
    }, 5000);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  calculatePercent = (percent, num) => {
    return (percent / 100) * num;
  };
  modalHide = () => {
    window.$("#modalFormDetail").modal("hide");
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(
      "/" + auth.getResellerIdentifier() + "/" + link
    );
  };

  togglePassword = () => {
    this.setState({
      passwordShown: !this.state.passwordShown,
    });
  };

  toggleConfirmPassword = () => {
    this.setState({
      confirmPasswordShown: !this.state.confirmPasswordShown,
    });
  };

  render() {
    const { useGooglePlaces, toggleButtonText } = this.state;
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div className="nk-content " id="SubClients_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle name="Sub CLients" icon="icon ni ni-users" />
                    </h3>
                  </div>
                  {auth.getUserTypeClient() === "Super" ? (
                    <>
                      <div className="nk-block-head-content" id="HeadContent">
                        <div className="toggle-wrap nk-block-tools-toggle">
                          <a
                            href="#"
                            className="btn btn-icon btn-trigger toggle-expand mr-n1"
                            data-target="pageMenu"
                          >
                            <em className="icon ni ni-more-v"></em>
                          </a>
                          <div
                            className="toggle-expand-content"
                            data-content="pageMenu"
                          >
                            <ul className="nk-block-tools g-3">
                              <li className="nk-block-tools-opt">
                                {this.props.defaultSettings !== "missing" ? (
                                  <a
                                    onClick={() =>
                                      this.addMenuHistory(
                                        "client/SubClients/AddSubClient"
                                      )
                                    }
                                    style={{
                                      color: "#fff",
                                    }}
                                    className="btn btn-primary"
                                  >
                                    <em className="icon ni ni-plus-round"></em>
                                    <span>Add New Sub Client</span>
                                  </a>
                                ) : null}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          {/* START DATATABLE */}
                          {this.state.tableLoader === true ? (
                            tableLoader()
                          ) : (
                            <DataTableExtended
                              columns={this.state.columnServices}
                              tableData={this.state.tableData}
                              title="All Sub Clients"
                            />
                          )}

                          {/* END TABLE */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
              <div class="modal fade" tabindex="-1" id="modalAlertResend">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <a href="#" class="close" data-dismiss="modal">
                      <em class="icon ni ni-cross"></em>
                    </a>
                    <div class="modal-body modal-body-lg text-center">
                      <div class="nk-modal">
                        {this.state.activation_button === true ? (
                          <>
                            <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-warning"></em>
                            <h4 class="nk-modal-title">Sending!</h4>
                            <div class="nk-modal-text">
                              <div class="caption-text">
                                Please wait while we are processing your request
                              </div>
                              <span class="sub-text-sm"></span>
                            </div>
                            <div class="nk-modal-action"></div>
                          </>
                        ) : null}
                        {this.state.activation_tick === true ? (
                          <>
                            <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                            <h4 class="nk-modal-title">Congratulations!</h4>
                            <div class="nk-modal-text">
                              <div class="caption-text">
                                Activation email has been sent successfully.
                              </div>
                              <span class="sub-text-sm"></span>
                            </div>
                            <div class="nk-modal-action"></div>
                          </>
                        ) : null}
                        {this.state.activation_cross === true ? (
                          <>
                            <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                            <h4 class="nk-modal-title">Oops!</h4>
                            <div class="nk-modal-text">
                              <div class="caption-text">
                                There is some error while sending activation
                                email. Please try again later
                              </div>
                              <span class="sub-text-sm"></span>
                            </div>
                            <div class="nk-modal-action"></div>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div class="modal-footer bg-lighter">
                      <div class="text-center w-100">
                        <a
                          href="#"
                          class="btn btn-lg btn-mw btn-primary"
                          data-dismiss="modal"
                        >
                          Close
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ACTIVATION MODAL END */}
              {/* EDIT MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalFormDetail">
                <div className="modal-dialog modal-xl" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {" "}
                        <em className="icon ni ni-edit"></em> Edit Sub Client
                      </h5>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* Error Start */}
                      {this.state.errorMessageEdit !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              this.state.errorMessageEdit,
                                          }}
                                        ></p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessageEdit !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessageEdit}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />
                      <div className="row g-4">
                        <form method="post" encType="multipart/form-data">
                          <div className="row g-4">
                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="client_name"
                                name="client_name"
                                type="text"
                                label="Sub Client Name"
                                inputProps={{ maxLength: 100 }}
                                value={this.state.client_name}
                                onChange={this.handleChange}
                                helperText="Enter the sub client name."
                                variant="outlined"
                                fullWidth
                                error={this.state.errorName === "error"}
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="client_email"
                                name="client_email"
                                type="email"
                                label="Email Address"
                                value={this.state.client_email}
                                onChange={this.handleChange}
                                helperText="Please note that the email address provided is read-only."
                                variant="outlined"
                                disabled
                                InputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-3">
                              <MutextField
                                id="client_title"
                                name="client_title"
                                type="text"
                                inputProps={{ maxLength: 30 }}
                                label="Client Title"
                                value={this.state.client_title}
                                onChange={this.handleChange}
                                helperText="Enter the title here."
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-3">
                              <MutextField
                                required
                                id="client_comp"
                                name="client_comp"
                                type="text"
                                inputProps={{ maxLength: 30 }}
                                label="Client Company"
                                value={this.state.client_comp}
                                onChange={this.handleChange}
                                helperText="Enter the company here."
                                variant="outlined"
                                fullWidth
                                error={
                                  this.state.errorClientCompany === "error"
                                }
                              />
                            </div>

                            <div className="form-group col-md-6">
                              {/*<PhoneInput
                                style={{ height: "70%" }}
                                required
                                defaultCountry={"us"}
                                error={this.state.errorPhone}
                                inputProps={{
                                  name: "client_phone",
                                  id: "client_phone",
                                  maxLength: 20,
                                }}
                                value={this.state.client_phone}
                                onChange={(value) =>
                                  this.handleChange({
                                    target: { name: "client_phone", value },
                                  })
                                }
                                helperText="Enter the client_phone number."
                                label="client_phone Number"
                              />*/}
                              <PhoneInput
                                style={{ height: "71%" }}
                                required
                                defaultCountry={"us"}
                                error={this.state.errorPhone}
                                inputProps={{
                                  name: "client_phone",
                                  id: "client_phone",
                                  maxLength: 20,
                                  className: this.state.errorClientPhone
                                    ? "error-border"
                                    : "default-border",
                                }}
                                dialCodePreviewStyleProps={{
                                  className: this.state.errorClientPhone
                                    ? "error-border"
                                    : "default-border",
                                }}
                                value={this.state.client_phone}
                                onChange={(value) =>
                                  this.handleChange({
                                    target: { name: "client_phone", value },
                                  })
                                }
                                helperText="Enter the Client phone number."
                                label="Agent Phone Number"
                                ref={this.phoneInputRef}
                                onFocus={this.handleFocus}
                              />
                            </div>

                            <div className="form-group col-md-6 mt-md-0 googleLocation-autocomplete-wrapper">
                              <div
                                class="nk-block-between-md g-4"
                                style={{
                                  position: "absolute",
                                  zIndex: 9999,
                                  right: "13px",
                                  top: "-15px",
                                }}
                              >
                                <div class="nk-block-head-content"></div>
                                <div class="nk-block-head-content">
                                  <ul class="nk-block-tools gx-3">
                                    <li>
                                      <a
                                        onClick={this.handleToggle}
                                        class="text-primary fs-11"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "11px",
                                        }}
                                      >
                                        {toggleButtonText}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div>
                                {useGooglePlaces ? (
                                  <>
                                    <GooglePlacesAutocomplete
                                      apiKey={
                                        process.env
                                          .REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY
                                      }
                                      selectProps={{
                                        value: this.state.client_address
                                          ? {
                                              label: this.state.client_address,
                                              value: this.state.client_address,
                                            }
                                          : null,
                                        onChange: this.handleAddressSelect,
                                        placeholder: this.state.client_address
                                          ? null
                                          : "Enter your address..",
                                        styles: {
                                          control: (provided) => ({
                                            ...provided,
                                            minHeight: "56px",
                                          }),
                                          menu: (provided) => ({
                                            ...provided,
                                            zIndex: "9",
                                          }),
                                        },
                                      }}
                                      id="googleLocation-autocomplete"
                                    />
                                  </>
                                ) : (
                                  <MutextField
                                    required
                                    id="client_address"
                                    name="client_address"
                                    type="text"
                                    label="PO Box Address"
                                    value={this.state.client_address}
                                    onChange={this.handleChange}
                                    helperText="Enter valid PO Box Address. i.e, PO Box Number, City, State Zip Code"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{
                                      shrink: true,
                                      required: false,
                                    }}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                id="client_address2"
                                name="client_address2"
                                type="text"
                                inputProps={{ maxLength: 30 }}
                                label="Client Address2"
                                value={this.state.client_address2}
                                onChange={this.handleChange}
                                helperText="Enter the address2 here."
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-3">
                              <MutextField
                                required
                                id="client_city"
                                name="client_city"
                                type="text"
                                inputProps={{ maxLength: 30 }}
                                label="Client City"
                                value={this.state.client_city}
                                onChange={this.handleChange}
                                helperText="Enter the city here."
                                variant="outlined"
                                fullWidth
                                error={this.state.errorClientCity === "error"}
                              />
                            </div>

                            <div className="form-group col-md-3">
                              <MutextField
                                required
                                id="client_state"
                                name="client_state"
                                type="text"
                                inputProps={{ maxLength: 30 }}
                                label="Client State"
                                value={this.state.client_state}
                                onChange={this.handleChange}
                                helperText="Enter the state here."
                                variant="outlined"
                                fullWidth
                                error={this.state.errorClientState === "error"}
                              />
                            </div>

                            <div className="form-group col-md-3">
                              <MutextField
                                required
                                id="client_zip"
                                name="client_zip"
                                type="text"
                                inputProps={{ maxLength: 30 }}
                                label="Client zip"
                                value={this.state.client_zip}
                                onChange={this.handleChange}
                                helperText="Enter the zip here."
                                variant="outlined"
                                fullWidth
                                error={this.state.errorClientZip === "error"}
                              />
                            </div>

                            <div className="form-group col-md-3">
                              <Select
                                className="customStyle-country"
                                options={this.countryOptions}
                                value={{
                                  label: this.state.client_country,
                                  value: this.state.client_country,
                                }}
                                onChange={this.handleChangeCountry}
                                placeholder="Select a country"
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    minHeight: "56px",
                                    borderColor: this.state.errorClientCountry
                                      ? "red"
                                      : baseStyles.borderColor,
                                    "&:hover": {
                                      borderColor: this.state.errorClientCountry
                                        ? "red"
                                        : baseStyles["&:hover"].borderColor,
                                    },
                                    boxShadow: this.state.errorClientCountry
                                      ? "0 0 0 1px red"
                                      : baseStyles.boxShadow,
                                  }),
                                }}
                              />
                              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-required">
                                Select country
                              </p>
                            </div>

                            <div
                              className="form-group col-md-6"
                              style={{ position: "relative" }}
                            >
                              <MutextField
                                required
                                id="client_password"
                                name="client_password"
                                type={
                                  this.state.passwordShown ? "text" : "password"
                                }
                                label="Password"
                                value={this.state.client_password}
                                inputProps={{ maxLength: 30 }}
                                onChange={this.handleChange}
                                helperText="Enter your password here."
                                variant="outlined"
                                fullWidth
                                error={
                                  this.state.errorClientPassword === "error"
                                }
                              />
                              <a
                                style={{
                                  left: "auto",
                                  right: "10px",
                                  top: "44px",
                                  position: "absolute",
                                  cursor: "pointer",
                                }}
                                tabIndex="-1"
                                onClick={this.togglePassword}
                                className="form-icon form-icon-right passcode-switch"
                                data-target="password"
                              >
                                {this.state.passwordShown ? (
                                  <em className="icon ni ni-eye-off"></em>
                                ) : (
                                  <em className="icon ni ni-eye"></em>
                                )}
                              </a>
                            </div>
                            <div
                              className="form-group col-md-6"
                              style={{ position: "relative" }}
                            >
                              <MutextField
                                required
                                id="client_c_password"
                                name="client_c_password"
                                type={
                                  this.state.confirmPasswordShown
                                    ? "text"
                                    : "password"
                                }
                                label="Confirm Password"
                                value={this.state.client_c_password}
                                inputProps={{ maxLength: 30 }}
                                onChange={this.handleChange}
                                helperText="Enter the confirm password here."
                                variant="outlined"
                                fullWidth
                                error={this.state.errorCPassword === "error"}
                              />
                              <a
                                style={{
                                  left: "auto",
                                  right: "10px",
                                  top: "44px",
                                  position: "absolute",
                                  cursor: "pointer",
                                }}
                                tabIndex="-1"
                                onClick={() => {
                                  this.toggleConfirmPassword();
                                }}
                                className="form-icon form-icon-right passcode-switch"
                                data-target="password"
                              >
                                {this.state.confirmPasswordShown ? (
                                  <em className="icon ni ni-eye-off"></em>
                                ) : (
                                  <em className="icon ni ni-eye"></em>
                                )}
                              </a>
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                name="client_status"
                                select
                                label="Status"
                                value={this.state.client_status}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText=""
                                variant="outlined"
                                fullWidth
                              >
                                <option key="st1" value="active">
                                  Active
                                </option>
                                <option key="st2" value="inactive">
                                  Inactive
                                </option>
                              </MutextField>
                            </div>

                            <div
                              className="custom-control custom-control-lg custom-checkbox col-lg-3"
                              style={{ padding: 0, marginLeft: "3rem" }}
                            >
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="customCheck2"
                                  checked={this.state.notifyCheckBox}
                                  onChange={() => {
                                    const isChecked =
                                      !this.state.notifyCheckBox;
                                    this.setState({
                                      notifyCheckBox: isChecked,
                                    });
                                    console.log(
                                      "Notify Checkbox Checked:",
                                      isChecked
                                    );
                                  }}
                                />
                                <label
                                  class="custom-control-label"
                                  for="customCheck2"
                                >
                                  Notify Sub Client
                                </label>
                              </div>
                            </div>

                            <div className="form-group text-right col-md-12">
                              <button
                                className="btn btn-lg btn-primary"
                                type="button"
                                disabled={this.state.disabled}
                                onClick={this.formUpdateSubmit}
                              >
                                Submit
                              </button>
                            </div>
                            {/* <div className="example-alert border-top">
                              <div className="alert alert-light alert-icon">
                                <em className="icon ni ni-alert-circle"></em>{" "}
                                <ul className="list">
                                  <li>
                                    Please note that the email address provided
                                    is read-only. It cannot be modified or
                                    changed. If you need to update your email
                                    address, please contact our support team for
                                    assistance.
                                  </li>
                                  <li>
                                    To ensure the security of your account,
                                    please ensure that the password and confirm
                                    password fields match. The values entered in
                                    both fields must be identical for successful
                                    submission.
                                  </li>
                                </ul>
                              </div>
                            </div> */}
                          </div>
                        </form>
                      </div>

                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* EDIT MODAL End */}
              {/* RIGHTS MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalFormRights">
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {" "}
                        <em className="icon ni ni-unlock"></em> Sub Client
                        Rights
                      </h5>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* Error Start */}
                      {this.state.errorMessageRights !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessageRights}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessageRights !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessageRights}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className=" g-4">
                        {this.state.tableLoaderListing === true ? (
                          tableLoaderListing()
                        ) : (
                          <>
                            <div id="no-more-tables-new">
                              <p>
                                These permissions allow users to access and view
                                information, data, or content within the system.
                              </p>
                              <table class="table table-bordered">
                                <thead class="thead-light">
                                  <tr>
                                    <th class="tb-col-os">
                                      <span class="overline-title">
                                        Main Menu
                                      </span>
                                    </th>
                                    <th class="tb-col-ip">
                                      <span class="overline-title">
                                        Sub Menu
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.allRights &&
                                  this.state.allRights.length > 0 ? (
                                    <>
                                      {this.state.allRights.map(
                                        (mainRights, i) => (
                                          <tr key={`menu${i}`}>
                                            <td class="tb-col-os">
                                              <div className="custom-control custom-control custom-checkbox align-switch-div">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input vdrSelected"
                                                  id={`cp-main-menu${i}`}
                                                  name="main_menu_id"
                                                  defaultChecked={
                                                    this.state
                                                      .assignedMainRights &&
                                                    this.state
                                                      .assignedMainRights
                                                      .length > 0
                                                      ? this.state.assignedMainRights.includes(
                                                          mainRights.link_id
                                                        )
                                                      : null
                                                  }
                                                  value={mainRights.link_id}
                                                  onChange={this.handleChange}
                                                  required=""
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor={`cp-main-menu${i}`}
                                                >
                                                  {mainRights.alt}
                                                </label>
                                              </div>
                                            </td>
                                            <td class="tb-col-ip">
                                              {mainRights.submenu &&
                                              mainRights.submenu.length > 0 ? (
                                                <>
                                                  {mainRights.submenu.map(
                                                    (subRights, index) => (
                                                      <div
                                                        className="col-md-6 col-lg-6 mb-2 inner_column"
                                                        key={`submenu${index}`}
                                                      >
                                                        <div className="custom-control custom-control custom-checkbox align-switch-div">
                                                          <input
                                                            type="checkbox"
                                                            className={`custom-control-input vdrSelectedSub${mainRights.link_id}`}
                                                            id={`cp-sub-menu${index}`}
                                                            name="sub_menu_id"
                                                            defaultChecked={
                                                              this.state
                                                                .assignedSubRights &&
                                                              this.state
                                                                .assignedSubRights
                                                                .length > 0
                                                                ? this.state.assignedSubRights.includes(
                                                                    subRights.link_id
                                                                  )
                                                                : null
                                                            }
                                                            value={
                                                              subRights.link_id
                                                            }
                                                            onChange={
                                                              this.handleChange
                                                            }
                                                            required=""
                                                          />
                                                          <label
                                                            className="custom-control-label"
                                                            htmlFor={`cp-sub-menu${index}`}
                                                          >
                                                            {subRights.alt}
                                                          </label>
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                </>
                                              ) : null}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </>
                                  ) : null}
                                </tbody>
                              </table>
                            </div>
                            <div className="nk-kycfm-action m-3 text-right">
                              <button
                                type="button"
                                className="btn btn-lg btn-primary"
                                disabled={this.state.disabled}
                                onClick={this.formSaveRights}
                              >
                                <em className="icon ni ni-plus-round"></em>
                                Save Rights
                              </button>
                            </div>
                          </>
                        )}
                      </div>

                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* RIGHTS MODAL End */}
              {/* Role Sub Client Assignment Modal START */}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modalSubClientAssignRole"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-grid-alt"></em>Sub Client
                          Roles{" "}
                          <sup>
                            <small className="text-soft">
                              {this.state.rolesSubClientName}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body text-left">
                      <div className="nk-modal">
                        <div className="nk-modal-text">
                          {this.state.subclientRoleLoader === true ? (
                            tableLoader()
                          ) : (
                            <>
                              {this.state.errorMessageSubClientRole !== "" ? (
                                <div
                                  className="example-alert "
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>
                                        {this.state.errorMessageSubClientRole}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.successMessageSubClientRole !== "" ? (
                                <div
                                  className="example-alert "
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>
                                        {this.state.successMessageSubClientRole}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div className="card card-bordered card-preview">
                                <table className="table table-orders">
                                  <thead className="tb-odr-head">
                                    <tr className="tb-odr-item">
                                      <th
                                        width="35%"
                                        style={{
                                          borderRight: "1px solid #dbdfea",
                                        }}
                                      >
                                        <span className="tb-odr-action">
                                          Roles
                                        </span>
                                      </th>
                                      <th width="65%">
                                        <span className="tb-odr-action">
                                          Sub Roles
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="tb-odr-body">
                                    {this.state.allRoleData &&
                                    this.state.allRoleData.length > 0 ? (
                                      <>
                                        {this.state.allRoleData.map(
                                          (role, index) => (
                                            <tr
                                              className="tb-odr-item"
                                              key={`role${index}`}
                                            >
                                              <td
                                                style={{
                                                  borderRight:
                                                    "1px solid #dbdfea",
                                                }}
                                              >
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={this.state.allRoleData.some(
                                                        (item) =>
                                                          item.alt ===
                                                            role.alt &&
                                                          item.isChecked
                                                      )}
                                                      onChange={
                                                        this
                                                          .changedAllRolesRights
                                                      }
                                                      disabled={
                                                        role.parent_role_id ===
                                                        1
                                                      }
                                                      name={role.id.toString()}
                                                      id={`role-${role.parent_role_id}`}
                                                      color="primary"
                                                    />
                                                  }
                                                  style={{
                                                    marginBottom: "0px",
                                                  }}
                                                  label={role.alt}
                                                />
                                              </td>
                                              <td
                                                style={{
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                  paddingLeft: "16px",
                                                }}
                                              >
                                                {this.state.allSubRoleData
                                                  .filter((subRole) => {
                                                    return (
                                                      subRole.role_id ===
                                                      role.id
                                                    );
                                                  })
                                                  .map((subRole, subIndex) => (
                                                    <div
                                                      key={`subRole${subIndex}`}
                                                    >
                                                      <FormControlLabel
                                                        control={
                                                          <Checkbox
                                                            checked={this.state.allSubRoleData.some(
                                                              (item) =>
                                                                item.alt ===
                                                                  subRole.alt &&
                                                                item.isChecked
                                                            )}
                                                            onChange={
                                                              this
                                                                .changedAllSubRolesRights
                                                            }
                                                            name={subRole?.tabdetailid?.toString()}
                                                            color="primary"
                                                          />
                                                        }
                                                        style={{
                                                          marginBottom: "0px",
                                                        }}
                                                        label={subRole.alt}
                                                      />
                                                    </div>
                                                  ))}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </>
                                    ) : null}
                                  </tbody>
                                </table>
                              </div>
                              <div className="nk-modal-action  text-right">
                                <button
                                  href="#"
                                  className="btn btn-lg btn-mw btn-primary "
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleSubmitRoleCheckboxSub(
                                      this.state.checkedName,
                                      this.state.checkedEmail
                                    );
                                  }}
                                  disabled={this.state.subdisableRoleBtn}
                                >
                                  Assign Roles
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Role Sub Client Assignment Modal End */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubClients);
