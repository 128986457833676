import { Link } from "react-router-dom";
import { LOGO } from "./../../config/config";
import $ from "jquery";
import HelperClass from "./../../config/helperClass";
const auth = new HelperClass();
const leftSideBar = (props) => {
  //console.log("props.menuArr: ", props);
  /*function classCheck() {
    var menu_active = $(this);
    var menu_items = $("#reportmenu");
    if (menu_items.hasClass("active")) {
      menu_items.removeClass("active");
      menu_items.removeClass("current-page");
    } else {
      menu_items.addClass("active");
      menu_items.addClass("current-page");
    }
  }*/

  function classCheck(id) {
    var menu_items = $("#" + id);
    if (menu_items.hasClass("active")) {
      menu_items.removeClass("active");
      menu_items.removeClass("current-page");
    } else {
      menu_items.addClass("active");
      menu_items.addClass("current-page");
    }
  }

  function activeCheck() {
    var menu_active = $(this);
    //console.log("menu active has: ", menu_active);
    if (menu_active.hasClass("active")) {
      $(this).removeClass("active");
      $(this).removeClass("current-page");
    } else {
      $(this).addClass("active");
      $(this).addClass("current-page");
    }
  }

  const addMenuHistoryLeft = (link) => {
    props.mainProps.history.push(link);
  };

  return (
    <div className="nk-sidebar nk-sidebar-fixed" data-content="sidebarMenu">
      <div
        className="nk-sidebar-element nk-sidebar-head"
        style={{
          backgroundColor: "white",
          height: "67.8px",
          borderRight: "1px solid #e8ecec",
        }}
      >
        <div className="nk-sidebar-brand">
          <div className="logo-link nk-sidebar-logo">
            <img className="logo-light logo-img" src={LOGO} alt="logo" />
            <img
              className="logo-dark logo-img"
              src={LOGO}
              alt="logo-dark"
              style={{ marginLeft: "0" }}
            />
          </div>
        </div>
        <div className="nk-menu-trigger mr-n2">
          <div
            className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
            data-target="sidebarMenu"
          >
            <em className="icon ni ni-arrow-left"></em>
          </div>
        </div>
      </div>
      <div
        className="nk-sidebar-element"
        style={{ backgroundColor: "transparent" }}
      >
        <div className="nk-sidebar-content">
          <div className="nk-sidebar-menu" data-simplebar>
            <ul className="nk-menu">
              <li className="nk-menu-heading">
                <h3
                  className="overline-title text-primary-alt"
                  style={{ color: "#6576ff" }}
                >
                  Channel Partner Portal
                </h3>
              </li>
              <li className="nk-menu-heading">
                <h3
                  className="overline-title text-primary-alt"
                  style={{ color: "#202223" }}
                >
                  {auth.getCompName()}
                </h3>
              </li>

              {props.menuArr && props.menuArr.length > 0 ? (
                <>
                  {props.menuArr.map((mainMenu, index) => (
                    <>
                      {mainMenu.submenu && mainMenu.submenu.length > 0 ? (
                        <li
                          className="nk-menu-item has-sub"
                          onClick={() =>
                            classCheck(mainMenu.alt.replace(/[&\s]/g, ""))
                          }
                          id={mainMenu.alt.replace(/[&\s]/g, "")}
                          key={`mainmenu${index}`}
                        >
                          <div className="nk-menu-link nk-menu-toggle">
                            <span className="nk-menu-icon">
                              <em className={mainMenu.tabimage}></em>
                            </span>
                            <span
                              className="nk-menu-text"
                              style={{
                                fontSize: "1.1rem",
                                marginLeft: "5%",
                                cursor: "pointer",
                              }}
                            >
                              {mainMenu.alt}
                            </span>
                          </div>
                          <ul className="nk-menu-sub">
                            {mainMenu.submenu.map((datas, k) => (
                              <li className="nk-menu-item" key={`submenu${k}`}>
                                <Link className="nk-menu-link" to={datas.link}>
                                  <span
                                    className="nk-menu-text"
                                    style={{ color: "#202223" }}
                                  >
                                    {datas.alt}
                                  </span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      ) : (
                        <li
                          className="nk-menu-item"
                          onClick={() => activeCheck()}
                          key={`mainmenu${index}`}
                        >
                          <Link className="nk-menu-link" to={mainMenu.link}>
                            <span className="nk-menu-icon">
                              <em className={mainMenu.tabimage}></em>
                            </span>
                            <span
                              className="nk-menu-text"
                              style={{
                                fontSize: "1.1rem",
                              }}
                            >
                              {mainMenu.alt}
                            </span>
                          </Link>
                        </li>
                      )}
                    </>
                  ))}
                </>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default leftSideBar;
