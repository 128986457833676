import styles from "./styles.module.css";

import SignatureCanvas from "react-signature-canvas";
import { Row, Col, Container } from "react-bootstrap";
import $ from "jquery";

import {
  saveGeneralAgreement,
  unsignedAgreements,
  saveChooseSignatureAgreement,
  sendToSignee,
} from "../../config/client_api_calls";
import { REPLACECONTENT, REPLACECONTENTCLIENT } from "../../config/utility";
import { React, Component } from "react";
import { ValidateEmail } from "./../../config/utility";
import HelperClass from "./../../config/helperClass";

import {
  THEME_COLOR,
  THEME_TEXT_COLOR,
  VENDOR_SIGNATURE,
  ADMIN_URL,
} from "../../config/config";

import "./agreement.css";
import TextField from "../InputFields/TextField";
import MutextField from "@material-ui/core/TextField";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import html2canvas from "html2canvas";
const auth = new HelperClass();

export default class Agreement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      successMessage: "",
      errorMessageSignee: "",
      successMessageSignee: "",
      agreementData: [],
      replacable_content: "",
      final_content: "",
      agreement_id: "",
      agreement_accountno: "",
      agreement_title: "",
      signature_zero: 1,
      signature_one: 0,
      signature_two: 0,
      signature_three: 0,
      signature_four: 0,
      vendor_signature: "",
      agreement_type: "esign",
      is_checked: "",
      signee_name: "",
      signee_email: "",
      signee_title: "",
      signee_comment: "",
      charLimit: 1000,
      characterExceededMessage: "",
      agreement_service_type: "",
      signature_type: "draw",
      signatureText: "",
      signatureClass: "'Amita', cursive",
      countryCode: "",
      loadingButton: false,
    };
    this.cursiveFonts = [
      { name: "Your Signature", value: "'Amita', cursive" },
      { name: "Your Signature", value: "'Bad Script', cursive" },
      { name: "Your Signature", value: "'Damion', cursive" },
      { name: "Your Signature", value: "'Dancing Script', cursive" },
      { name: "Your Signature", value: "'Felipa', cursive" },
      { name: "Your Signature", value: "'Julee', cursive" },
      { name: "Your Signature", value: "'Kaushan Script', cursive" },
      { name: "Your Signature", value: "'Lugrasimo', cursive" },
      { name: "Your Signature", value: "'Pacifico', cursive" },
      { name: "Your Signature", value: "'Sofia', cursive" },
      { name: "Your Signature", value: "'Yellowtail', cursive" },
      // Add more cursive fonts here
    ];
  }

  clear = () => {
    this.signaturePad.clear();
    $("#divImgSig").hide();
    $(".clsimgSig").attr("src", "");
    $(".clsimgSig").hide();
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    this.props.agreementSigned();
  };

  formatPhoneNumber = (phoneNumber) => {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
    if (phoneNumberObj) {
      if (phoneNumberObj.country === "US") {
        const nationalNumber = phoneNumberObj
          .formatNational()
          .replace(/\D/g, ""); // e.g. "2123727200"
        const areaCode = nationalNumber.slice(0, 3);
        const firstPart = nationalNumber.slice(3, 6);
        const secondPart = nationalNumber.slice(6);
        return `(${areaCode}) ${firstPart}-${secondPart}`;
      }
      return phoneNumberObj.formatInternational();
    }
    return phoneNumber; // Return original number if parsing fails
  };

  async componentDidMount() {
    $('div[data-content="sidebarMenu"]').hide();
    $(".nk-header-fixed").next(".nk-content").css("margin-left", "0px");
    let agreements_type = "required";
    let apiData = await unsignedAgreements(
      auth.getClientAccount(),
      auth.getClientToken(),
      agreements_type
    );
    //console.log("UnSignedAgreement DATA Inner: ", apiData.data);
    if (
      apiData.data.status === 403 ||
      apiData.data.errors === "authentication missing" ||
      apiData.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (apiData.data.status === 404 || apiData.data.data.length === 0) {
      console.log("First 404 Error Inner");
      this.setState({
        errorMessage: "There is some error.",
        successMessage: "",
      });
      //this.props.history.push("/error");
    } else if (apiData.data.status === 200 && apiData.data.data.length > 0) {
      this.setState({});
      let objReplace = {
        resellerCompName: auth.getCompName(),
        client_name: apiData.data.user_data[0]["name"],
        client_title:
          apiData.data.user_data[0]["title"] &&
          apiData.data.user_data[0]["title"] !== ""
            ? apiData.data.user_data[0]["title"]
            : "N/A",
        client_comp_name: apiData.data.user_data[0]["comp_name"],
        client_email: apiData.data.user_data[0]["email"],
        client_address: apiData.data.user_data[0]["address"]
          ? apiData.data.user_data[0]["address"]
          : "N/A",
        client_city: apiData.data.user_data[0]["city"]
          ? apiData.data.user_data[0]["city"]
          : "N/A",
        client_phone_no: apiData.data.user_data[0]["phone"]
          ? apiData.data.user_data[0]["phone"]
          : "N/A",
        client_state: apiData.data.user_data[0]["state"]
          ? apiData.data.user_data[0]["state"]
          : "N/A",
        client_zip: apiData.data.user_data[0]["zip"]
          ? apiData.data.user_data[0]["zip"]
          : "N/A",
        client_country: apiData.data.user_data[0]["country"]
          ? apiData.data.user_data[0]["country"]
          : "N/A",
        client_phone_no: this.formatPhoneNumber(
          apiData.data.user_data[0]["phone"]
        ),
        vendor_signor_name: apiData.data.reseller_data[0].reseller_name,
        vendor_signor_comp_name: apiData.data.reseller_data[0].reseller_comp,
        vendor_signor_title: apiData.data.reseller_data[0].reseller_title,
        vendor_signor_address: apiData.data.reseller_data[0].reseller_address
          ? apiData.data.reseller_data[0].reseller_address +
            ", " +
            apiData.data.reseller_data[0].reseller_city +
            ", " +
            apiData.data.reseller_data[0].reseller_state +
            ", " +
            apiData.data.reseller_data[0].reseller_zip
          : " ",
        vendor_signor_email: "N/A",
        vendor_signor_phone_no: apiData.data.reseller_data[0].reseller_phone,
        vendor_signor_fax: "N/A",
        vendor_signor_city: apiData.data.reseller_data[0].reseller_city,
        vendor_signor_state: apiData.data.reseller_data[0].reseller_state,
        vendor_signor_zip: apiData.data.reseller_data[0].reseller_zip,
        vendor_signor_comp_title: apiData.data.reseller_data[0].reseller_comp,
      };

      /*newContent = newContent.replace(
        "VENDOR SIGNATURE DATE SHOWN HERE",
        apiData.data.agreement[0]["published_date"]
      );*/
      let final_content = apiData.data.data[0][1];
      final_content = REPLACECONTENTCLIENT(
        final_content,
        objReplace,
        ""
      ).replace(
        /\[\[IMG_VENDOR_SIGN\]\]/g,
        ADMIN_URL +
          "files_data/logos/" +
          apiData.data.reseller_data[0].signature_image
      );
      this.setState({
        replacable_content: objReplace,
        agreementData: apiData.data.data,
        errorMessage: "",
        final_content: final_content,
        bgColor: "white",
        defaultLoader: false,
        agreement_id: apiData.data.data[0][0],
        agreement_type: apiData.data.data[0][8],
        agreement_title: apiData.data.data[0][9],
        agreement_accountno: apiData.data.data[0][6],
        vendor_signature:
          ADMIN_URL +
          "files_data/logos/" +
          apiData.data.reseller_data[0].signature_image,
        agreement_service_type: apiData.data.data[0][10],
      });

      //this.show_signature(0);
    } else {
      this.setState({
        errorMessage: "There is some error.",
        successMessage: "",
      });
      console.log("Last Error");
      //this.props.history.push("/error");
    }
  }

  open_signee = async () => {
    console.log("agreement_id: " + this.state.agreement_id);
    this.setState({
      errorMessageSignee: "",
      successMessageSignee: "",
      characterExceededMessage: "",
      signee_comment: "",
    });
    window.$("#modalSendSignee").modal("show");
  };

  close_signee = async () => {
    window.$("#modalSendSignee").modal("hide");
  };

  send_to_signee = async () => {
    console.log("agreement_id: " + this.state.agreement_id);
    this.setState({
      successMessageSignee: "",
      errorMessageSignee: "",
      disabled: true,
    });
    const {
      agreement_id,
      signee_name,
      signee_email,
      signee_title,
      signee_comment,
      agreement_service_type,
    } = this.state;

    var validated = true;
    if (signee_name === "") {
      validated = false;
    } else if (signee_email === "") {
      validated = false;
    } else if (ValidateEmail(signee_email) === false) {
      validated = false;
      this.setState({
        errorEmail: "error",
        errorMessageSignee: "Invalid email format.",
      });
    } else if (signee_comment === "") {
      validated = false;
    }

    if (validated === false) {
      this.setState({
        errorMessageSignee: "Please enter the required fields.",
        disabled: false,
      });
    } else {
      const apiData = await sendToSignee(
        auth.getClientAccount(),
        auth.getClientToken(),
        agreement_id,
        signee_name,
        signee_email,
        signee_title,
        signee_comment,
        agreement_service_type
      );
      //console.log("sendToSignee: ", apiData.data);
      if (
        apiData.data.status === 403 ||
        apiData.data.errors === "authentication missing" ||
        apiData.data.errors === "jwt expired"
      ) {
        auth.clientLogout();
      } else if (apiData.data.status === 404) {
        this.setState({
          errorMessageSignee: "We are sorry, something went wrong try later.",
          disabled: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 4000);

        // this.props.mainHistory.history.push("/error");
        // console.log("errors: ", apiData.data);
      } else if (
        apiData.data.status === 200 &&
        apiData.data.message === "success"
      ) {
        this.setState({
          successMessageSignee:
            "Agreement has been sent successfully to the signee.",
          disabled: false,
          signee_name: "",
          signee_email: "",
          signee_title: "",
          signee_comment: "",
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
  };

  handleChangeTextArea = (event) => {
    let { value } = event.target;
    const { charLimit } = this.state;

    // Update state only if the character limit is not exceeded
    
    if (value.length <= charLimit) {
      this.setState({ signee_comment: value, characterExceededMessage: "" });
    } else {
      //value = value.slice(0, charLimit);
      this.setState({characterExceededMessage:
        `Character limit exceeded. Maximum 500 characters are allowed. you have entered ${value.length} characters.`, });
    }
  };

  save = async () => {
    //console.log("save agreement_id: " + this.state.agreement_id);
    //console.log("save agreement_type: " + this.state.agreement_type);
    //console.log("save is_checked: " + this.state.is_checked);
    //console.log("save signatureText: " + this.state.signatureText);
    //console.log("save agreement_id: " + this.state.agreement_id);

    if (
      this.state.agreement_type === "accept" &&
      this.state.is_checked !== "yes"
    ) {
      window.$("#modalAlertError").modal("show");
      return this.setState({
        errorMessage:
          "For account activation and further processing. You must accept the agreement.",
      });
    } else if (
      this.state.agreement_type === "esign" &&
      this.signaturePad.isEmpty() &&
      this.state.signature_type === "draw"
    ) {
      window.$("#modalAlertError").modal("show");
      return this.setState({
        errorMessage:
          "For account activation and further processing. You must sign the mandatory agreements.",
      });
    } else if (
      this.state.agreement_type === "esign" &&
      this.state.signature_type === "choose" &&
      this.state.signatureText == ""
    ) {
      window.$("#modalAlertError").modal("show");
      return this.setState({
        errorMessage: "Please enter the authorized person name.",
      });
    } else {
      if (this.state.signature_type === "draw") {
        $("#loading_btn").show();
        $("#save_btn").hide();
        $("#signee_button").hide();
        this.setState({
          errorMessage: "",
          loadingButton: true,
        });
        var data = "";
        if (this.state.agreement_type === "esign") {
          data = this.signaturePad
            .getTrimmedCanvas()
            .toDataURL("image/svg+xml");
        }
        let content_div_id =
          "agreement_content_id_" + this.state.agreement_accountno;
        var final_content = document.getElementById(content_div_id).innerHTML;
        const apiData = await saveGeneralAgreement(
          auth.getClientAccount(),
          auth.getClientToken(),
          data,
          final_content,
          this.state.agreement_id,
          this.state.agreement_accountno,
          this.state.vendor_signature,
          this.state.agreement_type
        );
        //console.log("MSA: ", apiData.data);
        if (
          apiData.data.status === 403 ||
          apiData.data.errors === "authentication missing" ||
          apiData.data.errors === "jwt expired"
        ) {
          auth.clientLogout();
        } else if (apiData.data.status === 404) {
          window.$("#modalAlertError").modal("show");
          return this.setState({
            errorMessage: "We are sorry, something went wrong try later.",
            loadingButton: false,
          });
          // this.props.mainHistory.history.push("/error");
          // console.log("errors: ", apiData.data);
        } else if (
          apiData.data.status === 200 &&
          apiData.data.data === "success"
        ) {
          $("#loading_btn").hide();
          $("#save_btn").show();

          window.location.replace(
            "/" + auth.getResellerIdentifier() + "/client/dashboard"
          );
        }

        $("#divSig").show();
        $("#divImgSig").show();
        $(".clsimgSig").show();
        $(".clsimgSig").attr("src", data);
      } else if (this.state.signature_type === "choose") {
        this.submitSignatoryDetails();
      }
    }
  };

  set_data = (agreement_content) => {
    this.setState({
      final_content: agreement_content,
    });
  };
  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.value : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };
  preview = () => {
    if (!this.signaturePad.isEmpty()) {
      var data = this.signaturePad
        .getTrimmedCanvas()
        .toDataURL("image/svg+xml");
      $("#divSig").show();
      //$("#divImgSig").show();
      $(".clsimgSig").show();
      $(".clsimgSig").attr("src", data);
    }
  };

  class_manage_link = (ids) => {
    if (ids === 0) {
      return "nav-link active";
    } else {
      return "nav-link";
    }
  };

  class_manage_content = (ids) => {
    if (ids === 0) {
      return "tab-pane active";
    } else {
      return "tab-pane";
    }
  };

  class_required = (ids) => {
    if (ids === 1) {
      return (
        <sup style={{ fontSize: "100%" }}>
          <small className="" style={{ color: "#e85347", fontSize: "100%" }}>
            *
          </small>
        </sup>
      );
    } else {
      return "";
    }
  };

  set_tabs = (ids) => {
    if (ids !== "") {
      return "#agreement" + ids;
    } else {
      return "tab-pane";
    }
  };

  set_tabs_accountno = (ids) => {
    if (ids !== "") {
      this.setState({ agreement_accountno: ids });
    }
  };

  set_tabs_content = (ids) => {
    if (ids !== "") {
      return "agreement" + ids;
    } else {
      return "tab-pane";
    }
  };

  show_agreement_content = async (
    ids,
    agreement_content,
    agreement_type,
    agreement_title
  ) => {
    //console.log("replacable_content: ", this.state.replacable_content);
    let final_content = agreement_content;
    if (final_content !== "") {
      final_content = REPLACECONTENTCLIENT(
        agreement_content,
        this.state.replacable_content,
        ""
      ).replace(/\[\[IMG_VENDOR_SIGN\]\]/g, this.state.vendor_signature);
    }
    this.setState({
      final_content: final_content,
      agreement_id: ids,
      agreement_type: agreement_type,
      agreement_title: agreement_title,
    });
  };

  updateSignatureType = (type) => {
    if(type === "choose"){
      this.clear();
    } else {
      this.setState({signatureText: ""});
    }
    this.setState({
      signature_type: type,
    });
  };

  handleSignature = (family) => {
    this.setState({ signatureClass: family });
  };

  submitSignatoryDetails = async () => {
    let { signatureText, signature_type } = this.state;
    this.setState({
      loadingButton: true,
    });
    let is_validated = true;
    if (signatureText === "") {
      is_validated = false;
    }
    if (signature_type === "choose") {
      await this.saveAsImage();
    }
    if (signature_type === "draw") {
      //await this.saveSignature();
    }
  };

  saveAsImage = async () => {
    let { signatureText, signature_type, agreement_id, agreement_accountno } =
      this.state;
    const element = document.getElementById("styled-text-container");

    
    //console.log("final_content: ", final_content);
    if (element) {
      html2canvas(element, { scale: 2, backgroundColor: null }).then(
        async (canvas) => {
          const imgData = canvas.toDataURL("image/png");
          let content_div_id = "agreement_content_id_" + agreement_accountno;
          var final_content = document.getElementById(content_div_id).innerHTML;
          /*var final_content = document.getElementById(
            "agreement_content_id"
          ).innerHTML;*/
          const apiData = await saveChooseSignatureAgreement(
            auth.getClientAccount(),
            auth.getClientToken(),
            imgData,
            signatureText,
            signature_type,
            final_content,
            agreement_id,
            this.state.vendor_signature,
            this.state.agreement_type,
            this.state.agreement_accountno
          );
          //console.log("saveChooseSignatureAgreement: ", apiData.data);
          if (
            apiData.data.status === 403 ||
            apiData.data.errors === "authentication missing" ||
            apiData.data.errors === "jwt expired"
          ) {
            auth.clientLogout();
          } else if (apiData.data.status === 404) {
            $("#signee_button").show();
            $("#loading_btn").hide();
            $("#save_btn").show();
            this.setState({
              errorMessageSignatory:
                "There is some error while processing your request.",
              successMessageSignatory: "",
              loadingButton: false,
              disabled: false,
            });
          } else if (
            apiData.data.status === 200 &&
            apiData.data.data === "success"
          ) {
            window.location.replace(
              "/" + auth.getResellerRegToken() + "/client/dashboard"
            );
            this.setState({ loadingButton: false });
          } else {
            this.setState({ loadingButton: false });
            //console.log("there was some error while signing the agreement!");
          }
        }
      );
    }
  };

  handlePrint = () => {
    // Target the div element by ID
    const activeTabPane = document.querySelector(".tab-pane.active");
    const activeTabId = activeTabPane.id;
    const printableElement = document.getElementById(activeTabId);

    // Check if the element exists
    if (printableElement) {
      // Open a new window for printing
      const printWindow = window.open("", "_blank");

      // Append the content of the div to the new window for printing
      printWindow.document.write(printableElement.innerHTML);

      // Trigger the print dialog for the new window
      printWindow.print();

      // Close the new window after printing
      printWindow.close();
    } else {
      console.error("Element not found: contentToPrint");
    }
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    {this.state.agreementData.length > 0 ? (
                      <h3 className="nk-block-title page-title">Agreements</h3>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12">
                    <div className="nk-block nk-block-lg">
                      <div
                        className="card card-bordered"
                        style={{
                          backgroundColor: this.props.backgroundColor,
                          border: "none",
                        }}
                      >
                        {this.state.agreementData.length > 0 ? (
                          <>
                            <div className="card-inner card-inner-xl">
                              <ul className="nav nav-tabs">
                                {this.state.agreementData.map(
                                  (agreements, i) => (
                                    <li
                                      className="nav-item"
                                      key={`agrtitle${i}`}
                                    >
                                      <a
                                        className={this.class_manage_link(i)}
                                        data-toggle="tab"
                                        href={this.set_tabs(agreements[6])}
                                        onClick={() => {
                                          this.show_agreement_content(
                                            agreements[0],
                                            agreements[1],
                                            agreements[8],
                                            agreements[2]
                                          );
                                          this.set_tabs_accountno(
                                            agreements[6]
                                          );
                                        }}
                                      >
                                        <em className="icon ni ni-list-round"></em>
                                        <span>
                                          {agreements[9]}{" "}
                                          {this.class_required(agreements[3])}
                                        </span>
                                      </a>
                                    </li>
                                  )
                                )}
                              </ul>
                              <div className="tab-content tab-agreement_content">
                                {this.state.agreementData.map(
                                  (agreementscontent, i) => (
                                    <div
                                      className={this.class_manage_content(i)}
                                      id={this.set_tabs_content(
                                        agreementscontent[6]
                                      )}
                                      key={`agrcont${i}`}
                                    >
                                      <div
                                        id={`agreement_content_id_${agreementscontent[6]}`}
                                        dangerouslySetInnerHTML={{
                                          __html: REPLACECONTENTCLIENT(
                                            agreementscontent[1],
                                            this.state.replacable_content,
                                            ""
                                          ).replace(
                                            /\[\[IMG_VENDOR_SIGN\]\]/g,
                                            this.state.vendor_signature
                                          ),
                                        }}
                                      />
                                    </div>
                                  )
                                )}
                                {/* ACTION BUTTONS DIV  START*/}
                                {this.state.agreement_type === "esign" ? (
                                  <>
                                    <div id="signature_div">
                                      <Row style={{ padding: "0px 0px 10px" }}>
                                        <Col sm={12}>
                                          <div>
                                            <ul className="nav nav-tabs">
                                              <li className="nav-item">
                                                <a
                                                  className={
                                                    this.state
                                                      .signature_type ===
                                                      "draw" ||
                                                    this.state
                                                      .signature_type === ""
                                                      ? "nav-link active"
                                                      : "nav-link"
                                                  }
                                                  data-toggle="tab"
                                                  onClick={() =>
                                                    this.updateSignatureType(
                                                      "draw"
                                                    )
                                                  }
                                                  href="#tabItem6"
                                                >
                                                  <em className="icon ni ni-edit-alt"></em>
                                                  <span>Draw</span>
                                                </a>
                                              </li>
                                              <li className="nav-item">
                                                <a
                                                  className={
                                                    this.state
                                                      .signature_type ===
                                                    "choose"
                                                      ? "nav-link active"
                                                      : "nav-link"
                                                  }
                                                  data-toggle="tab"
                                                  onClick={() =>
                                                    this.updateSignatureType(
                                                      "choose"
                                                    )
                                                  }
                                                  href="#tabItem5"
                                                >
                                                  <em className="icon ni ni-list-check"></em>
                                                  <span>Choose</span>
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </Col>
                                      </Row>
                                      <div className="tab-content">
                                        <div
                                          className={
                                            this.state.signature_type ===
                                            "choose"
                                              ? "tab-pane active"
                                              : "tab-pane"
                                          }
                                          id="tabItem5"
                                        >
                                          <div class="example-alert">
                                            <div class="alert alert-light">
                                              Pick a style for your signature
                                              (Authorized Person Name).
                                            </div>
                                          </div>
                                          <div className="form-group mt-4">
                                            <div className="form-group">
                                              <MutextField
                                                required
                                                id="signatureText"
                                                name="signatureText"
                                                type="text"
                                                label="Authorized Person Name"
                                                inputProps={{ maxLength: 30 }}
                                                onInput={(e) => {
                                                  e.target.value =
                                                    e.target.value.replace(
                                                      /[^a-zA-Z0-9 ]/gi,
                                                      ""
                                                    );
                                                }}
                                                defaultValue={
                                                  this.state.signatureText
                                                }
                                                onChange={this.handleChange}
                                                helperText=""
                                                variant="outlined"
                                                fullWidth
                                              />
                                            </div>
                                            <h6 className="title">
                                              Choose Style
                                            </h6>

                                            <ul className="cc-pay-method">
                                              <li className="cc-pay-dd dropdown">
                                                <a
                                                  href="#"
                                                  className="btn btn-white btn-outline-light dropdown-toggle dropdown-indicator"
                                                  data-toggle="dropdown"
                                                >
                                                  <span
                                                    style={{
                                                      fontFamily:
                                                        this.state
                                                          .signatureClass,
                                                    }}
                                                  >
                                                    {this.state.signatureText ||
                                                      "Your Signature"}
                                                  </span>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-auto">
                                                  <ul className="link-list-plain">
                                                    {this.cursiveFonts.map(
                                                      (font, index) => (
                                                        <li key={index}>
                                                          <a
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                              this.handleSignature(
                                                                font.value
                                                              )
                                                            }
                                                            className="cc-pay-item"
                                                          >
                                                            <span className="cc-pay-item-name">
                                                              <span
                                                                className="cc-pay-item-method"
                                                                style={{
                                                                  fontFamily:
                                                                    font.value,
                                                                }}
                                                              >
                                                                {this.state
                                                                  .signatureText !==
                                                                ""
                                                                  ? this.state
                                                                      .signatureText
                                                                  : font.name}
                                                              </span>
                                                            </span>
                                                          </a>
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                          <div
                                            className="signature-preview mt-3"
                                            id="styled-text-container"
                                            style={{
                                              fontFamily:
                                                this.state.signatureClass,
                                              fontSize: "40px",
                                              width: "480px",
                                              height: "80px",
                                              background: "trasnparent",
                                              backgroundColor: "transparent",
                                              border: "none",
                                              lineHeight: "80px",
                                              color: "#000",
                                            }}
                                          >
                                            {this.state.signatureText ||
                                              "Your Signature"}
                                          </div>
                                        </div>
                                        <div
                                          className={
                                            this.state.signature_type ===
                                              "draw" ||
                                            this.state.signature_type === ""
                                              ? "tab-pane active"
                                              : "tab-pane"
                                          }
                                          id="tabItem6"
                                        >
                                          <Row>
                                            <Col sm={12}>
                                              <div class="example-alert mb-4">
                                                <div class="alert alert-light">
                                                  Please Save the signature to
                                                  proceed.
                                                </div>
                                              </div>
                                              <div id="divSig" style={{display: "none"}}>
                                                  <div class="mb-4"><img src="" class="clsimgSig" height="100" width="200" onerror="this.style.display='none'" /></div>
                                                </div>
                                              <div>
                                                <strong>Signature</strong>
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row style={{ marginTop: "1%" }}>
                                            <Col
                                              sm={12}
                                              style={{ height: "120px" }}
                                            >
                                              <SignatureCanvas
                                                clearOnResize={true}
                                                canvasProps={{
                                                  className: styles.sigPad,
                                                }}
                                                velocityFilterWeight={0.7}
                                                throttle={50}
                                                ref={(ref) => {
                                                  this.signaturePad = ref;
                                                }}
                                              />
                                            </Col>
                                          </Row>
                                        </div>
                                      </div>

                                      <Row style={{ marginTop: "1%" }}>
                                        <Col sm={12}>
                                          <div style={{ float: "right" }}>
                                            <button
                                              className="btn btn-info"
                                              style={{
                                                margin: "13px 10px 12px 10px",
                                              }}
                                              onClick={() => this.handlePrint()}
                                            >
                                              <em class="icon ni ni-download"></em>{" "}
                                              <span>Download PDF / Print</span>
                                            </button>
                                            {this.state.loadingButton ===
                                            false ? (
                                              <>
                                                {this.state.signature_type ===
                                                "draw" ? (
                                                  <>
                                                    <button
                                                      className="btn btn-warning"
                                                      style={{
                                                        margin:
                                                          "13px 0px 12px 10px",
                                                      }}
                                                      onClick={() =>
                                                        this.clear()
                                                      }
                                                    >
                                                      <em class="icon ni ni-delete"></em>{" "}
                                                      <span>Clear</span>
                                                    </button>
                                                    <button
                                                      className="btn btn-success"
                                                      style={{
                                                        margin:
                                                          "13px 0px 12px 10px",
                                                      }}
                                                      onClick={() =>
                                                        this.preview()
                                                      }
                                                    >
                                                      <em class="icon ni ni-eye"></em>{" "}
                                                      <span>
                                                        Preview Signature
                                                      </span>
                                                    </button>
                                                  </>
                                                ) : null}

                                                <button
                                                  className="btn btn-primary  "
                                                  onClick={() =>
                                                    this.open_signee()
                                                  }
                                                  id="signee_button"
                                                  style={{
                                                    margin:
                                                      "13px 12px 12px 10px",
                                                  }}
                                                >
                                                  <em class="icon ni ni-send"></em>{" "}
                                                  <span>Send To Signee</span>
                                                </button>
                                              </>
                                            ) : null}
                                            <button
                                              className="btn"
                                              type="button"
                                              id="loading_btn"
                                              style={{
                                                display: "none",
                                                backgroundColor: THEME_COLOR,
                                                color: THEME_TEXT_COLOR,
                                              }}
                                              disabled
                                            >
                                              <span
                                                className="spinner-grow spinner-grow-sm"
                                                role="status"
                                                aria-hidden="true"
                                              ></span>
                                              <span> Wait... </span>
                                            </button>

                                            {this.state.loadingButton ===
                                            true ? (
                                              <button
                                                className="btn "
                                                type="button"
                                                style={{
                                                  backgroundColor: THEME_COLOR,
                                                  color: THEME_TEXT_COLOR,
                                                  marginLeft: "12px",
                                                }}
                                                disabled
                                              >
                                                <span
                                                  className="spinner-grow spinner-grow-sm"
                                                  role="status"
                                                  aria-hidden="true"
                                                ></span>
                                                <span> Wait... </span>
                                              </button>
                                            ) : (
                                              <button
                                                className="btn"
                                                style={{
                                                  backgroundColor: THEME_COLOR,
                                                  color: THEME_TEXT_COLOR,
                                                  margin: "13px 0px 12px 0px",
                                                }}
                                                onClick={() => this.save()}
                                                id="save_btn"
                                              >
                                                <em class="icon ni ni-folder-check"></em>{" "}
                                                <span>Save</span>
                                              </button>
                                            )}
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </>
                                ) : null}
                                {this.state.agreement_type === "accept" ? (
                                  <div
                                    id="signature_div"
                                    className="signature_width"
                                  >
                                    <div className="nk-block">
                                      <div className="row g-gs">
                                        <div className="col-md-12">
                                          <div className="card card-bordered card-full">
                                            <div className="nk-wg1">
                                              <div className="nk-wg1-block">
                                                <div className="nk-wg1-text">
                                                  <div className="form-group">
                                                    <div className="custom-control custom-control-xs custom-checkbox">
                                                      <input
                                                        type="checkbox"
                                                        name="is_checked"
                                                        className="custom-control-input"
                                                        id="checkbox"
                                                        defaultChecked={
                                                          this.state.is_checked
                                                        }
                                                        value="yes"
                                                        onChange={
                                                          this.handleChange
                                                        }
                                                      />
                                                      <label
                                                        className="custom-control-label"
                                                        htmlFor="checkbox"
                                                      >
                                                        I accept the{" "}
                                                        {
                                                          this.state
                                                            .agreement_title
                                                        }
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="nk-wg1-action"
                                                style={{ padding: "1.25rem" }}
                                              >
                                                <div className="form-group ">
                                                  <button
                                                    className="btn btn-lg"
                                                    type="button"
                                                    id="loading_btn"
                                                    style={{
                                                      display: "none",
                                                      backgroundColor:
                                                        THEME_COLOR,
                                                      color: THEME_TEXT_COLOR,
                                                    }}
                                                    disabled
                                                  >
                                                    <span
                                                      className="spinner-grow spinner-grow-sm"
                                                      role="status"
                                                      aria-hidden="true"
                                                    ></span>
                                                    <span> Wait... </span>
                                                  </button>
                                                  <button
                                                    className="btn btn-lg"
                                                    type="button"
                                                    id="loading_btn"
                                                    style={{
                                                      display: "none",
                                                      backgroundColor:
                                                        THEME_COLOR,
                                                      color: THEME_TEXT_COLOR,
                                                    }}
                                                    disabled
                                                  >
                                                    <span
                                                      className="spinner-grow spinner-grow-sm"
                                                      role="status"
                                                      aria-hidden="true"
                                                    ></span>
                                                    <span> Wait... </span>
                                                  </button>

                                                  <button
                                                    className="btn btn-primary"
                                                    onClick={() => this.save()}
                                                    id="save_btn"
                                                  >
                                                    <em class="icon ni ni-folder-check"></em>{" "}
                                                    <span>
                                                      Accept and Continue
                                                    </span>
                                                  </button>
                                                  <button
                                                    className="btn btn-warning"
                                                    onClick={() =>
                                                      this.open_signee()
                                                    }
                                                    style={{
                                                      margin:
                                                        "0px 12px 0px 10px",
                                                    }}
                                                    id="signee_button"
                                                  >
                                                    Send to Signee
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {/* ACTIONS BUTTONS DIV END */}
                              </div>
                            </div>
                            <div class="col-lg-12 mb-4">
                              <div class="alert alert-light alert-icon">
                                <em class="icon ni ni-alert-circle"></em>{" "}
                                <b>Full Legal Name:</b> This field requires the
                                full legal name of the signing authority. If you
                                are not the authorized signer, please select the
                                'Send to Signee' option below. The designated
                                signee will receive an email directly from our
                                system with the document attached for signature.
                                The recipient does not need to register an
                                account to sign the document.{" "}
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
              {/* SUCCESS MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalAlert">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      onClick={() => {
                        this.modalHide();
                      }}
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                        <h4 className="nk-modal-title">
                          {this.state.successMessage}
                        </h4>
                        <div className="nk-modal-text">
                          <div className="caption-text">
                            You’ve successfully signed{" "}
                            <strong>Agreement</strong>
                          </div>
                        </div>
                        <div className="nk-modal-action">
                          <a
                            href="#"
                            className="btn btn-lg btn-mw btn-primary"
                            onClick={() => {
                              this.modalHide();
                            }}
                          >
                            OK
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* SUCCESS MODAL End */}
              {/* ERROR MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalAlertError">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                        <h4 className="nk-modal-title">Unable to Process!</h4>
                        <div className="nk-modal-text">
                          <p className="lead">{this.state.errorMessage}</p>
                        </div>
                        <div className="nk-modal-action mt-5">
                          <a
                            href="#"
                            className="btn btn-lg btn-mw btn-light"
                            data-dismiss="modal"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ERROR MODAL END */}
              {/* Signee Model */}

              <div className="modal fade" tabindex="-1" id="modalSendSignee">
                <div
                  className="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">Send To Signee</h5>
                    </div>
                    <div className="modal-body">
                      {this.state.errorMessageSignee !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessageSignee}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessageSignee !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessageSignee}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />

                      <div className="row g-4">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="form-group">
                              <MutextField
                                required
                                id="signee_name"
                                name="signee_name"
                                type="text"
                                label="Full Name"
                                value={this.state.signee_name}
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <MutextField
                              id="signee_title"
                              name="signee_title"
                              type="text"
                              label="Title"
                              value={this.state.signee_title}
                              onChange={this.handleChange}
                              variant="outlined"
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <MutextField
                              required
                              id="signee_email"
                              name="signee_email"
                              type="text"
                              label="Email Address"
                              value={this.state.signee_email}
                              onChange={this.handleChange}
                              variant="outlined"
                              fullWidth
                            />
                          </div>
                        </div>
                        
                        <div className="col-lg-12">
                              <div className="form-group">
                                <textarea
                                  class="form-control"
                                  id="signee_comment"
                                  name="signee_comment"
                                  onChange={this.handleChangeTextArea}
                                  placeholder="Comments *"
                                  maxLength={1000}
                                >
                                  {this.state.signee_comment}
                                </textarea>
                                <div class="form-note">
                                  {this.state.signee_comment.length}/
                                  {this.state.charLimit} characters
                                </div>
                                <div class="form-note">
                                  
                                  {this.state.characterExceededMessage &&
                                  this.state.characterExceededMessage !== "" ? (
                                    <code>
                                      {this.state.characterExceededMessage}
                                    </code>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                        <div className="col-lg-12">
                          <div class="example-alert">
                            <div class="alert alert-warning alert-icon">
                              <em class="icon ni ni-alert-circle"></em>{" "}
                              <strong>NOTE</strong>. This will send new
                              agreement sign request. Your previous request will
                              be discarded if you have sent any.
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 text-right">
                          <button
                            type="button"
                            className="btn btn-light"
                            style={{ marginRight: "10px" }}
                            onClick={() => this.close_signee()}
                          >
                            <em class="icon ni ni-step-back"></em> Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => this.send_to_signee()}
                            disabled={this.state.disabled}
                          >
                            <em className="icon ni ni-done"></em> Send
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Signee Model */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
