import React, { useState } from "react";
import { Button, FormHelperText } from "@material-ui/core";
import {
  DateTimePicker,
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";

const FormRenderer = ({ formData, formTitle, formDescription, onSubmit }) => {
  //console.log("FormRenderer formData:",formData)
  const [formState, setFormState] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (questionId, value) => {
    setFormState({
      ...formState,
      [questionId]: value,
    });
    setFormErrors({
      ...formErrors,
      [questionId]: "",
    });
  };

  const handleCheckboxChange = (questionId, option, isChecked) => {
    const currentOptions = formState[questionId] || [];
    if (isChecked) {
      setFormState({
        ...formState,
        [questionId]: [...currentOptions, option],
      });
    } else {
      setFormState({
        ...formState,
        [questionId]: currentOptions.filter((opt) => opt !== option),
      });
    }
    setFormErrors({
      ...formErrors,
      [questionId]: "",
    });
  };

  const handleFileChange = (questionId, event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      axios
        .post(
          `${process.env.REACT_APP_API_URL}forms/form_image_response`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          const imageUrl = response.data.default;
          setFormState({
            ...formState,
            [questionId]: imageUrl,
          });
          setFormErrors({
            ...formErrors,
            [questionId]: "",
          });
        })
        .catch((error) => {
          console.error("There was an error uploading the image!", error);
        });
    }
  };

  const handleSubmit = () => {
    const requiredQuestions = formData.flatMap((form) =>
      form.questions.filter((question) => question.required)
    );
    let hasErrors = false;

    requiredQuestions.forEach((question) => {
      if (!formState[question.id]) {
        hasErrors = true;
      }
    });

    if (hasErrors) {
      setErrorMessage("Please fill out all required fields.");
      setTimeout(() => {
        setErrorMessage("");
      }, 2500);
      return;
    }

    const formDataToSend = formData.map((form) => ({
      title: form.title,
      id: form.id,
      questions: form.questions.map((question) => ({
        ...question,
        answer: formState[question.id],
      })),
      errorMessage: form.errorMessage,
    }));
    console.log("formDataToSend: ", formDataToSend);
    onSubmit(formDataToSend);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="form-renderer-container">
        {errorMessage && (
          <div className="example-alert my-3">
            <div className="alert alert-pro alert-danger">
              <div className="alert-text">
                <h4>Error</h4>
                <p>{errorMessage}</p>
              </div>
            </div>
          </div>
        )}
        {formData.map((form) => (
          <div key={form.id} className="form-section">
            <div className="nk-block">
              <div className="card card-bordered">
                <div className="nk-kycfm">
                  <div
                    className="nk-kycfm-head"
                    style={{ background: "#f5f6fa" }}
                  >
                    <div className="nk-kycfm-title">
                      <h5 className="title">{formTitle || "Untitled Form"}</h5>
                      <p className="sub-title">
                        {formDescription || "Untitled Form"}
                      </p>
                    </div>
                  </div>
                  <div className="nk-kycfm-content">
                    <div className="row g-gs">
                      {form.questions.map((question) => (
                        <div
                          key={question.id}
                          className="question-container col-sm-6"
                        >
                          <div className="form-group question-text mb-0">
                            <label className="form-label">
                              {question.questionText}{" "}
                              {question.required && (
                                <sup className="text-danger">*</sup>
                              )}
                            </label>
                          </div>
                          {question.imageUrl && (
                            <img
                              src={question.imageUrl}
                              alt="Admin Uploaded"
                              className="question-image"
                            />
                          )}
                          {question.answerType === "input" && (
                            <div className="form-group mb-0">
                              <div className="form-control-wrap">
                                <input
                                  className={`form-control form-control-lg`}
                                  onChange={(e) =>
                                    handleChange(question.id, e.target.value)
                                  }
                                  type="text"
                                  maxLength={100}
                                />
                              </div>
                              {formErrors[question.id] && (
                                <FormHelperText error>
                                  {formErrors[question.id]}
                                </FormHelperText>
                              )}
                            </div>
                          )}
                          {question.answerType === "textarea" && (
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <textarea
                                  className={`form-control form-control-lg`}
                                  onChange={(e) =>
                                    handleChange(question.id, e.target.value)
                                  }
                                  placeholder=""
                                  maxLength={500}
                                ></textarea>
                              </div>
                              {formErrors[question.id] && (
                                <FormHelperText error>
                                  {formErrors[question.id]}
                                </FormHelperText>
                              )}
                            </div>
                          )}
                          {question.answerType === "datetime" && (
                            <div className="form-group">
                              <DateTimePicker
                                variant="inline"
                                inputVariant="outlined"
                                fullWidth
                                value={formState[question.id] || null}
                                onChange={(date) =>
                                  handleChange(question.id, date)
                                }
                              />
                              {formErrors[question.id] && (
                                <FormHelperText error>
                                  {formErrors[question.id]}
                                </FormHelperText>
                              )}
                            </div>
                          )}
                          {question.answerType === "date" && (
                            <div className="form-group">
                              <DatePicker
                                variant="inline"
                                inputVariant="outlined"
                                fullWidth
                                value={formState[question.id] || null}
                                onChange={(date) =>
                                  handleChange(question.id, date)
                                }
                              />
                              {formErrors[question.id] && (
                                <FormHelperText error>
                                  {formErrors[question.id]}
                                </FormHelperText>
                              )}
                            </div>
                          )}
                          {question.answerType === "time" && (
                            <div className="form-group">
                              <TimePicker
                                variant="inline"
                                inputVariant="outlined"
                                fullWidth
                                value={formState[question.id] || null}
                                onChange={(time) =>
                                  handleChange(question.id, time)
                                }
                              />
                              {formErrors[question.id] && (
                                <FormHelperText error>
                                  {formErrors[question.id]}
                                </FormHelperText>
                              )}
                            </div>
                          )}
                          {question.answerType === "dropdown" && (
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <div className="form-control-select">
                                  <select
                                    className={`form-control form-control-lg`}
                                    onChange={(e) =>
                                      handleChange(question.id, e.target.value)
                                    }
                                  >
                                    <option value="">Select an option</option>
                                    {question.options.map((option, index) => (
                                      <option key={index} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              {formErrors[question.id] && (
                                <FormHelperText error>
                                  {formErrors[question.id]}
                                </FormHelperText>
                              )}
                            </div>
                          )}
                          {question.answerType === "checkbox" && (
                            <>
                            <div className="mt-2 prettyprint ">
                            <div className="row">
                              {question.options.map((option, index) => (
                                <div
                                  className="col-md-12 mb-1"
                                  key={`options_${index}`}
                                >
                                  <div className="custom-control custom-control-md custom-checkbox custom-control-pro">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input vdrSelected"
                                      id={`question_checkbox_${question.id}_${index}`}
                                      checked={
                                        formState[question.id]?.includes(
                                          option
                                        ) || false
                                      }
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          question.id,
                                          option,
                                          e.target.checked
                                        )
                                      }
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`question_checkbox_${question.id}_${index}`}
                                    >
                                      {option}
                                    </label>
                                  </div>
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              ))}
                              </div>
                              </div>
                            </>
                          )}

                          {question.answerType === "radio" && (
                            <div className="form-pm-group">
                              <ul className="buysell-pm-list">
                                {question.options.map((option, index) => (
                                  <li className="buysell-pm-item" key={index}>
                                    <input
                                      className="buysell-pm-control"
                                      type="radio"
                                      name={`radio_${question.id}`}
                                      id={`radio_${question.id}_${index}`}
                                      onChange={(e) =>
                                        handleChange(question.id, option)
                                      }
                                    />
                                    <label
                                      className="buysell-pm-label"
                                      htmlFor={`radio_${question.id}_${index}`}
                                    >
                                      <span className="pm-name">{option}</span>
                                      <span className="pm-icon">
                                        <em className="icon ni ni-question"></em>
                                      </span>
                                    </label>
                                  </li>
                                ))}
                              </ul>
                              {formErrors[question.id] && (
                                <FormHelperText error>
                                  {formErrors[question.id]}
                                </FormHelperText>
                              )}
                            </div>
                          )}
                          {question.answerType === "image_input" && (
                            <div className="form-group">
                              <div
                                className="form-control-group"
                                id="fileContainer"
                              >
                                <input
                                  type="file"
                                  id="add_picture"
                                  className="file-block"
                                  name="add_picture"
                                  accept=".png, .jpg, .jpeg, .pdf, .doc, docx, .xlx, .xlxs, .csv"
                                  onChange={(e) =>
                                    handleFileChange(question.id, e)
                                  }
                                />
                              </div>
                              {formErrors[question.id] && (
                                <FormHelperText error>
                                  {formErrors[question.id]}
                                </FormHelperText>
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default FormRenderer;
