import { React } from "react";
import { Component } from "react";
import axios from "axios";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";
import "../../../components/Client/signatureCanvas.css";
import SignatureCanvas from "react-signature-canvas";

import { addSettings } from "../../../actions";
import {
  ValidateEmail,
  axiosConfig,
  normalizePhone,
  ValidatePasswordPattern,
  formatAsCurrency,
} from "../../../config/utility";
import {
  getSingleSalesOrder,
  listSingleClient,
  getPaymentMethod,
  getPaymentLogs,
  listServicesWithPrice,
  getOrderComments,
  getSingleRfq,
  sendOrderEmail,
  rejectSalesOrder,
  getOrderDocuments,
  deleteOrderDocument
} from "./../../../config/client_api_calls";

import $ from "jquery";

import Questions from "../../../components/Client/RequestForQuotation/questions";
import ExtraDetails from "../../../components/Client/RequestForQuotation/extra";

import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import { HeadBodyGrid } from "../../../components/ContentLoaders/newLoaders/HeadBodyGrid";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  APP_LIVE_URL,
  API_URL
} from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import Typography from "@material-ui/core/Typography";
//import { Editor } from "@tinymce/tinymce-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import htmlToFormattedText from "html-to-formatted-text";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import html2canvas from "html2canvas";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const animatedComponents = makeAnimated();

const auth = new HelperClass();
const newHeight = window.screen.height;

class ViewOrder extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      serviceData: [],
      tableDataBundle: [],
      completeData: [],
      defaultServices: [],
      errorMessage: "",
      serviceId: "",
      successMessage: "",
      successMessagePay: "",
      errorMessagePay: "",
      errorMessagePayTitle: "",
      tableLoader: true,
      tableLoaderListing: true,
      mcqLoader: true,
      tableLoaderBundle: true,
      errorMessageEmail: "",
      VerifyBusinessButton: false,
      VerifyFinancialButton: false,
      VerifyTradeButton: false,
      verifyDetailsButton: false,
      resendPinButton: false,
      finalPayButton: false,
      successMessageEmail: "",
      errorMessagePassword: "",
      errorMessageConsent: "",
      errorMessageFinal: "",
      convErrorMessage: "",
      service_success_message: "",
      service_already_eists_message: "",
      payment_method: "bank",
      bank_account_details: "",
      wire_transfer_details: "",
      bank_account_details_plain: "",
      wire_transfer_details_plain: "",
      final_form_step: "signature",
      step: 1,
      email: "",
      copied: false,
      cpied_value: "",
      show_signature: false,
      disabled: false,
      //Client Details
      client_name: "",
      client_company: "",
      client_email: "",
      client_phone: "",
      client_address: "",
      client_city: "",
      client_state: "",
      client_zip: "",
      //Reseller Details
      reseller_name: "",
      reseller_company: "",
      reseller_email: "",
      reseller_phone: "",
      reseller_address: "",
      reseller_city: "",
      reseller_state: "",
      reseller_zip: "",
      //Discount Entries
      discount_type: "amount",
      discount_value: 0,
      discount_value_total: 0,
      discount_reason: "",
      //Shipping Entries
      shipping_title: "",
      shipping_value: 0,
      shipping_value_total: 0,
      quotation_signature: "",
      //Tax Details
      tax_type: "amount",
      tax_value: 0,
      tax_value_total: 0,
      tax_reason: "",
      //Quotation Settings
      quotation_notes: "",
      payment_terms: "",
      currency: "USD",
      contact_person: "",
      contact_email: "",
      validity: "",
      errorMessageInfo: "",
      successMessageInfo: "",
      info_button: false,
      conversations: [],
      quote_accountno: "",
      orderno: "",
      quote_comment_button: false,
      quote_comment: "",

      payErrorMessage: "",
      paySuccessMessage: "",
      paymentLogLoader: true,
      payLogErrorMessage: "",
      paymentMethodLoader: true,

      emailto: "",
      emailcc: "",
      emailbcc: "",
      emailsubject: "",
      emailcontent: "",
      email_button: false,
      emailErrorMessage: "",
      emailErrorMessageTitle: "",
      emailSuccessMessage: "",

      rejection_reason: "",
      rejectErrorMessage: "",
      rejectErrorMessageTitle: "",
      rejectSuccessMessage: "",
      rejectionButton: false,

      service_grand_total: 0,
      service_grand_sub_total: 0,
      rows: [],
      all_Services: [],
      all_select_services: [],
      all_select_services_optional: [],

      signatureText: "",
      signatureClass: "'Amita', cursive",
      signature_type: "draw",

      quote_status: "",

      order_documents: [],
      allowed_documents: 0,
      orderDocumentLoader: true,
      orderDocumentForm: false,
      document_title: "",
      document_attachement: "",
      document_attachement_name: "",
      orderDocumentErrorTitle: "",
      orderDocumentErrorMessage: "",
      orderDocumentSuccessMessage: "",
      upload_document_button: false,
      currentDocumentLink: "",
      currentDocumentType: "",

      rows_service_temp: [
        {
          temp_serv_id: "row-135",
          temp_serv_data: {
            temp_serv_inner_id: 940,
            temp_serv_inner_name: "Item 1",
            temp_serv_inner_quantity: 2,
            temp_serv_inner_unit: "sbc",
            temp_serv_inner_price: 10,
            temp_serv_inner_total: 10,
            temp_serv_inner_type: "service",
          },
        },
      ],
      rows_optional: [],
      rows_optional_temp: [
        {
          temp_opt_id: "row-145",
          temp_opt_data: {
            temp_opt_inner_id: 940,
            temp_opt_inner_name: "Item 1",
            temp_opt_inner_quantity: 2,
            temp_opt_inner_unit: "sbc",
            temp_opt_inner_price: 10,
            temp_opt_inner_total: 10,
            temp_opt_inner_type: "service",
          },
        },
      ],
      rows_custom_temp: [
        {
          temp_id: "row-125",
          temp_data: {
            temp_inner_id: 94,
            temp_inner_name: "Item 1",
            temp_inner_quantity: 2,
            temp_inner_unit: "sbc",
            temp_inner_price: 10,
            temp_inner_total: 10,
            temp_inner_type: "custom",
          },
        },
      ],
    };
    this.cursiveFonts = [
      { name: "Your Signature", value: "'Amita', cursive" },
      { name: "Your Signature", value: "'Bad Script', cursive" },
      { name: "Your Signature", value: "'Damion', cursive" },
      { name: "Your Signature", value: "'Dancing Script', cursive" },
      { name: "Your Signature", value: "'Felipa', cursive" },
      { name: "Your Signature", value: "'Julee', cursive" },
      { name: "Your Signature", value: "'Kaushan Script', cursive" },
      { name: "Your Signature", value: "'Lugrasimo', cursive" },
      { name: "Your Signature", value: "'Pacifico', cursive" },
      { name: "Your Signature", value: "'Sofia', cursive" },
      { name: "Your Signature", value: "'Yellowtail', cursive" },
      // Add more cursive fonts here
    ];
  }

  async componentDidMount() {
    let orderno = this.props.props.match.params.orderno;
    this.setState({ orderno: orderno });
    console.log("quote_accountno: ", orderno);
    if (
      this.props.props.match.params.orderno &&
      this.props.props.match.params.orderno !== ""
    ) {
      this.getSalesOrder(orderno);
      this.getServices();
    }
  }

  getSalesOrder = async (orderno) => {
    const servicesResponce = await getSingleSalesOrder(
      auth.getClientAccount(),
      auth.getClientToken(),
      orderno
    );
    console.log("getSalesOrder: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let discount_value_total = 0;
      let discount_type = "amount";
      let discount_value = 0;
      let discount_reason = "";
      if (
        servicesResponce.data.data[0]["discounts"] &&
        servicesResponce.data.data[0]["discounts"].length > 0
      ) {
        discount_value_total =
          servicesResponce.data.data[0]["discounts"][0]["discount_total"];
        discount_type =
          servicesResponce.data.data[0]["discounts"][0]["discount_type"];
        discount_value =
          servicesResponce.data.data[0]["discounts"][0]["discount_value"];
        discount_reason =
          servicesResponce.data.data[0]["discounts"][0]["discount_reason"];
      }
      let tax_value_total = 0;
      let tax_type = "amount";
      let tax_value = 0;
      let tax_reason = "";
      if (
        servicesResponce.data.data[0]["taxes"] &&
        servicesResponce.data.data[0]["taxes"].length > 0
      ) {
        tax_value_total =
          servicesResponce.data.data[0]["taxes"][0]["tax_total"];
        tax_type = servicesResponce.data.data[0]["taxes"][0]["tax_type"];
        tax_value = servicesResponce.data.data[0]["taxes"][0]["tax_value"];
        tax_reason = servicesResponce.data.data[0]["taxes"][0]["tax_reason"];
      }
      let shipping_value_total = 0;
      let shipping_title = "";
      let shipping_value = "";
      if (
        servicesResponce.data.data[0]["shipping"] &&
        servicesResponce.data.data[0]["shipping"].length > 0
      ) {
        shipping_value_total =
          servicesResponce.data.data[0]["shipping"][0]["shipping_value"];
        shipping_title =
          servicesResponce.data.data[0]["shipping"][0]["shipping_title"];
        shipping_value =
          servicesResponce.data.data[0]["shipping"][0]["shipping_value"];
      }
      //console.log("rfq_questions: "+servicesResponce.data.data);
      let servicesRows = [];
      let total = 0;
      if (
        servicesResponce.data.data[0]["quote_services"] &&
        servicesResponce.data.data[0]["quote_services"].length > 0
      ) {
        let rfq_services = servicesResponce.data.data[0]["quote_services"];
        for (let i = 0; i < rfq_services.length; i++) {
          let newArray = {
            id: `row-${i}`,
            data: {
              id: rfq_services[i]["service_id"],
              sku: rfq_services[i]["sku"],
              name: rfq_services[i]["service_name"],
              quantity: rfq_services[i]["quantity"],
              unit: rfq_services[i]["service_unit"],
              price: rfq_services[i]["service_price"],
              total: Number(
                Number(rfq_services[i]["quantity"]) *
                  rfq_services[i]["service_price"]
              ).toFixed(this.getDecimalPoint()),
              type: rfq_services[i]["item_type"],
            },
          };
          servicesRows.push(newArray);
          total =
            total +
            Number(rfq_services[i]["service_price"]) *
              Number(rfq_services[i]["quantity"]);
        }
      }
      //Minus Discount
      let with_discount = total - parseFloat(discount_value_total);
      //Calculate Tax
      with_discount = with_discount + parseFloat(tax_value_total);
      //Calculate Shipping
      with_discount = with_discount + parseFloat(shipping_value_total);

      //**** Get Optional Services ****/rows_optional
      let servicesOptionalRows = [];
      if (
        servicesResponce.data.data[0]["quote_optional_services"] &&
        servicesResponce.data.data[0]["quote_optional_services"].length > 0
      ) {
        let optional_services =
          servicesResponce.data.data[0]["quote_optional_services"];
        for (let i = 0; i < optional_services.length; i++) {
          let newArrays = {
            id: `row-${i}`,
            data: {
              id: optional_services[i]["service_id"],
              name: optional_services[i]["service_name"],
              quantity: optional_services[i]["quantity"],
              unit: optional_services[i]["service_unit"],
              price: optional_services[i]["service_price"],
              total: Number(
                Number(optional_services[i]["quantity"]) *
                  optional_services[i]["service_price"]
              ).toFixed(this.getDecimalPoint()),
              type: optional_services[i]["item_type"],
            },
          };
          servicesOptionalRows.push(newArrays);
        }
      }
      if (
        servicesResponce.data.data[0]["client_accountno"] &&
        servicesResponce.data.data[0]["client_accountno"] !== ""
      ) {
        this.getSingleClient();
      }
      this.setState({
        rows: servicesRows,
        rows_optional: servicesOptionalRows,
        tableData: servicesResponce.data.data,
        quotation_services: servicesResponce.data.data[0]["quote_services"],
        quote_title: servicesResponce.data.data[0]["quote_title"],
        quote_notes: servicesResponce.data.data[0]["quote_notes"],
        quote_attachement: servicesResponce.data.data[0]["quote_attachement"],
        all_answers: servicesResponce.data.all_answers,
        service_grand_total:
          with_discount && with_discount > 0
            ? this.formatAsCurrency(Number(with_discount))
            : (0).toFixed(this.getDecimalPoint()),
        service_grand_sub_total:
          total && total > 0 ? this.formatAsCurrency(Number(total)) : (0).toFixed(this.getDecimalPoint()),
        discount_value_total: discount_value_total,
        discount_type: discount_type,
        discount_value: discount_value,
        discount_reason: discount_reason,
        tax_value_total: tax_value_total,
        tax_type: tax_type,
        tax_value: tax_value,
        tax_reason: tax_reason,
        shipping_value_total: shipping_value_total,
        shipping_value: shipping_value,
        shipping_title: shipping_title,
        email_sent: servicesResponce.data.data[0]["email_sent"],
        conversations: servicesResponce.data.data[0]["conversations"],
        quote_status: servicesResponce.data.data[0]["quote_status"],
        order_date: servicesResponce.data.data[0]["dateCreated"],
        payment_terms: servicesResponce.data.data[0]["payment_terms"],
        currency: servicesResponce.data.data[0]["currency"],
        validity: servicesResponce.data.data[0]["validity"],
        contact_person: servicesResponce.data.data[0]["contact_person"],
        payment_status: servicesResponce.data.data[0]["payment_status"],
        client_accountno: servicesResponce.data.data[0]["client_accountno"],
        quote_accountno: servicesResponce.data.data[0]["quote_accountno"],
        rfq_accountno: servicesResponce.data.data[0]["rfq_accountno"],
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  };

  getSingleClient = async () => {
    const servicesResponce = await listSingleClient(
      auth.getClientAccount(),
      auth.getClientToken()
    );
    console.log("getSingleClient: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        client_details: servicesResponce.data.client_details,
        //quotation_notes: quotation_notes,
        //quote_notes: quotation_notes,
        client_name:
          servicesResponce.data.client_details[0]["name"] &&
          servicesResponce.data.client_details[0]["name"] !== ""
            ? servicesResponce.data.client_details[0]["name"]
            : "",
        client_company: servicesResponce.data.client_details[0]["comp_name"]
          ? servicesResponce.data.client_details[0]["comp_name"]
          : "",
        client_email: servicesResponce.data.client_details[0]["email"]
          ? servicesResponce.data.client_details[0]["email"]
          : "",
        client_phone: servicesResponce.data.client_details[0]["phone"]
          ? servicesResponce.data.client_details[0]["phone"]
          : "",
        client_address: servicesResponce.data.client_details[0]["address"]
          ? servicesResponce.data.client_details[0]["address"]
          : "",
        client_city: servicesResponce.data.client_details[0]["city"]
          ? servicesResponce.data.client_details[0]["city"]
          : "",
        client_state: servicesResponce.data.client_details[0]["state"]
          ? servicesResponce.data.client_details[0]["state"]
          : "",
        client_zip: servicesResponce.data.client_details[0]["zip"]
          ? servicesResponce.data.client_details[0]["zip"]
          : "",
        reseller_details:
          servicesResponce.data.reseller_details.reseller_details,
        reseller_name: servicesResponce.data.reseller_details
          .reseller_details[0]["name"]
          ? servicesResponce.data.reseller_details.reseller_details[0]["name"]
          : "",
        reseller_company: servicesResponce.data.reseller_details
          .reseller_details[0]["comp_name"]
          ? servicesResponce.data.reseller_details.reseller_details[0][
              "comp_name"
            ]
          : "",
        reseller_email: servicesResponce.data.reseller_details
          .reseller_details[0]["email"]
          ? servicesResponce.data.reseller_details.reseller_details[0]["email"]
          : "",
        reseller_phone: servicesResponce.data.reseller_details
          .reseller_details[0]["phone"]
          ? servicesResponce.data.reseller_details.reseller_details[0]["phone"]
          : "",
        reseller_address: servicesResponce.data.reseller_details
          .reseller_details[0]["address"]
          ? servicesResponce.data.reseller_details.reseller_details[0][
              "address"
            ]
          : "",
        reseller_city: servicesResponce.data.reseller_details
          .reseller_details[0]["city"]
          ? servicesResponce.data.reseller_details.reseller_details[0]["city"]
          : "",
        reseller_state: servicesResponce.data.reseller_details
          .reseller_details[0]["state"]
          ? servicesResponce.data.reseller_details.reseller_details[0]["state"]
          : "",
        reseller_zip: servicesResponce.data.reseller_details
          .reseller_details[0]["zip"]
          ? servicesResponce.data.reseller_details.reseller_details[0]["zip"]
          : "",
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  };

  getServices = async () => {
    const servicesResponce = await listServicesWithPrice(
      auth.getClientAccount(),
      auth.getClientToken()
    );
    console.log("getServices: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_select_services = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          let newArr = {
            label: servicesResponce.data.data[i][1],
            value: servicesResponce.data.data[i][0],
          };
          all_select_services.push(newArr);
        }
      }
      this.setState({
        all_select_services: all_select_services,
        all_select_services_optional: all_select_services,
        all_services: servicesResponce.data.data,
      });
    }
  };

  getPaymentMethod = async () => {
    const { orderno } = this.state;
    this.setState({
      paymentMethodLoader: true,
      payErrorMessage: "",
    });
    const servicesResponce = await getPaymentMethod(
      auth.getClientAccount(),
      auth.getClientToken(),
      orderno
    );
    console.log("getPaymentMethod: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        paymentMethodLoader: false,
        payErrorMessage:
          "There is some error while getting the payment methods.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        payment_method: servicesResponce.data.data[0]["payment_method"],
        payment_details: servicesResponce.data.data[0]["payment_details"],
        paymentMethodLoader: false,
      });
    } else {
      this.setState({
        paymentMethodLoader: false,
        payErrorMessage:
          "There is some error while getting the payment methods.",
      });
      // window.location.replace("/error");
    }
    if (this.state.payErrorMessage !== "") {
      setTimeout(() => {
        this.setState({
          payErrorMessage: "",
        });
      }, 4000);
    }
  };

  getPaymentLogs = async () => {
    const { orderno } = this.state;
    this.setState({
      paymentLogLoader: true,
      payLogErrorMessage: "",
    });
    const servicesResponce = await getPaymentLogs(
      auth.getClientAccount(),
      auth.getClientToken(),
      orderno
    );
    console.log("getPaymentLogs: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        paymentLogLoader: false,
        payLogErrorMessage:
          "There is some error while getting the payment logs.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        payment_logs: servicesResponce.data.data,
        paymentLogLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        payment_logs: [],
        payLogErrorMessage: "Payment logs not found.",
        paymentLogLoader: false,
      });
    } else {
      this.setState({
        paymentLogLoader: false,
        payLogErrorMessage:
          "There is some error while getting the payment logs.",
      });
      // window.location.replace("/error");
    }
  };

  formatPhoneNumber = (phoneNumber) => {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
    if (phoneNumberObj) {
      if (phoneNumberObj.country === "US") {
        const nationalNumber = phoneNumberObj
          .formatNational()
          .replace(/\D/g, ""); // e.g. "2123727200"
        const areaCode = nationalNumber.slice(0, 3);
        const firstPart = nationalNumber.slice(3, 6);
        const secondPart = nationalNumber.slice(6);
        return `(${areaCode}) ${firstPart}-${secondPart}`;
      }
      return phoneNumberObj.formatInternational();
    }
    return phoneNumber; // Return original number if parsing fails
  };

  getOrderComments = async () => {
    const { orderno } = this.state;
    this.setState({
      orderCommentLoader: true,
      payLogErrorMessage: "",
    });
    const servicesResponce = await getOrderComments(
      auth.getClientAccount(),
      auth.getClientToken(),
      orderno
    );
    console.log("getPaymentLogs: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        orderCommentLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        order_comments: servicesResponce.data.data,
        orderCommentLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        payment_logs: [],

        orderCommentLoader: false,
      });
    } else {
      this.setState({
        orderCommentLoader: false,
      });
      // window.location.replace("/error");
    }
  };

  getOrderDocuments = async () => {
    const { orderno } = this.state;
    this.setState({
      orderDocumentLoader: true,
      orderDocumentErrorMessage: "",
      orderDocumentSuccessMessage: "",
    });
    const servicesResponce = await getOrderDocuments(
      auth.getClientAccount(),
      auth.getClientToken(),
      orderno
    );
    console.log("getPaymentLogs: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        orderDocumentLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let allowed_documents = 0;
      if(servicesResponce.data.data){
        allowed_documents = servicesResponce.data.data.length;
      }
      this.setState({
        order_documents: servicesResponce.data.data,
        allowed_documents: allowed_documents,
        orderDocumentLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        order_documents: [],
        orderDocumentLoader: false,
      });
    } else {
      this.setState({
        orderDocumentLoader: false,
      });
      // window.location.replace("/error");
    }
  };

  openUploadDocument = async () => {
    this.setState({
      orderDocumentForm: true,
      orderDocumentErrorTitle: "",
      orderDocumentErrorMessage: "",
      orderDocumentSuccessMessage: "",
      document_attachement: "",
      document_attachement_name: "",
    });
  };

  submitOrderDocument = async () => {
    let {
      orderno,
      document_title,
      document_attachement,
      document_attachement_name,
    } = this.state;
    this.setState({
      upload_document_button: true,
    });
    if (document_attachement_name === "") {
      this.setState({
        orderDocumentErrorMessage: "Document file is required.",
        orderDocumentErrorTitle: "Missing Fields",
        upload_agreement_button: false,
      });
      setTimeout(() => {
        this.setState({
          orderDocumentErrorTitle: "",
          orderDocumentErrorMessage: "",
          upload_document_button: false,
        });
      }, 4000);
      return false;
    } else if (document_title === ""){
      this.setState({
        orderDocumentErrorMessage: "Document title is required.",
        orderDocumentErrorTitle: "Missing Fields",
        upload_agreement_button: false,
      });
      setTimeout(() => {
        this.setState({
          orderDocumentErrorTitle: "",
          orderDocumentErrorMessage: "",
          upload_document_button: false,
        });
      }, 4000);
      return false;
    } else {
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("accountno", auth.getClientAccount());
      formData.append("file", document_attachement);
      formData.append("fileName", document_attachement_name);
      formData.append("orderno", orderno);
      formData.append("document_title", document_title);
      formData.append("added_by", "client");

      try {
        axios
          .post(
            API_URL +
              "sales_orders/upload_order_document",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Credentials": true,
              },
            },
            {}
          )
          .then((response) => {
            console.log("submitSignatory response.data : ", response.data);
            if (
              response.data.status === 404 &&
              response.data.message === "no_record"
            ) {
              this.setState({
                orderDocumentErrorMessage: "Record not found.",
                orderDocumentErrorTitle: "System Error",
                upload_document_button: false,
                orderDocumentSuccessMessage: "",
              });
            } else if (response.data.status === 403) {
              this.setState({
                orderDocumentErrorTitle: "System Error",
                orderDocumentErrorMessage: response.data.message,
                upload_document_button: false,
                orderDocumentSuccessMessage: "",
              });
            } else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                orderDocumentSuccessMessage: "Document uploaded successfully.",
                orderDocumentErrorMessage: "",
                orderDocumentErrorTitle: "",
                upload_document_button: true,
                orderDocumentForm: false,
                document_title: "",
                document_attachement: [],
                document_attachement_name: "",
              });
              setTimeout(() => {
                this.getOrderDocuments();
              }, 2000);
            } else {
              this.setState({
                orderDocumentErrorTitle: "System Error",
                orderDocumentErrorMessage:
                  "There is some error while uploading the document.",
                orderDocumentSuccessMessage: "",
                upload_document_button: false,
              });
            }
          });
      } catch (error) {
        this.setState({
          orderDocumentErrorTitle: "System Error",
          orderDocumentErrorMessage:
            "There is some error while uploading the document.",
          disabled: false,
          orderDocumentSuccessMessage: "",
          upload_document_button: false,
        });

        console.log("catch : ", error);
      }
      setTimeout(() => {
        this.setState({
          orderDocumentErrorMessage: "",
          orderDocumentErrorTitle: "",
        });
      }, 4000);
    }
  };

  deleteDocument = async (document_id) => {
    const { orderno } = this.state;
    this.setState({
      orderDocumentErrorMessage: "",
      orderDocumentSuccessMessage: "",
    });
    const servicesResponce = await deleteOrderDocument(
      auth.getClientAccount(),
      auth.getClientToken(),
      orderno,
      document_id
    );
    console.log("deleteDocument: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        orderDocumentErrorTitle: "Delete Error",
        orderDocumentErrorMessage: "There is some error while deleting the document.",
        orderDocumentSuccessMessage: ""
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        orderDocumentErrorTitle: "",
        orderDocumentErrorMessage: "",
        orderDocumentSuccessMessage: "Order document deleted successfully."
      });
      setTimeout(() => {
        this.getOrderDocuments();
      }, 3000);
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        orderDocumentErrorTitle: "Delete Error",
        orderDocumentErrorMessage: "There is some error while deleting the document.",
        orderDocumentSuccessMessage: ""
      });
      
    } else {
      this.setState({
        orderDocumentErrorTitle: "System Error",
        orderDocumentErrorMessage: "There is some error while deleting the document.",
        orderDocumentSuccessMessage: "" 
      });
      // window.location.replace("/error");
    }
    if(this.state.orderDocumentErrorMessage !== ""){
      setTimeout(() => {
        this.setState({
          orderDocumentErrorMessage: "",
          orderDocumentErrorTitle: "",
        });
      }, 4000);
    }
  };

  getFileExtension = (fileName) => {
    let file_extension = fileName.split(".").pop();

    if (
      file_extension === "xlsx" ||
      file_extension === "xls" ||
      file_extension === "doc" ||
      file_extension === "docx" ||
      file_extension === "csv"
    ) {
      return (
        <a
          style={{ cursor: "pointer" }}
          onClick={() => this.downloadDocument(fileName)}
        >
          <span class="badge badge-outline-secondary">
            <em class="icon ni ni-download"></em> Download
          </span>
        </a>
      );
    } else if (
      file_extension === "png" ||
      file_extension === "jpg" ||
      file_extension === "jpeg" ||
      file_extension === "pdf" ||
      file_extension === "txt" ||
      file_extension === "rtf"
    ) {
      return (
        <a
          style={{ cursor: "pointer" }}
          onClick={() => this.viewDocument(fileName)}
        >
          <span class="badge badge-outline-secondary">
          <em class="icon ni ni-file-check-fill"></em> View
          </span>
        </a>
      );
    }
  };

  handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation selectedFiles
    const allowedFileTypes = [
      ".png",
      ".jpg",
      ".jpeg",
      ".pdf",
      ".doc",
      ".docx",
      ".csv",
      ".xlsx",
      ".xls",
      ".txt",
      ".rtf",
    ];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (!allowedFileTypes.includes(`.${fileExtension}`)) {
      this.setState({
        orderDocumentErrorTitle: "Invalid File",
        orderDocumentErrorMessage:
          "Invalid file type. Please select a valid file format.",
      });
      return;
    }

    // File size validation (e.g., limit to 10MB)
    const maxSizeBytes = 10 * 1024 * 1024; // 10MB
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        orderDocumentErrorTitle: "File Size",
        orderDocumentErrorMessage:
          "File size exceeds the limit (10MB). Please choose a smaller file.",
      });
      return;
    }

    // Clear any previous errors
    this.setState({
      document_attachement: selectedFile,
      orderDocumentErrorTitle: "",
      orderDocumentErrorMessage: "",
      document_attachement_name: selectedFile.name,
    });
    //return true;
  };

  downloadDocument = async (d_f_name) => {
    if (d_f_name !== "") {
      var url = APP_LIVE_URL + "files_data/rfq/" +d_f_name;
      const parts = url.split("/");
      let file_name = d_f_name;

      fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        this.setState({
          orderDocumentErrorTitle: "Download Error",
          orderDocumentErrorMessage: "There is some error while downloading the document.",
        });
        setTimeout(() => {
          this.setState({ orderDocumentErrorMessage: "", orderDocumentErrorTitle: "" });
        }, 4000);
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
      });
    }
  };

  retrieveFileExtension = (fileName) => {
    return fileName.split(".").pop();
  }

  viewDocument = async (file_name) => {
    var url = APP_LIVE_URL + "files_data/rfq/" +file_name;
    let file_extension = this.retrieveFileExtension(file_name);
    let current_type = "application/pdf";
    if(file_extension === "png"){
      current_type = "image/png";
    } else if(file_extension === "jpg" || file_extension === "jpeg"){
      current_type = "image/jpeg";
    } else if(file_extension === "txt"){
      current_type = "text/plain";
    } else if(file_extension === "rtf"){
      current_type = "application/rtf";
    }
    this.setState({
      currentDocumentLink: url,
      currentDocumentType: current_type,
    });
    window.$("#modalMarketingPDFLink").modal("show");
  };

  modalHideMarketingPDF = () => {
    this.setState({
      currentDocumentLink: "",
      currentDocumentType: ""
    });
    window.$("#modalMarketingPDFLink").modal("hide");
  };

  showRequirements = async () => {
    const { rfq_accountno } = this.state;
    this.setState({
      formLoader: true,
      rfq_requirements: "",
    });

    const servicesResponce = await getSingleRfq(
      auth.getClientAccount(),
      auth.getClientToken(),
      rfq_accountno
    );
    console.log("getSingleRfq: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        rfq_details: [],
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      console.log(
        "servicesResponce.data.all_answers: ",
        servicesResponce.data.data[0].all_answers
      );
      if (
        servicesResponce.data.data[0]["rfq_questions"] &&
        servicesResponce.data.data[0]["rfq_questions"].length > 0
      ) {
        let rfq_questions = servicesResponce.data.data[0]["rfq_questions"];
        for (let i = 0; i < rfq_questions.length; i++) {
          if (
            rfq_questions[i]["question_type"] === "radio" ||
            rfq_questions[i]["question_type"] === "dropdown"
          ) {
            let answer_id = this.doesAnswerIdExist(
              Number(rfq_questions[i]["answer_id"]),
              servicesResponce.data.data[0].all_answers
            );

            rfq_questions[i]["answer"] = answer_id;
            console.log(
              "radio answer answer_id: " +
                rfq_questions[i]["answer_id"] +
                " " +
                answer_id
            );
          } else if (rfq_questions[i]["question_type"] === "checkbox") {
            let answer_id = this.doesAnswerArrayExist(
              JSON.parse(rfq_questions[i]["answer_id"]),
              servicesResponce.data.data[0].all_answers
            );

            rfq_questions[i]["answer"] = answer_id;
            console.log(
              "checkbox answer answer_id: " +
                JSON.parse(rfq_questions[i]["answer_id"]) +
                " " +
                answer_id
            );
          } else {
            rfq_questions[i]["answer"] = rfq_questions[i]["answer_id"];
          }
        }
      }

      this.setState({
        rfq_details: servicesResponce.data.data,
        quotation_services: servicesResponce.data.data[0]["rfq_services"],
        rfq_requirements: servicesResponce.data.data[0]["rfq_comments"],
        rfq_attachment: servicesResponce.data.data[0]["rfq_attachement"],
        all_answers: servicesResponce.data.all_answers,
      });
    } else {
      this.setState({
        rfq_details: [],
      });
      // window.location.replace("/error");
    }

    window.$("#modalFormOptions").modal("show");
    setTimeout(() => {
      this.setState({
        formLoader: false,
      });
    }, 2000);
  };

  doesAnswerIdExist = (answerIdToCheck, answerArray) => {
    //return answerArray.some((answer) => answer.answer_id === answerIdToCheck);
    const answer = answerArray.find((item) => item.id === answerIdToCheck);
    return answer ? answer.question_options : "";
  };

  doesAnswerArrayExist = (answerIdToCheck, answerArray) => {
    console.log(
      "answerIdToCheck, answerArray: " + answerIdToCheck + " " + answerArray
    );
    answerIdToCheck = JSON.stringify(answerIdToCheck);
    //return answerArray.some((answer) => answer.answer_id === answerIdToCheck);
    const objectValues = answerIdToCheck.slice(1, -1).split(",");
    var matchingItems = "";
    objectValues.map((setString, index) => {
      console.log("setString: ", setString);
      let answer = answerArray.find((item) => item.id === Number(setString));
      if (answer) {
        matchingItems +=
          "<p>" + (Number(index) + 1) + ": " + answer.question_options + "</p>";
      }
    });

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: matchingItems,
        }}
      />
    );
  };

  downloadOrderPdf = async (d_f_name) => {
    //var url = d_f_name;
    let file_name = d_f_name;
    var url = APP_LIVE_URL + "files_data/rfq/" + d_f_name;
    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "There is some error while downloading the attachment.",
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 4000);
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
      });
  };

  fieldValidation = () => {
    let { emailto, emailsubject, emailcontent } = this.state;

    if (emailto === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        emailErrorMessage: "To email address is required.",
      });
      return false;
    } else if (this.validateEmailAdd(emailto) === false) {
      this.setState({
        emailErrorMessageTitle: "Invalid Format",
        emailErrorMessage: "Invalid email address format.",
      });
      return false;
    } else if (emailsubject === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        emailErrorMessage: "Subject is required.",
      });
      return false;
    } else if (emailcontent === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        emailErrorMessage: "Custom content is required.",
      });
      return false;
    } else {
      this.setState({
        emailErrorMessageTitle: "",
        emailErrorMessage: "",
      });
    }
    return true;
  };

  submitOrderEmail = async () => {
    const { orderno, emailto, emailcc, emailbcc, emailsubject, emailcontent } =
      this.state;

    if (this.fieldValidation() === true) {
      this.setState({
        email_button: true,
        emailErrorMessage: "",
        emailErrorMessageTitle: "",
        emailSuccessMessage: "",
      });
      const servicesResponce = await sendOrderEmail(
        auth.getClientAccount(),
        auth.getClientToken(),
        orderno,
        emailto,
        emailcc,
        emailbcc,
        emailsubject,
        emailcontent
      );
      console.log("submitOrderEmail: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.clientLogout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          emailErrorMessageTitle: "System Error",
          emailErrorMessage:
            "There is some error while processing your request.",
          email_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          emailSuccessMessage: "Email sent successfully.",
          payment_status: servicesResponce.data.data,
          emailErrorMessageTitle: "",
          emailErrorMessage: "",
          emailsubject: "",
          emailcontent: "",
          email_button: false,
        });
      } else {
        this.setState({
          emailErrorMessageTitle: "System Error",
          emailErrorMessage:
            "There is some error while processing your request.",
          email_button: false,
        });
      }
    }
    if (
      this.state.emailErrorMessage !== "" ||
      this.state.emailSuccessMessage !== ""
    ) {
      setTimeout(() => {
        this.setState({
          emailErrorMessage: "",
          emailSuccessMessage: "",
        });
      }, 4000);
    }
  };

  format_date = (value) => {
    let date = value;
    if (date && date !== "") {
      return dateFormat(date, "mmmm dS, yyyy");
    } else {
      return "---";
    }
  };

  validateEmailAdd = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    //console.log("isValidEmail: " + " " + email + " = " + isValidEmail);
    if (isValidEmail) {
      return true;
    }
    return false;
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  openEmailWindow = async () => {
    const { orderno } = this.state;
    this.setState({
      email_button: false,
      emailErrorMessage: "",
      emailErrorMessageTitle: "",
      emailSuccessMessage: "",
    });
    window.$("#modalFormEmail").modal("show");
  };

  rejectSalesOrder = async () => {
    const { orderno } = this.state;
    this.setState({
      rejection_reason: "",
      rejectErrorMessage: "",
      rejectSuccessMessage: "",
    });
    window.$("#modalRejectSalesOrder").modal("show");
  };

  rejectQuotationSubmit = async () => {
    const { orderno, rejection_reason } = this.state;
    this.setState({
      rejectionButton: true,
      rejectErrorMessage: "",
      rejectSuccessMessage: "",
    });
    if (rejection_reason === "") {
      this.setState({
        rejectErrorMessage: "Please enter the reason.",
        rejectErrorMessageTitle: "Missing Fields",
      });
    } else {
      const rejectionResponse = await rejectSalesOrder(
        auth.getClientAccount(),
        auth.getClientToken(),
        orderno,
        rejection_reason
      );
      console.log("rejectionResponse: ", rejectionResponse.data);
      if (
        rejectionResponse.data.status === 403 ||
        rejectionResponse.data.errors === "authentication missing" ||
        rejectionResponse.data.errors === "jwt expired"
      ) {
        auth.clientLogout();
      } else if (
        rejectionResponse.data.status === 404 &&
        rejectionResponse.data.message === "not_found"
      ) {
        this.setState({
          rejectErrorMessage: "Sales order not found.",
          rejectErrorMessageTitle: "Missing Sales Order",
          rejectSuccessMessage: "",
          rejectionButton: false,
        });
      } else if (
        rejectionResponse.data.status === 200 &&
        rejectionResponse.data.message === "success"
      ) {
        this.setState({
          rejectErrorMessage: "",
          rejectErrorMessageTitle: "",
          rejectSuccessMessage: "Sales order rejected successfully.",
          rejectionButton: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        this.setState({
          rejectErrorMessage:
            "There is some error while processing your request.",
          rejectErrorMessageTitle: "Sales Order Error",
          rejectSuccessMessage: "",
          rejectionButton: false,
        });
      }
      setTimeout(() => {
        this.setState({
          rejectErrorMessage: "",
          rejectErrorMessageTitle: "",
          rejectSuccessMessage: "",
        });
      }, 4000);
    }
  };

  formatAsCurrency = (amount) => {
    let decimal_point = this.getDecimalPoint();
    if (typeof amount === "string") {
      amount = parseFloat(amount);
    }
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: decimal_point,
      maximumFractionDigits: decimal_point,
    });
  };

  getDecimalPoint = () => {
    if (this.props.quotationSettings[0]?.decimal_point && this.props.quotationSettings[0]?.decimal_point !== "") {
      const decimalPoint = this.props.quotationSettings[0]?.decimal_point;
      return decimalPoint !== "" ? Number(decimalPoint) : 2;
    }
    return 2;
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  render() {
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");

    return (
      <div className="nk-content rfq_Block" id="editQuotations">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        color="inherit"
                        onClick={() =>
                          this.addMenuHistory(
                            "/" + auth.getResellerRegToken() + "/client/"
                          )
                        }
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <HomeIcon fontSize="inherit" className="" />
                        Home
                      </Link>
                      <Link
                        color="inherit"
                        onClick={() =>
                          this.addMenuHistory(
                            "/" +
                              auth.getResellerRegToken() +
                              "/client/SalesOrders"
                          )
                        }
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Sales Orders
                      </Link>

                      <Typography color="textPrimary" className="">
                        Order Details
                      </Typography>
                    </Breadcrumbs>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="card card-bordered">
                  <div className="card-aside-wrap">
                    <div className="card-content">
                      <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#tabOrderSummary"
                          >
                            <em className="icon ni ni-user-circle"></em>
                            <span>Order Summary</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            onClick={() => this.getPaymentMethod()}
                            href="#tabPaymentMethod"
                          >
                            <em className="icon ni ni-repeat"></em>
                            <span>Payments</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            onClick={() => this.getPaymentLogs()}
                            href="#tabPaymentLogs"
                          >
                            <em className="icon ni ni-file-text"></em>
                            <span>Payment Logs</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            onClick={() => this.getOrderComments()}
                            href="#tabOrderComments"
                          >
                            <em className="icon ni ni-bell"></em>
                            <span>Comments</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            onClick={() => this.getOrderDocuments()}
                            href="#tabOrderDocuments"
                          >
                            <em class="icon ni ni-file-docs"></em>
                            <span>Order Documents</span>
                          </a>
                        </li>
                        {/*<li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabOrderQuotation"
                          >
                            <em className="icon ni ni-activity"></em>
                            <span>Quotation</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabOrderRfq"
                          >
                            <em className="icon ni ni-activity"></em>
                            <span>RFQ</span>
                          </a>
                      </li>*/}
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active" id="tabOrderSummary">
                          <div
                            className="invoice-wrap"
                            style={{ border: "0px", padding: "2rem" }}
                          >
                            <div className="invoice-head">
                              <div className="invoice-contact">
                                <span className="overline-title">
                                  Invoice To
                                </span>
                                <div className="invoice-contact-info">
                                  <h4 className="title">
                                    {this.state.client_company}
                                  </h4>
                                  <ul className="list-plain">
                                    {this.state.client_address &&
                                    this.state.client_address !== "" ? (
                                      <li>
                                        <em className="icon ni ni-map-pin-fill"></em>
                                        <span className="text-dark">
                                          {this.state.client_address &&
                                          this.state.client_address !== ""
                                            ? this.state.client_address
                                            : ""}
                                          <br />
                                          {this.state.client_city &&
                                          this.state.client_city !== ""
                                            ? this.state.client_city
                                            : ""}{" "}
                                          {this.state.client_state &&
                                          this.state.client_state !== ""
                                            ? this.state.client_state
                                            : ""}{" "}
                                          {this.state.client_zip &&
                                          this.state.client_zip !== ""
                                            ? this.state.client_zip
                                            : ""}
                                        </span>
                                      </li>
                                    ) : null}
                                    <li>
                                      <em className="icon ni ni-call-fill"></em>
                                      <span className="text-dark">
                                        {this.state.client_phone &&
                                        this.state.client_phone !== ""
                                          ? this.formatPhoneNumber(
                                              this.state.client_phone
                                            )
                                          : "---"}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="invoice-desc right_invoice">
                                <ul className="list-plain">
                                  <li className="invoice-date">
                                    <span>Date</span>:
                                    <span>
                                      {this.state.order_date &&
                                      this.state.order_date !== ""
                                        ? this.format_date(
                                            this.state.order_date
                                          )
                                        : ""}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="invoice-bills">
                              <div className="table-responsive">
                                <table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th style={{paddingLeft: "1.25rem"}}>Item ID</th>
                                      <th>SKU</th>
                                      <th>Description</th>
                                      <th>Qty</th>
                                      <th>Unit</th>
                                      <th>Price</th>
                                      <th>Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.rows.map((row, index) => (
                                      <>
                                        {row.data.type &&
                                        row.data.type === "service" ? (
                                          <tr key={`serv-${index}`}>
                                            <td>
                                              {row.data.id && row.data.id !== ""
                                                ? row.data.id
                                                : "---"}
                                            </td>
                                            <td>
                                              {row.data.sku &&
                                              row.data.sku !== ""
                                                ? row.data.sku
                                                : "---"}
                                            </td>
                                            <td>
                                              {row.data.name &&
                                              row.data.name !== ""
                                                ? row.data.name
                                                : "---"}
                                            </td>

                                            <td>{row.data.quantity}</td>
                                            <td>{row.data.unit}</td>
                                            <td>{this.formatAsCurrency(Number(row.data.price))}</td>
                                            <td>
                                              {this.formatAsCurrency(
                                                Number(row.data.total)
                                              )}
                                            </td>
                                          </tr>
                                        ) : row.data.type === "section" ? (
                                          <tr key={`serv-${index}`}>
                                            <td
                                              className="text-left"
                                              style={{ fontWeight: "bold" }}
                                              colSpan={7}
                                            >
                                              {row.data.name}
                                            </td>
                                          </tr>
                                        ) : row.data.type === "custom" ? (
                                          <tr key={`serv-${index}`}>
                                            <td>{row.data.id}</td>
                                            <td>
                                              {row.data.sku &&
                                              row.data.sku !== ""
                                                ? row.data.sku
                                                : "---"}
                                            </td>
                                            <td>
                                              {row.data.name &&
                                              row.data.name !== ""
                                                ? row.data.name
                                                : "---"}
                                            </td>
                                            <td>{row.data.quantity}</td>
                                            <td>{row.data.unit}</td>
                                            <td>{this.formatAsCurrency(Number(row.data.price))}</td>
                                            <td>
                                              {this.formatAsCurrency(
                                                Number(row.data.total)
                                              )}
                                            </td>
                                          </tr>
                                        ) : null}
                                      </>
                                    ))}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td colSpan="4"></td>
                                      <td colSpan="2">Subtotal</td>
                                      <td>
                                        {this.state.service_grand_sub_total}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan="4"></td>
                                      <td colSpan="2">Est. Discount</td>
                                      <td>
                                        {this.formatAsCurrency(
                                          parseFloat(
                                            this.state.discount_value_total
                                          )
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan="4"></td>
                                      <td colSpan="2">Est. Shipping</td>
                                      <td>
                                        {this.formatAsCurrency(
                                          parseFloat(
                                            this.state.shipping_value_total
                                          )
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan="4"></td>
                                      <td colSpan="2">Est. TAX</td>
                                      <td>
                                        {this.formatAsCurrency(
                                          parseFloat(this.state.tax_value_total)
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan="4"></td>
                                      <td colSpan="2">Grand Total</td>
                                      <td>{this.state.service_grand_total}</td>
                                    </tr>
                                  </tfoot>
                                </table>
                                {this.state.quote_notes &&
                                this.state.quote_notes !== "" ? (
                                  <div className="card card-bordered mt-5">
                                    <div className="card-inner">
                                      <div className="nk-help">
                                        <div className="nk-help-text">
                                          <h5>Notes</h5>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: this.state.quote_notes,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="tabPaymentMethod">
                          <div className="col-md-12">
                            {this.state.payErrorMessage !== "" ? (
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h6>Error</h6>
                                  <p>{this.state.payErrorMessage}</p>
                                </div>
                              </div>
                            ) : null}
                            {this.state.paySuccessMessage !== "" ? (
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h6>Success</h6>
                                  <p>{this.state.paySuccessMessage}</p>
                                </div>
                              </div>
                            ) : null}
                            {this.state.paymentMethodLoader === true ? (
                              FormLoader()
                            ) : (
                              <div className="card card-bordered mt-2">
                                <div className="card-inner">
                                  <div className="nk-help">
                                    <div className="nk-help-text">
                                      {/*<h5>Payment Method</h5>
                                      <p className="prettyprint2 mt-2 mb-4">
                                        {this.state.payment_method === "bank"
                                          ? "Bank Account"
                                          : this.state.payment_method === "wire"
                                          ? "Wire Transfer"
                                          : ""}
                            </p>*/}
                                      <h5>
                                        {this.state.payment_method === "bank"
                                          ? "Payment Method Details"
                                          : this.state.payment_method === "wire"
                                          ? "Payment Method Details"
                                          : ""}
                                      </h5>
                                      <p className="prettyprint2 mt-4">
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: this.state.payment_details,
                                          }}
                                        />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="tab-pane" id="tabPaymentLogs">
                          <div className="col-md-12">
                            {this.state.paymentLogLoader === true ? (
                              FormLoader()
                            ) : (
                              <div className="card card-bordered mt-2">
                                <div className="card-inner">
                                  <div className="nk-help">
                                    <div className="nk-help-text">
                                      {this.state.payment_logs &&
                                      this.state.payment_logs.length > 0 ? (
                                        <>
                                          <h5>Payment Logs</h5>
                                          <div className="table-responsive">
                                            <table className="table table-striped">
                                              <thead>
                                                <tr>
                                                  <th>Order #</th>
                                                  <th>Method</th>
                                                  <th>Date</th>
                                                  <th>Amount</th>
                                                  <th>Receipt</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {this.state.payment_logs.map(
                                                  (logs, index) => (
                                                    <tr key={`log-${index}`}>
                                                      <td>{logs.orderno}</td>
                                                      <td>
                                                        {logs.payment_method ===
                                                        "bank"
                                                          ? "Bank Account"
                                                          : logs.payment_method ===
                                                            "wire"
                                                          ? "Wire Transfer"
                                                          : ""}
                                                      </td>
                                                      <td>
                                                        {this.format_date(
                                                          logs.dateCreated
                                                        )}
                                                      </td>
                                                      <td>
                                                        {" "}
                                                        {this.formatAsCurrency(
                                                          parseFloat(
                                                            logs.amount
                                                          )
                                                        )}
                                                      </td>
                                                      <td>
                                                        {logs.payment_receipt &&
                                                        logs.payment_receipt !==
                                                          ""
                                                          ? logs.payment_receipt
                                                          : "---"}
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </>
                                      ) : (
                                        <div className="alert alert-pro alert-warning">
                                          <div className="alert-text">
                                            <h6>Not Found</h6>
                                            <p>Payment logs not found. </p>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="tab-pane" id="tabOrderComments">
                          <div className="col-md-12">
                            {this.state.orderCommentLoader === true ? (
                              FormLoader()
                            ) : (
                              <div className="card card-bordered mt-2">
                                <div className="card-inner">
                                  <div className="nk-help">
                                    <div className="nk-help-text">
                                      {this.state.order_comments &&
                                      this.state.order_comments.length > 0 ? (
                                        <>
                                          {this.state.order_comments.map(
                                            (quote, index) => (
                                              <div
                                                className="nk-reply-item"
                                                key={`keys-${index}`}
                                              >
                                                <div className="nk-reply-header">
                                                  <div className="user-card">
                                                    {quote.from_admin !== "" ? (
                                                      <div className="user-avatar sm bg-blue">
                                                        <span>AD</span>
                                                      </div>
                                                    ) : quote.from_client !==
                                                      "" ? (
                                                      <div className="user-avatar sm bg-pink">
                                                        <span>CL</span>
                                                      </div>
                                                    ) : null}

                                                    <div className="user-name">
                                                      {quote.from_admin !== ""
                                                        ? quote.from_admin
                                                        : quote.from_client !==
                                                          ""
                                                        ? quote.from_client
                                                        : null}
                                                    </div>
                                                  </div>
                                                  <div className="date-time">
                                                    {this.format_date(
                                                      quote.dateAdded
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="nk-reply-body">
                                                  <div className="nk-reply-entry entry">
                                                    <p>Hello,</p>
                                                    <p>{quote.conversation}</p>
                                                    {quote.from_admin !== "" ? (
                                                      <p>
                                                        Thank you <br />{" "}
                                                        {quote.from_admin}
                                                      </p>
                                                    ) : null}
                                                    {quote.from_client !==
                                                    "" ? (
                                                      <p>
                                                        Thank you <br />{" "}
                                                        {quote.from_client}
                                                      </p>
                                                    ) : null}
                                                  </div>
                                                  {quote.quote_file !== "" ? (
                                                    <div className="attach-files invoicefiles">
                                                      <ul className="attach-list">
                                                        <li className="attach-item">
                                                          <a
                                                            className="download"
                                                            href="#"
                                                          >
                                                            <em className="icon ni ni-img"></em>
                                                            <span>
                                                              {quote.quote_file}
                                                            </span>
                                                          </a>
                                                        </li>
                                                      </ul>
                                                      <div className="attach-foot">
                                                        <span className="attach-info">
                                                          1 file attached
                                                        </span>
                                                        {this.state
                                                          .download_start ===
                                                        true ? (
                                                          <div
                                                            className="spinner-border spinner-border-sm"
                                                            role="status"
                                                          >
                                                            <span className="sr-only">
                                                              Loading...
                                                            </span>
                                                          </div>
                                                        ) : (
                                                          <a
                                                            className="attach-download link"
                                                            onClick={() =>
                                                              this.downloadPdf(
                                                                quote.quote_file
                                                              )
                                                            }
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            <em className="icon ni ni-download"></em>
                                                            <span>
                                                              Download
                                                            </span>
                                                          </a>
                                                        )}
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </>
                                      ) : (
                                        <div className="alert alert-pro alert-warning">
                                          <div className="alert-text">
                                            <h6>Not Found</h6>
                                            <p>Order comments not found. </p>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="tab-pane" id="tabOrderDocuments">
                          <div className="col-md-12">
                            {this.state.orderDocumentLoader === true ? (
                              FormLoader()
                            ) : (
                              <>
                                <div className="nk-block-head nk-block-head-sm">
                                  <div className="nk-block-between">
                                    <div className="nk-block-head-content">
                                      <h5 class="nk-block-title">
                                        <em
                                          class="icon ni ni-file-docs"
                                          style={{ marginRight: "15px" }}
                                        ></em>
                                        Order Documents
                                      </h5>
                                    </div>
                                    <div
                                      className="nk-block-head-content"
                                      id="HeadContentAgent"
                                    >
                                      <div className="toggle-wrap nk-block-tools-toggle">
                                        <a
                                          href="#"
                                          className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                          data-target="pageMenu"
                                        >
                                          <em className="icon ni ni-more-v"></em>
                                        </a>
                                        <div
                                          className="toggle-expand-content"
                                          data-content="pageMenu"
                                        >
                                          <ul className="nk-block-tools g-3">
                                            {this.state.allowed_documents < 6 ? (
                                            <li className="nk-block-tools-opt">
                                              <Link
                                                color="inherit"
                                                onClick={() =>
                                                  this.openUploadDocument()
                                                }
                                                className="btn btn-primary"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  cursor: "pointer",
                                                  color: "#fff",
                                                }}
                                              >
                                                <em className="icon ni ni-plus-round"></em>
                                                &nbsp;Add New Document
                                              </Link>
                                            </li>
                                            ) : null}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {this.state.orderDocumentErrorMessage !== "" ? (
                                  <div class="alert alert-pro alert-danger mb-2 mt-2">
                                    <div class="alert-text">
                                      <h6>
                                        {this.state.orderDocumentErrorTitle}
                                      </h6>
                                      <p>
                                        {this.state.orderDocumentErrorMessage}{" "}
                                      </p>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.orderDocumentSuccessMessage !==
                                "" ? (
                                  <div class="alert alert-pro alert-success mb-2 mt-2">
                                    <div class="alert-text">
                                      <h6>Success</h6>
                                      <p>
                                        {this.state.orderDocumentSuccessMessage}{" "}
                                      </p>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.orderDocumentForm === true ? (
                                  <div class="card card-bordered mt-2">
                                    <div class="card-inner">
                                      <div class="nk-help">
                                        <div class="nk-help-text">
                                          <div
                                            className="col-md-12 mb-2"
                                            style={{
                                              marginBottom: "20px",
                                              clear: "both",
                                            }}
                                          >
                                            <div className="form-group">
                                              <div class="form-group">
                                                <label
                                                  class="form-label"
                                                  for="document_title"
                                                >
                                                  Title <sup>*</sup>
                                                </label>
                                                <div class="form-control-wrap">
                                                  <input
                                                    type="text"
                                                    class="form-control form-control-lg"
                                                    id="document_title"
                                                    name="document_title"
                                                    onChange={this.handleChange}
                                                    defaultValue={
                                                      this.state.document_title
                                                    }
                                                    placeholder="Add document title with a maximum of 100 characters.."
                                                    maxLength={100}
                                                    autoComplete="no"
                                                  />
                                                </div>
                                              </div>
                                              <div className="form-label-group">
                                                <label className="form-label">
                                                  <Tooltip
                                                    title="Upload order document."
                                                    placement="right"
                                                  >
                                                    <em
                                                      className="icon ni ni-info"
                                                      style={{
                                                        color: "#6576ff",
                                                      }}
                                                    ></em>
                                                  </Tooltip>{" "}
                                                  Upload Document <sup>*</sup>
                                                  <span className="text-danger"></span>
                                                </label>
                                              </div>
                                              <div className="form-control-group signatory_upload mb-4">
                                                <div class="custom-file">
                                                  <input
                                                    type="file"
                                                    class="custom-file-input"
                                                    id="document_attachement"
                                                    name="document_attachement"
                                                    defaultValue={
                                                      this.state
                                                        .document_attachement
                                                    }
                                                    accept=".png, .jpg, .jpeg, .doc, .docx, .pdf, .txt, .rtf, .csv, .xlsx, .xls"
                                                    onChange={
                                                      this.handleFileChange
                                                    }
                                                  />
                                                  <label
                                                    class="custom-file-label"
                                                    for="document_attachement"
                                                  >
                                                    Choose file
                                                  </label>
                                                </div>
                                              </div>
                                              <div class="form-note">
                                                {
                                                  this.state
                                                    .document_attachement_name
                                                }
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-12 mt-5">
                                            <div className="example-alert">
                                              <div className="alert alert-light">
                                                <ul className="list">
                                                  <li>
                                                    Accepted Formats: PDF, DOC,
                                                    DOCX, TXT, RTF, PNG, JPEG,
                                                    JPG, CSV, XLS, XLSX
                                                  </li>
                                                  <li>
                                                    Recommended Size: 10MB
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-12 mt-4 mb-2">
                                            <div class="d-flex justify-content-end">
                                              <button
                                                type="button"
                                                class="btn btn-lg btn-light mr-2"
                                                onClick={() =>
                                                  this.setState({
                                                    upload_document_button: false,
                                                    orderDocumentForm: false,
                                                  })
                                                }
                                              >
                                                Cancel
                                              </button>
                                              {this.state
                                                .upload_document_button ===
                                              true ? (
                                                <button
                                                  className="btn btn-lg btn-primary"
                                                  disabled
                                                >
                                                  <em className="icon ni ni-swap"></em>{" "}
                                                  Saving
                                                  <div
                                                    className="spinner-grow spinner-grow-sm"
                                                    role="status"
                                                  >
                                                    <span className="sr-only">
                                                      Loading...
                                                    </span>
                                                  </div>
                                                </button>
                                              ) : (
                                                <button
                                                  type="button"
                                                  class="btn btn-lg btn-primary"
                                                  onClick={() =>
                                                    this.submitOrderDocument()
                                                  }
                                                >
                                                  Save Document
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <div class="example-alert">
                                                            <div class="alert alert-light ">
                                                            Please note: A maximum of six documents are allowed. </div>
                                                        </div>
                                <div class="card card-bordered mt-2">
                                  <div class="card-inner">
                                    <div class="nk-help">
                                      <div class="nk-help-text">
                                        {this.state.order_documents &&
                                        this.state.order_documents.length >
                                          0 ? (
                                          <>
                                            <div class="table-responsive">
                                              <table class="table table-striped">
                                                <thead>
                                                  <tr>
                                                    <th>Uploaded By</th>
                                                    <th>Title</th>
                                                    <th>Date</th>
                                                    <th>Document</th>
                                                    <th>Action</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {this.state.order_documents.map(
                                                    (documents, index) => (
                                                      <tr key={`log-${index}`}>
                                                        <td>
                                                          {documents.added_by}
                                                        </td>
                                                        <td>
                                                          {
                                                            documents.document_title
                                                          }
                                                        </td>
                                                        <td>
                                                          {this.format_date(
                                                            documents.dateCreated
                                                          )}
                                                        </td>
                                                        <td>
                                                          {" "}
                                                          {this.getFileExtension(
                                                            documents.document_attachement
                                                          )}
                                                        </td>
                                                        <td>
                                                          <a
                                                            onClick={() =>
                                                              this.deleteDocument(
                                                                documents.id
                                                              )
                                                            }
                                                            className="text-danger fs-16px"
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            <em class="icon ni ni-trash"></em>
                                                          </a>
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </>
                                        ) : (
                                          <div class="alert alert-pro alert-warning">
                                            <div class="alert-text">
                                              <h6>No Record</h6>
                                              <p>Documents not found. </p>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="tab-pane" id="tabOrderQuotation">
                          <div className="col-md-12">
                            <div className="card card-bordered mt-2">
                              <div className="card-inner">
                                <div className="nk-help">
                                  <div className="nk-help-text">
                                    <h5>Order Quotations</h5>
                                    <p>Order Quotations</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="tabOrderRfq">
                          <div className="col-md-12">
                            <div className="card card-bordered mt-2">
                              <div className="card-inner">
                                <div className="nk-help">
                                  <div className="nk-help-text">
                                    <h5>Order RFQ</h5>
                                    <p>Order RFQ</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="card-aside card-aside-right user-aside toggle-slide toggle-slide-right toggle-break-xxl"
                      data-content="userAside"
                      data-toggle-screen="xxl"
                      data-toggle-overlay="true"
                      data-toggle-body="true"
                    >
                      <div className="card-inner-group" data-simplebar>
                        <div className="card-inner">
                          <div className="user-card user-card-s2">
                            <div className="user-info">
                              <div className="badge badge-lg badge-outline-primary badge-pill ucap fw-bold">
                                Order #: {this.state.orderno}
                              </div>
                              <h5>{this.state.client_name}</h5>
                              <span className="sub-text">
                                {this.state.client_email}
                              </span>

                              {this.state.quote_status &&
                              this.state.quote_status === "cancelled" ? (
                                <div className="badge badge-lg badge-outline-danger badge-pill ucap fw-bold mt-2">
                                  Order Status: {this.state.quote_status}
                                </div>
                              ) : this.state.quote_status &&
                                this.state.quote_status === "completed" ? (
                                <div className="badge badge-lg badge-outline-success badge-pill ucap fw-bold mt-2">
                                  Order Status: {this.state.quote_status}
                                </div>
                              ) : null}
                              {this.state.payment_status &&
                              this.state.payment_status === "unpaid" ? (
                                <div className="badge badge-lg badge-outline-danger badge-pill ucap fw-bold mt-2">
                                  Payment Status: {this.state.payment_status}
                                </div>
                              ) : this.state.payment_status &&
                                this.state.payment_status === "paid" ? (
                                <div className="badge badge-lg badge-outline-success badge-pill ucap fw-bold mt-2">
                                  Payment Status: {this.state.payment_status}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="card-inner card-inner-sm">
                          <ul className="btn-toolbar justify-center gx-1">
                            <li className="mr-2">
                              <Tooltip title="Email" placement="top">
                                <a
                                  onClick={() => this.openEmailWindow()}
                                  className="btn btn-trigger btn-icon"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-mail fs-20px"></em>
                                </a>
                              </Tooltip>
                            </li>
                            <li className="mr-2">
                              <Tooltip
                                title="Download Sales Order PDF"
                                placement="top"
                              >
                                <a
                                  onClick={() =>
                                    this.downloadOrderPdf(
                                      this.state.quote_attachement
                                    )
                                  }
                                  className="btn btn-trigger btn-icon"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-download-cloud fs-20px"></em>
                                </a>
                              </Tooltip>
                            </li>
                            {this.state.quote_status === "completed" &&
                            this.state.payment_status === "unpaid" ? (
                              <li>
                                <Tooltip title="Cancel Order" placement="top">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.rejectSalesOrder()}
                                    className="btn btn-trigger btn-icon text-danger"
                                  >
                                    <em className="icon ni ni-na fs-20px text-danger"></em>
                                  </a>
                                </Tooltip>
                              </li>
                            ) : null}
                          </ul>
                        </div>
                        <div className="card-inner">
                          <div className="overline-title-alt mb-2">
                            Grand Total
                          </div>
                          <div className="profile-balance">
                            <div className="profile-balance-group gx-4">
                              <div className="profile-balance-sub">
                                <div className="profile-balance-amount">
                                  <div className="number">
                                    {this.state.service_grand_total}{" "}
                                  </div>
                                </div>
                                <div className="profile-balance-subtitle">
                                  Order Amount
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-inner">
                          <h6 className="overline-title-alt mb-2">
                            Recurring Billing
                          </h6>
                          <div className="row text-center">
                            <div className="col-4">
                              <div className="profile-stats">
                                <span className="amount">0</span>
                                <span className="sub-text"> Invoices</span>
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="profile-stats">
                                <span className="amount">${(0).toFixed(this.getDecimalPoint())}</span>
                                <span className="sub-text"> Payments</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-inner">
                          <h6 className="overline-title-alt mb-2">
                            Additional
                          </h6>
                          <div className="row g-3">
                            <div className="col-6">
                              <span className="sub-text">From:</span>
                              <span>{this.state.reseller_company}</span>
                            </div>
                            <div className="col-6">
                              <span className="sub-text">Payment terms:</span>
                              <span>{this.state.payment_terms}</span>
                            </div>
                            <div className="col-6">
                              <span className="sub-text">Currency:</span>
                              <span>{this.state.currency}</span>
                            </div>

                            <div className="col-6">
                              <span className="sub-text">Validity:</span>
                              <span>{this.state.validity} Days</span>
                            </div>
                            {this.state.quote_accountno &&
                            this.state.quote_accountno !== "" ? (
                            <div className="col-6">
                              <span className="sub-text">Quotation:</span>
                              <a
                                onClick={() =>
                                  this.addMenuHistory(
                                    "/EditQuotations/" +
                                      this.state.client_accountno +
                                      "/" +
                                      this.state.quote_accountno
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <span>
                                  <span className="badge badge-outline-primary">
                                    <em className="icon ni ni-card-view"></em>{" "}
                                    View
                                  </span>
                                </span>
                              </a>
                            </div>
                            ) : null}
                            {this.state.rfq_accountno &&
                            this.state.rfq_accountno !== "" ? (
                              <div className="col-6">
                                <span className="sub-text">RFQ:</span>
                                <a
                                  onClick={() => this.showRequirements()}
                                  style={{ cursor: "pointer" }}
                                >
                                  <span>
                                    <span className="badge badge-outline-primary">
                                      <em className="icon ni ni-card-view"></em>{" "}
                                      View
                                    </span>
                                  </span>
                                </a>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* RFQ MODAL */}
              <div
                className="modal fade zoom"
                tabIndex="-1"
                id="modalFormOptions"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-list-round"></em> RFQ
                          Details{" "}
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* NRC DIVS START */}

                      {this.state.formLoader === true ? (
                        FormLoader()
                      ) : (
                        <>
                          <div>
                            <div className="card card-bordered">
                              <div className="card-inner-group">
                                {this.state.rfq_details &&
                                this.state.rfq_details[0].rfq_questions.length >
                                  0 ? (
                                  <div className="card-inner">
                                    <div className="sp-plan-head">
                                      <h6 className="title">
                                        General Assesment
                                      </h6>
                                    </div>
                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                      <table className="table table-striped">
                                        <thead>
                                          <tr>
                                            <th className="w-150px">
                                              Question
                                            </th>
                                            <th className="w-60">Answer</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.rfq_details[0].rfq_questions.map(
                                            (rfq, index) => (
                                              <tr key={index}>
                                                <td>{rfq.question}</td>
                                                <td>{rfq.answer}</td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.quotation_services &&
                                this.state.quotation_services.length > 0 ? (
                                  <div className="card-inner">
                                    <div className="sp-plan-head">
                                      <h6 className="title">
                                        Products & Services
                                      </h6>
                                    </div>
                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                      {this.state.rfq_details &&
                                      this.state.rfq_details.length > 0 ? (
                                        <table className="table table-striped">
                                          <thead>
                                            <tr>
                                              <th className="w-60">
                                                Product Name
                                              </th>
                                              <th>Qty</th>
                                              <th>Unit</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.quotation_services.map(
                                              (service, sid) => (
                                                <tr key={`ser${sid}`}>
                                                  <td>
                                                    {service.service_name}
                                                  </td>
                                                  <td>{service.quantity}</td>
                                                  <td>
                                                    {service.service_unit}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                      ) : null}
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.rfq_attachment &&
                                this.state.rfq_attachment !== "" ? (
                                  <div className="card-inner">
                                    <div className="sp-plan-head">
                                      <h6 className="title">Attachement</h6>
                                    </div>
                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                      <ul className="row gx-1">
                                        <li className="col-sm-5">
                                          <p>
                                            <a
                                              onClick={() =>
                                                this.downloadPdfRfq(
                                                  this.state.rfq_attachment
                                                )
                                              }
                                              style={{ cursor: "pointer" }}
                                              title="Download Attachment"
                                            >
                                              <span className="badge badge-outline-secondary">
                                                {" "}
                                                <em className="icon ni ni-download"></em>{" "}
                                                Download Attachment
                                              </span>
                                            </a>
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                ) : null}

                                <div className="card-inner">
                                  <div className="sp-plan-head-group">
                                    <div className="sp-plan-head">
                                      <h6 className="title">Requirement</h6>
                                      <span className="ff-italic text-soft">
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: this.state.rfq_requirements,
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* RFQ Modal */}
              {/* Email Modal */}
              <div class="modal fade" tabindex="-1" id="modalFormEmail">
                <div
                  class="modal-dialog modal-dialog-top modal-xl "
                  role="document"
                >
                  <div class="modal-content">
                    <a
                      href="#"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross"></em>
                    </a>
                    <div class="modal-header">
                      <h5 class="modal-title">
                        <em class="icon ni ni-emails"></em> Send Invoice Email
                      </h5>
                    </div>
                    <div class="modal-body">
                      <div class="row g-gs">
                        <div class="col-sm-12">
                          {this.state.emailErrorMessage !== "" ? (
                            <div class="alert alert-pro alert-danger">
                              <div class="alert-text">
                                <h6>{this.state.emailErrorMessageTitle}</h6>
                                <p>{this.state.emailErrorMessage}</p>
                              </div>
                            </div>
                          ) : null}
                          {this.state.emailSuccessMessage !== "" ? (
                            <div class="alert alert-pro alert-success">
                              <div class="alert-text">
                                <h6>Success</h6>
                                <p>{this.state.emailSuccessMessage}</p>
                              </div>
                            </div>
                          ) : null}
                          <div class="form-group">
                            <label class="form-label" for="emailto">
                              To <sup>*</sup>
                            </label>
                            <div class="form-control-wrap">
                              <input
                                type="text"
                                class="form-control form-control-lg"
                                id="emailto"
                                name="emailto"
                                defaultValue={this.state.emailto}
                                onChange={this.handleChange}
                                placeholder="Enter email address"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label class="form-label" for="emailcc">
                              Cc
                            </label>
                            <div class="form-control-wrap">
                              <input
                                type="text"
                                class="form-control form-control-lg"
                                id="emailcc"
                                name="emailcc"
                                defaultValue={this.state.emailcc}
                                onChange={this.handleChange}
                                placeholder="Enter CC email addresses separated by commas"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label class="form-label" for="emailbcc">
                              Bcc
                            </label>
                            <div class="form-control-wrap">
                              <input
                                type="text"
                                class="form-control form-control-lg"
                                id="emailbcc"
                                name="emailbcc"
                                defaultValue={this.state.emailbcc}
                                onChange={this.handleChange}
                                placeholder="Enter Bcc email addresses separated by commas"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 mt-2">
                          <div class="form-group">
                            <label class="form-label" for="emailsubject">
                              Subject <sup>*</sup>
                            </label>
                            <div class="form-control-wrap">
                              <input
                                type="text"
                                class="form-control form-control-lg"
                                id="emailsubject"
                                name="emailsubject"
                                defaultValue={this.state.emailsubject}
                                onChange={this.handleChange}
                                placeholder="Email Subject"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 mt-2">
                          <div class="form-group">
                            <label class="form-label" for="emailcontent">
                              Custom Message <sup>*</sup>
                            </label>
                            <div class="form-control-wrap">
                              <textarea
                                class="form-control"
                                id="emailcontent"
                                name="emailcontent"
                                defaultValue={this.state.emailcontent}
                                onChange={this.handleChange}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 mt-2 d-flex justify-content-end">
                          <div class="nk-block-head-content">
                            <ul class="nk-block-tools gx-3">
                              {this.state.email_button === true ? (
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  disabled
                                >
                                  <span
                                    className="spinner-grow spinner-grow-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span> Processing... </span>
                                </button>
                              ) : (
                                <li>
                                  <a
                                    onClick={() => this.submitOrderEmail()}
                                    style={{
                                      cursor: "pointer",
                                      color: "#fff",
                                    }}
                                    class="btn btn-primary"
                                  >
                                    <em class="icon ni ni-check-round-cut"></em>{" "}
                                    <span>Send Email</span>{" "}
                                  </a>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Email Modal */}
              {/****** Rejection Modal *****/}
              <div class="modal fade" tabindex="-1" id="modalRejectSalesOrder">
                <div
                  class="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div class="modal-content">
                    <a
                      href="#"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross"></em>
                    </a>
                    <div class="modal-header">
                      <h5 class="modal-title">
                        <em class="icon ni ni-cross-round-fill"></em> Reject
                        Order
                      </h5>
                    </div>
                    <div class="modal-body">
                      <div className="row g-gs">
                        {this.state.rejectErrorMessage !== "" ? (
                          <div class="col-sm-12 mb-4">
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>{this.state.rejectErrorMessageTitle}</h4>
                                  <p>{this.state.rejectErrorMessage}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {this.state.rejectSuccessMessage !== "" ? (
                          <div class="col-sm-12 mb-4">
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.rejectSuccessMessage}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div class="col-sm-12 mb-4">
                          <div class="form-group">
                            <label class="form-label" for="default-textarea">
                              Reason <sup>*</sup>
                            </label>
                            <div class="form-control-wrap">
                              <textarea
                                class="form-control"
                                id="rejection_reason"
                                name="rejection_reason"
                                defaultValue={this.state.rejection_reason}
                                onChange={this.handleChange}
                                placeholder="Please provide the reason for rejecting the order in this space."
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-gs d-flex justify-content-end mt-5 border-top">
                        <div className="" style={{ float: "right" }}>
                          {this.state.rejectionButton === true ? (
                            <button
                              className="btn btn-primary btn-lg"
                              type="button"
                              id="loading_btn"
                              style={{
                                margin: "13px 12px 12px 10px",
                              }}
                              disabled
                            >
                              <span
                                className="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span> Processing... </span>
                            </button>
                          ) : this.state.rejectionButton === false ? (
                            <button
                              className="btn btn-primary btn-lg"
                              style={{
                                margin: "13px 12px 12px 10px",
                              }}
                              onClick={() => this.rejectQuotationSubmit()}
                              id=""
                            >
                              <em class="icon ni ni-cross-round-fill"></em>{" "}
                              <span>Reject</span>{" "}
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/****** Rejection Modal *****/}
              {/* Link Modal MODAL START */}
              <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalMarketingPDFLink"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-list-round"></em>{" "}
                              Order Document
                            </strong>
                          </div>
                          <a
                            className="close"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.modalHideMarketingPDF();
                            }}
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          <br />
                          <embed
                            src={this.state.currentDocumentLink}
                            type={this.state.currentDocumentType}
                            width="100%"
                            height={newHeight * 0.61}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* LINK MODAL End */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
    quotationSettings: state.quotationSettings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewOrder);
