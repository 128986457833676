import react, { Component } from "react";
import Footer from "./footer";
import Header from "./Header";
import ContentLoader from "react-content-loader";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import { addSettings, addMsaInfo, setClientLeftMenu, setQuotationSettings } from "../../actions";

import LefeSideBar from "./leftSideBar";
import Dashboard from "../../Pages/Client/Dashboard";
import Services from "../../Pages/Client/Services";
import Agreements from "../../Pages/Client/Agreements";
import UnsignedAgreements from "../../Pages/Client/Agreements/unsignedagreements";
import Pdfdownload from "../../Pages/Client/Agreements/pdfdownload";

import Help from "../../Pages/Client/Help";
import CreditApp from "../../Pages/Client/CreditApp";
import AddCreditApp from "../../Pages/Client/CreditApp/addCreditApp";

import SubClients from "../../Pages/Client/SubClients";
import AddSubClient from "../../Pages/Client/SubClients/addsubclient";

import AccountActivity from "../../Pages/Client/AccountActivity";
import KycForm from "../../Pages/Client/KycForm";
import Rfqs from "../../Pages/Client/Rfqs";
import AddRfq from "../../Pages/Client/Rfqs/addRfq";
import EditRfq from "../../Pages/Client/Rfqs/editRfq";

import Quotations from "../../Pages/Client/Quotations";
import ViewQuotation from "../../Pages/Client/Quotations/viewQuotation";

import SalesOrders from "../../Pages/Client/SalesOrders";
import ViewSalesOrder from "../../Pages/Client/SalesOrders/viewOrder";
import EditSalesOrder from "../../Pages/Client/SalesOrders/addOrder";

import ClientDocuments from "../../Pages/Client/Documents";

import Forms from "../../Pages/Client/Forms";
import FormSubmit from "../../Pages/Client/Forms/form_submit";
import FormSubmissions from "../../Pages/Client/Forms/form_submissions";

import BillingInformation from "../../Pages/Client/Billing";
import Invoices from "../../Pages/Client/Billing/Invoices";
import PaymentHistory from "../../Pages/Client/Billing/PaymentHistory";
import Pricing from "../../Pages/Client/Billing/Pricing";

import Loaders from "../../Pages/Client/Loaders";

import Cdr from "../../Pages/Client/Reporting";
import SmsRecords from "../../Pages/Client/Reporting/SmsRecords";
import MouStats from "../../Pages/Client/Reporting/MouStats";
import BalanceUsage from "../../Pages/Client/Reporting/BalanceUsage";

import MSA from "../../Pages/Reseller/MSA";
import DashboardLoader from "../ContentLoaders/dashboard_loader";
import ErrorPage from "../Errors/404";
import AccessDenied from "../Errors/ClientAccessDenied";

import { getAccountData, profileUpdate, clientLeftMenu, getQuotationSettings } from "../../config/client_api_calls";
import {
  LOGO,
  THEME_COLOR,
  THEME_TEXT_COLOR,
  VENDOR_SIGNATURE,
} from "../../config/config";
import { REPLACECONTENT, ValidateEmail, normalize } from "../../config/utility";
import HelperClass from "./../../config/helperClass";
import Agreement from "./agreement_new";

import TextField from "../InputFields/TextField";

const auth = new HelperClass();

const pageAssociations = {
  CreditApp: ["AddCreditApp"],
  SalesOrders: ["ViewSalesOrder", "EditSalesOrder"],
  Rfqs: ["AddRfq", "EditRfq"],
  Quotations: ["ViewQuotation"],
  SubClients: ["AddSubClient"],
  forms: ["client_form_submit", "client_form_submissions"],
  //Forms: ["forms"]
};

class Main extends Component {
  constructor(props) {
    super(props);

    if (auth.isClientLogin() === false) {
      auth.clientLogout();
    }

    this.state = {
      view: this.props.view,
      activePage: "",
      is_msa_signed: false,
      Agreement_Content: "",
      title_agreement: "",
      bgColor: "#ffffff",
      vendor_signor_signature_file: "",
      published_date: "",
      defaultLoader: true,
      errorCompName: "",
      comp_name: "",
      errorName: "",
      name: "",
      errorTitle: "",
      title: "",
      errorEmail: "",
      email: "",
      errorPhone: "",
      phone: "",
      errorPass: "",
      password: "",
      confirm_password: "",
      errorAddress: "",
      address: "",
      errorCity: "",
      city: "",
      errorState: "",
      states: "",
      errorZip: "",
      zip: "",
      errorMessage: "",
      disabled: "",
      successMessage: "",
      routingUrl: "",
      filer_id: "",
      passwordShown: false,
      confirmPasswordShown: false,
    };
  }

  async componentDidMount() {
    this.setState({
      comp_name: auth.getCompNameClient(),
      name: auth.getNameClient(),
      email: auth.getEmailClient(),
      phone: auth.getPhoneClient(),
      filer_id: auth.getFilerID(),
      title: auth.getTitleClient() === false ? "" : auth.getTitleClient(),
    });
    this.defaultChecks();
    await this.getLeftMenu();
    await this.getQuotationSetting();
  }

  getLeftMenu = async () => {
    let acctno = auth.getClientAccount();

    let res = await clientLeftMenu(
      acctno,
      auth.getClientToken(),
      auth.getEmailClient()
    );
    //console.log("getClientLeftMenu: ", res.data);
    if (
      res.data.status === 200 &&
      res.data.message === "success" &&
      res.data.data.length > 0
    ) {
      // Dispatch action to update left menu in Redux
      this.props.setClientLeftMenu(res.data.data);
    }
  };

  getQuotationSetting = async () => {
    let acctno = auth.getClientAccount();

    let res = await getQuotationSettings(
      acctno,
      auth.getClientToken(),
      auth.getEmailClient()
    );
    //console.log("getQuotationSettings: ", res.data);
    if (
      res.data.status === 200 &&
      res.data.message === "success" &&
      res.data.data.length > 0
    ) {
      // Dispatch action to update left menu in Redux
      this.props.setQuotationSettings(res.data.data);
    }
  };

  formUpdate = async () => {
    let {
      name,
      email,
      comp_name,
      title,
      phone,
      address,
      city,
      states,
      zip,
      password,
      confirm_password,
      is_msa_signed
    } = this.state;

    let is_validated = true;
    if (comp_name === "") {
      this.setState({
        errorCompName: "error",
        errorMessage: "Company Name is required.",
      });
      is_validated = false;
    } else if (name === "") {
      this.setState({
        errorCompName: "",
        errorName: "error",
        errorMessage: "Full Name is required.",
      });
      is_validated = false;
    } /*else if (title === "") {
      this.setState({
        errorCompName: "",
        errorName: "",
        errorTitle: "error",
        errorMessage: "Title is required.",
      });
      is_validated = false;
    }*/ else if (phone === "") {
      this.setState({
        errorPhone: "error",
        errorMessage: "Phone is required.",
      });
      is_validated = false;
    } else if (normalize(phone) === null) {
      this.setState({
        errorPhone: "error",
        errorMessage: "Invalid phone format.",
      });
      is_validated = false;
    } else if (ValidateEmail(email) === false) {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessage: "Invalid email format.",
      });
      is_validated = false;
    } else if (password !== "") {
      if (password.length < 8) {
        this.setState({
          errorPass: "error",
          errorEmail: "",

          errorPhone: "",
          errorMessage: "Use 8 characters or more for your password",
        });
        is_validated = false;
      } else if (confirm_password === "") {
        this.setState({
          errorPass: "error",

          errorEmail: "",
          errorPhone: "",
          errorMessage: "Confirm password is required.",
        });
        is_validated = false;
      } else if (confirm_password.length < 8) {
        this.setState({
          errorPass: "error",

          errorEmail: "",

          errorPhone: "",
          errorMessage: "Use 8 characters or more for your confirm password",
        });
        is_validated = false;
      } else if (password.trim() != confirm_password.trim()) {
        this.setState({
          errorPass: "error",

          errorEmail: "",

          errorPhone: "",
          errorMessage: "Password and confirm password does not match.",
        });
        is_validated = false;
      }
    }
    if (is_validated) {
      this.setState({
        errorPass: "",
        errorEmail: "",
        errorMessage: "",
        errorConfirm: "",
        errorPhone: "",
        errorTitle: "",
        disabled: true,
      });

      let apiResponce = await profileUpdate(
        auth.getClientAccount(),
        auth.getClientToken(),
        name,
        title,
        comp_name,
        phone,
        address,
        city,
        states,
        zip,
        password
      );
      //console.log(apiResponce);
      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.clientLogout();
      } else if (
        apiResponce.data.status === 409 &&
        apiResponce.data.message === "Address validation failed"
      ) {
        this.setState({
          errorMessage:
            "Address validation failed. Check if zip, state, city, and country are correctly matched.",
          disabled: false,
        });
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "success"
      ) {
        Cookies.set(
          "client_comp_nme__",
          CryptoJS.AES.encrypt(
            apiResponce.data.data[0]["comp_name"],
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "client_name__",
          CryptoJS.AES.encrypt(
            apiResponce.data.data[0]["name"],
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "client_phone__",
          CryptoJS.AES.encrypt(
            apiResponce.data.data[0]["phone"],
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "client_title__",
          CryptoJS.AES.encrypt(
            apiResponce.data.data[0]["title"],
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );

        this.setState({
          successMessage: "Profile updated successfully.",
          disabled: false,
        });
        if(is_msa_signed === false){
          window.location.reload();
        }
        setTimeout(async function () {
          window.$("#modalFormProfile").modal("hide");
        }, 2000);
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "fail"
      ) {
        this.setState({
          errorMessage: "Something went wrong,try later.",
          disabled: false,
        });
        return false;
      } else {
        auth.clientLogout();
      }
    }
  };

  togglePassword = () => {
    this.setState({
      passwordShown: !this.state.passwordShown,
    });
  };

  toggleConfirmPassword = () => {
    this.setState({
      confirmPasswordShown: !this.state.confirmPasswordShown,
    });
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  agreementSigned = () => {
    this.setState({ is_msa_signed: true });
  };

  defaultChecks = async () => {
    let apiData = await getAccountData(
      auth.getClientAccount(),
      auth.getClientToken()
    );
    //console.log("Agreement DATA: ", apiData.data);
    if (
      apiData.data.status === 403 ||
      apiData.data.errors === "authentication missing" ||
      apiData.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (apiData.data.status === 404 || (apiData.data.data && apiData.data.data.length === 0)) {
      console.log("First 404 Error");
      //this.props.history.push("/error");
    } else if (apiData.data.status === 200 && apiData.data.data.length > 0) {
      this.props.addSettings(apiData.data.resellers_setting);
      this.props.addMsaInfo(apiData.data.agreement);

      this.setState({
        address: apiData.data.data[0]["address"],
        city: apiData.data.data[0]["city"],
        states: apiData.data.data[0]["state"],
        zip: apiData.data.data[0]["zip"],
        routingUrl: apiData.data.url,
      });
      if (apiData.data.agreement === "No") {
        this.setState({
          is_msa_signed: false,
          defaultLoader: false,
        });
      } else if (apiData.data.agreement === "Yes") {
        this.setState({
          is_msa_signed: true,
          defaultLoader: false,
        });
      }
    } else if (
      (apiData.data.status === 200 &&
        apiData.data.data[0]["isVerified"] === "No") ||
      apiData.data.data[0]["account_status"] !== "active"
    ) {
      auth.clientLogout();
    } else {
      console.log("Last Error");
      //this.props.history.push("/error");
    }
  };

  pageView = () => {
    const { leftClientMenuItems } = this.props;

    let urlFromParams = window.location.pathname;
    let urlSegments = urlFromParams.split("/");

    urlSegments = urlSegments.slice(1);

    if (urlSegments[0] !== "client") {
      urlSegments = urlSegments.slice(1);
    }

    const firstPart = urlSegments.slice(0, 2).join("/");
    //console.log("URL segments:", urlSegments);
    //console.log("First part of URL:", firstPart);

    const matchedMenuItem = leftClientMenuItems.find((menuItem) => {
      const menuItemLink = menuItem.link.startsWith("/")
        ? menuItem.link.slice(1)
        : menuItem.link;

      const submenuLinks = menuItem.submenu.map((submenuItem) =>
        submenuItem.link.startsWith("/")
          ? submenuItem.link.slice(1)
          : submenuItem.link
      );

      const menuItemLinkNormalized = `/${menuItemLink}`.toLowerCase();
      const firstPartNormalized = `/${firstPart}`.toLowerCase();

      if (
        menuItemLinkNormalized === firstPartNormalized ||
        submenuLinks.some(
          (submenuLink) =>
            `/${submenuLink}`.toLowerCase() === firstPartNormalized
        )
      ) {
        return true;
      }

      return false;
    });

    const shouldRenderComponent = !!matchedMenuItem;

    if (shouldRenderComponent) {
      return this.renderComponent();
    }

    const mainPages = Object.keys(pageAssociations);
    for (const mainPage of mainPages) {
      if (pageAssociations[mainPage].includes(this.state.view)) {
        const mainPageMatched = leftClientMenuItems.find((menuItem) => {
          const menuItemLink = menuItem.link.startsWith("/")
            ? menuItem.link.slice(1)
            : menuItem.link;

          const submenuLinks = menuItem.submenu.map((submenuItem) =>
            submenuItem.link.startsWith("/")
              ? submenuItem.link.slice(1)
              : submenuItem.link
          );

          const menuItemLinkNormalized = `/${menuItemLink}`.toLowerCase();
          const mainPageNormalized = `/client/${mainPage.toLowerCase()}`;

          if (
            menuItemLinkNormalized === mainPageNormalized ||
            submenuLinks.some(
              (submenuLink) =>
                `/${submenuLink}`.toLowerCase() === mainPageNormalized
            )
          ) {
            return true;
          }

          return false;
        });

        if (mainPageMatched) {
          return this.renderComponent();
        }
      }
    }

    setTimeout(() => {
      this.setState({ showAccessDenied: true });
    }, 500);

    if (this.state.showAccessDenied) {
      return <AccessDenied />;
    }
  };

  renderComponent = () => {
    let shouldRenderServices = false;
    let shouldRenderOrders = false;
    let shouldRenderRfq = false;
    switch (this.state.view) {
      case "dashboard":
        shouldRenderServices = this.props.leftClientMenuItems.some(
          (menuItem) => {
            if (menuItem.link === "/client/services") {
              return true;
            } else if (menuItem.submenu && menuItem.submenu.length > 0) {
              return menuItem.submenu.some(
                (submenuItem) => submenuItem.link === "/client/services"
              );
            }
            return false;
          }
        );

        shouldRenderOrders = this.props.leftClientMenuItems.some((menuItem) => {
          if (menuItem.link === "/client/SalesOrders") {
            return true;
          } else if (menuItem.submenu && menuItem.submenu.length > 0) {
            return menuItem.submenu.some(
              (submenuItem) => submenuItem.link === "/client/SalesOrders"
            );
          }
          return false;
        });

        shouldRenderRfq = this.props.leftClientMenuItems.some((menuItem) => {
          if (menuItem.link === "/client/Rfqs") {
            return true;
          } else if (menuItem.submenu && menuItem.submenu.length > 0) {
            return menuItem.submenu.some(
              (submenuItem) => submenuItem.link === "/client/Rfqs"
            );
          }
          return false;
        });

        return (
          <Dashboard
            props={this.props}
            shouldRenderServices={shouldRenderServices}
            shouldRenderOrders={shouldRenderOrders}
            shouldRenderRfq={shouldRenderRfq}
          />
        );
      case "services":
        return <Services props={this.props} />;
      case "Pdfdownload":
        return <Pdfdownload props={this.props} />;
      case "UnsignedAgreements":
        return <UnsignedAgreements props={this.props} />;
      case "SubClients":
        return <SubClients props={this.props} />;
      case "AddSubClient":
        return <AddSubClient props={this.props} />;
      case "help":
        return <Help props={this.props} />;
      case "KycForm":
        return <KycForm props={this.props} />;
      case "Rfqs":
        return <Rfqs props={this.props} />;
      case "AddRfq":
        return <AddRfq props={this.props} />;
      case "EditRfq":
        return <EditRfq props={this.props} />;
      case "Quotations":
        return <Quotations props={this.props} />;
      case "ViewQuotation":
        return <ViewQuotation props={this.props} />;
      case "SalesOrders":
        return <SalesOrders props={this.props} />;
      case "ViewSalesOrder":
        return <ViewSalesOrder props={this.props} />;
      case "EditSalesOrder":
        return <EditSalesOrder props={this.props} />;
      case "AccountActivity":
        return <AccountActivity props={this.props} />;
      case "BillingInformation":
        return <BillingInformation props={this.props} />;
      case "Invoices":
        return <Invoices props={this.props} />;
      case "PaymentHistory":
        return <PaymentHistory props={this.props} />;
      case "Pricing":
        return <Pricing props={this.props} />;
      case "Loaders":
        return <Loaders props={this.props} />;
      case "Cdr":
        return <Cdr props={this.props} />;
      case "SmsRecords":
        return <SmsRecords props={this.props} />;
      case "MouStats":
        return <MouStats props={this.props} />;
      case "BalanceUsage":
        return <BalanceUsage props={this.props} />;
      case "CreditApp":
        return <CreditApp props={this.props} />;
      case "AddCreditApp":
        return <AddCreditApp props={this.props} />;
      case "agreements":
        return <Agreements props={this.props} />;
      case "documents":
        return <ClientDocuments props={this.props} />;
      case "msa_signing":
        return <MSA />;
      case "forms":
          return <Forms props={this.props}  />;
      case "client_form_submit":
        return <FormSubmit props={this.props}  />;   
      case "client_form_submissions":    
        return <FormSubmissions props={this.props}  />;  
      default:
        return <ErrorPage />;
    }
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  pageChange = (data) => {
    this.props.history.push("/" + data);
  };

  profileView = () => {
    this.setState({ successMessage: "", errorMessage: "" });
    window.$("#modalFormProfile").modal("show");
  };

  render() {
    return (
      <div className="nk-body bg-lighter npc-general has-sidebar ">
        <div className="nk-app-root">
          <div className="nk-main">
            {this.state.defaultLoader === true ? (
              <div>
                <div>
                {this.state.is_msa_signed === true ? (
                    <LefeSideBar
                      mainProps={this.props}
                      menuArr={this.props.leftClientMenuItems}
                      routingUrl={this.state.routingUrl}
                      {...this.props}
                    />
                  ) : null}
                  
                </div>
                {/*<div>
                  <DashboardLoader />
            </div>*/}
              </div>
            ) : (
              <div>
                
                {this.state.is_msa_signed === true ? (
                    <LefeSideBar
                      mainProps={this.props}
                      menuArr={this.props.leftClientMenuItems}
                      routingUrl={this.state.routingUrl}
                      {...this.props}
                    />
                  ) : null}
              </div>
            )}

            <div className="nk-wrap ">
              <Header
                isNdaSigned={this.state.is_msa_signed}
                modalOpen={() => {
                  this.profileView();
                }}
                mainProps={this.props}
              />

              {this.state.is_msa_signed === true ? (
                this.pageView()
              ) : (
                <Agreement
                  backgroundColor={this.state.bgColor}
                  vendor_signor_signature_file={
                    this.state.vendor_signor_signature_file
                  }
                  mainHistory={this.props}
                  agreementSigned={() => this.agreementSigned()}
                />
              )}
              {/* MODAL EDIT PROFILE START */}
              <div className="modal fade" tabIndex="-1" id="modalFormProfile">
                <div className="modal-dialog modal-xl" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Edit Profile</h5>
                      <a
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* Error Start */}
                      {this.state.errorMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />


                      <div className="row g-4">
                      {this.state.filer_id && this.filer_id != "" && (
                          <div className="col-lg-4">
                            <div className="form-group">
                              <TextField
                                label="499 Filer ID"
                                error={this.state.errorCompName}
                                id="filer_id"
                                name="filer_id"
                                value={this.state.filer_id}
                                placeholder="499 Filer ID"
                                change={this.handleChange}
                                disabled={true}
                                type="text"
                              />
                            </div>
                          </div>
                        )}
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Company Name"
                              error={this.state.errorCompName}
                              id="comp_name"
                              name="comp_name"
                              value={this.state.comp_name}
                              placeholder="Company Name"
                              change={this.handleChange}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Full Name"
                              error={this.state.errorName}
                              id="name"
                              name="name"
                              value={this.state.name}
                              placeholder="Full Name"
                              change={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Title"
                              error={this.state.errorTitle}
                              id="title"
                              name="title"
                              value={this.state.title}
                              placeholder="Title"
                              change={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Email"
                              type="text"
                              error={this.state.errorEmail}
                              id="email"
                              name="email"
                              value={this.state.email}
                              placeholder="Enter your email address"
                              change={this.handleChange}
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Phone"
                              type="text"
                              error={this.state.errorPhone}
                              id="phone"
                              name="phone"
                              value={this.state.phone}
                              placeholder="Enter your phone"
                              change={this.handleChange}
                              
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Address"
                              type="text"
                              error={this.state.errorAddress}
                              id="address"
                              name="address"
                              value={this.state.address}
                              placeholder="Enter your address"
                              change={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="City"
                              type="text"
                              error={this.state.errorCity}
                              id="city"
                              name="city"
                              value={this.state.city}
                              placeholder="Enter your city"
                              change={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="State"
                              type="text"
                              error={this.state.errorState}
                              id="states"
                              name="states"
                              value={this.state.states}
                              placeholder="Enter your state"
                              change={this.handleChange}
                              
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Zip Code"
                              type="text"
                              error={this.state.errorZip}
                              id="zip"
                              name="zip"
                              value={this.state.zip}
                              placeholder="Enter your zip code"
                              change={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group" style={{ position: "relative" }}>
                            <TextField
                              label="Password"
                              type={
                                this.state.passwordShown ? "text" : "password"
                              }
                              error={this.state.errorPass}
                              id="password"
                              name="password"
                              value={this.state.password}
                              placeholder="Enter your password"
                              change={this.handleChange}
                            />
                            <a
                              style={{
                                left: "auto",
                                right: "2px",
                                top: "53px",
                                position: "absolute",
                                cursor: "pointer",
                              }}
                              tabIndex="-1"
                              onClick={() => {
                                this.togglePassword();
                              }}
                              className="form-icon form-icon-right passcode-switch"
                              data-target="password"
                            >
                              {this.state.passwordShown ? (
                                <em className="icon ni ni-eye-off"></em>
                              ) : (
                                <em className="icon ni ni-eye"></em>
                              )}
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group" style={{ position: "relative" }}>
                            <TextField
                              label="Confirm Password"
                              type={
                                this.state.confirmPasswordShown
                                  ? "text"
                                  : "password"
                              }
                              error={this.state.errorPass}
                              id="confirm_password"
                              name="confirm_password"
                              value={this.state.confirm_password}
                              placeholder="Enter confirm password"
                              change={this.handleChange}
                            />
                            <a
                              style={{
                                left: "auto",
                                right: "2px",
                                top: "53px",
                                position: "absolute",
                                cursor: "pointer",
                              }}
                              tabIndex="-1"
                              onClick={() => {
                                this.toggleConfirmPassword();
                              }}
                              className="form-icon form-icon-right passcode-switch"
                              data-target="password"
                            >
                              {this.state.confirmPasswordShown ? (
                                <em className="icon ni ni-eye-off"></em>
                              ) : (
                                <em className="icon ni ni-eye"></em>
                              )}
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-4">&nbsp;</div>
                        <div style={{ clear: "both" }}></div>
                        {/* <div className="form-group">
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                        <div className="form-control-wrap">
                          <a
                            tabIndex="-1"
                            href="#"
                            className="form-icon form-icon-right passcode-switch"
                            data-target="password"
                          >
                            <em className="passcode-icon icon-show icon ni ni-eye"></em>
                            <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                          </a>
                          <input
                            type="password"
                            className={
                              "form-control form-control-lg " +
                              this.state.errorPass
                            }
                            id="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            placeholder="Enter your password"
                          />
                        </div>
                      </div> */}
                        <div className="col-lg-12 text-right">
                          <button
                            type="button"
                            className="btn btn-lg"
                            onClick={() => {
                              this.formUpdate();
                            }}
                            style={{
                              backgroundColor: THEME_COLOR,
                              color: THEME_TEXT_COLOR,
                              float: "right",
                            }}
                            disabled={this.state.disabled}
                          >
                            Update Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* END MODAL EDIT PROFILE */}
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { state, leftClientMenuItems: state.leftClientMenuItems, quotationSettings: state.quotationSettings, };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
    addMsaInfo: (payload) => dispatch(addMsaInfo(payload)),
    setClientLeftMenu: (menuClientItems) =>
      dispatch(setClientLeftMenu(menuClientItems)),
    setQuotationSettings: (quotationsettings) => dispatch(setQuotationSettings(quotationsettings)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
