const moment = require("moment");
const $ = require("jquery");

export const ValidateEmail = (mail) => {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      mail
    )
  ) {
    // This part checks if there's a valid top-level domain
    if (/\.[a-zA-Z]{2,}$/.test(mail)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const ValidateDomain = (domain) => {
  if (
    /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(domain)
  ) {
    return true;
  } else {
    return false;
  }
};

export const axiosConfig = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "http://localhost:3000",
    "Access-Control-Allow-Credentials": true,
    useCredentails: true,
  },
};

export const normalize = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export const formatAsCurrency = (amount) => {
  return amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const normalizePhone = (phoneNumberString) => {
  var phone = phoneNumberString;
  if (!phone.match("[0-9]{10}")) {
    return null;
  } else {
    return phone;
  }
};

export const REPLACECONTENT = (strContent, ObjContent, date) => {
  //let COMPANY_SIGN = `<img id = 'imgSigCust' src=[[CLIENT_SIGN]] width = '230px' heigth='100px' alt = 'Signature' onerror="this.style.display='none'" class='clsimgSigCus'/>`;
  let COMPANY_SIGN = `<div id='clientsignatory_div'></div>`;
  let VENDOR_SIGN = `<img id = 'imgVendorSig' src=[[IMG_VENDOR_SIGN]] width = '230px' heigth='100px' alt = 'VendorSignature' onerror="this.style.display='none'" />`;
  let CUSTOMER_SIGN = `<img id = 'imgSig' src=[[IMG_AGENT_SIGN]] width = '230px' heigth='100px' alt = 'Signature' onerror="this.style.display='none'" class='clsimgSig'/>`;

  let full_address = ObjContent.reseller_address;
  if (
    ObjContent.reseller_city &&
    ObjContent.reseller_city !== "" &&
    ObjContent.reseller_city !== "N/A"
  ) {
    full_address += ", " + ObjContent.reseller_city;
  }
  if (
    ObjContent.reseller_state &&
    ObjContent.reseller_state !== "" &&
    ObjContent.reseller_state !== "N/A"
  ) {
    full_address += ", " + ObjContent.reseller_state;
  }
  if (
    ObjContent.reseller_zip &&
    ObjContent.reseller_zip !== "" &&
    ObjContent.reseller_zip !== "N/A"
  ) {
    full_address += ", " + ObjContent.reseller_zip;
  }
  if (
    ObjContent.reseller_country &&
    ObjContent.reseller_country !== "" &&
    ObjContent.reseller_country !== "N/A"
  ) {
    full_address += ", " + ObjContent.reseller_country;
  }

  let _strContent = strContent
    .replace(/\[\[SIGNATORY_DETAILS\]\]/g, "<div id='signatory_div'></div>")
    .replace(
      /\[\[AGENT_SIGNOR_TITLE\]\]/g,
      `<label class='lblAgentTitle'>${ObjContent.resellerCompName}</label>`
    )
    .replace(
      /\[\[CLIENT_SIGNOR_TITLE\]\]/g,
      `<label class='lblAgentTitle'>${ObjContent.resellerCompName}</label>`
    )
    .replace(
      /\[\[AGENT_SIGNOR_NAME\]\]/g,
      `<label class='lblAgentName'>${ObjContent.reseller_name}</label>`
    )
    .replace(
      /\[\[CLIENT_SIGNOR_NAME\]\]/g,
      `<label class='lblAgentName'>${ObjContent.reseller_name}</label>`
    )
    .replace(/\[\[AGENT_COMPANY_NAME\]\]/g, ObjContent.reseller_comp_name)
    .replace(/\[\[CLIENT_COMPANY_NAME\]\]/g, ObjContent.reseller_comp_name)
    .replace(/\[\[CLIENT_SIGNOR_EMAIL\]\]/g, ObjContent.reseller_email)
    .replace(/\[\[CLIENT_SIGNOR_ADDRESS\]\]/g, ObjContent.reseller_address)
    .replace(/\[\[CLIENT_CITY\]\]/g, ObjContent.reseller_city)
    .replace(/\[\[CLIENT_STATE\]\]/g, ObjContent.reseller_state)
    .replace(/\[\[CLIENT_ZIP\]\]/g, ObjContent.reseller_zip)
    .replace(/\[\[CLIENT_COUNTRY\]\]/g, ObjContent.reseller_country)
    .replace(/\[\[CLIENT_PHONE\]\]/g, ObjContent.reseller_phone_no)
    .replace(/\[\[CLIENT_EMAIL\]\]/g, ObjContent.reseller_email)
    .replace(/\[\[CLIENT_ADDRESS\]\]/g, ObjContent.reseller_address)
    .replace(/\[\[CLIENT_FULL_ADDRESS\]\]/g, full_address)
    .replace(/\[\[AGENT_EMAIL\]\]/g, ObjContent.reseller_email)
    .replace(/\[\[AGENT_ADDRESS\]\]/g, ObjContent.agent_address)
    .replace(/\[\[AGENT_PHONENO\]\]/g, ObjContent.reseller_phone_no)
    .replace(/\[\[AGENT_FAX\]\]/g, ObjContent.agent_fax)
    .replace(/\[\[AGENT_SIGN\]\]/g, COMPANY_SIGN)
    .replace(/\[\[CLIENT_SIGN\]\]/g, COMPANY_SIGN)
    .replace(/\[\[CONTRACT_DATE\]\]/g, moment().format("MMMM Do YYYY"))
    .replace(/\[\[VENDOR_SIGNOR_TITLE\]\]/g, ObjContent.vendor_signor_title)
    .replace(/\[\[VENDOR_SIGNOR_NAME\]\]/g, ObjContent.vendor_signor_name)
    .replace(/\[\[VENDOR_SIGNOR_ADDRESS\]\]/g, ObjContent.vendor_signor_address)
    .replace(
      /\[\[VENDOR_SIGNOR_COMPANY_TITLE\]\]/g,
      ObjContent.vendor_signor_comp_title
    )
    .replace(/\[\[VENDOR_SIGNOR_CITY\]\]/g, ObjContent.vendor_signor_city)
    .replace(/\[\[VENDOR_SIGNOR_STATE\]\]/g, ObjContent.vendor_signor_state)
    .replace(/\[\[VENDOR_SIGNOR_ZIP\]\]/g, ObjContent.vendor_signor_zip)
    .replace(
      /\[\[VENDOR_SIGNOR_PHONENO\]\]/g,
      ObjContent.vendor_signor_phone_no
    )
    .replace(/\[\[VENDOR_SIGNOR_FAX\]\]/g, ObjContent.vendor_signor_fax)
    .replace(/\[\[VENDOR_SIGNOR_EMAIL\]\]/g, ObjContent.vendor_signor_email)
    .replace(/\[\[VENDOR_SIGNOR_SIGN\]\]/g, VENDOR_SIGN)
    // .replace(/\[\[VENDOR_SIGNOR_SIGN\]\]/g, "VENDOR SIGNATURE SHOWN HERE")
    .replace(/\[\[VENDOR_CONTRACT_DATE\]\]/g, moment().format("MMMM Do YYYY"))
    .replace(
      /\[\[VENDOR_SIGNOR_COMPANY_NAME\]\]/g,
      ObjContent.vendor_signor_comp_name
    )
    .replace(
      /\[\[VENDOR_SIGNOR_COMPANY\]\]/g,
      ObjContent.vendor_signor_comp_name
    )
    .replace(
      /\[\[VENDOR_SIGNOR_COMPANY_TITLE\]\]/g,
      ObjContent.vendor_signor_comp_name
    )
    .replace(/\[\[AGENT_COMPANY_NAME\]\]/g, ObjContent.reseller_comp_name)
    .replace(/\[\[DEF_DID_CHARGES\]\]/g, ObjContent.def_did_charges)
    .replace(/\[\[DEF_TFN_CHARGES\]\]/g, ObjContent.def_tfn_charges)
    .replace(/\[\[DEF_TERMINATION_CHARGES\]\]/g, ObjContent.def_term_charges)
    .replace(
      /\[\[DEF_LIVE_AGENT_CHARGES\]\]/g,
      ObjContent.def_live_agent_charges
    )
    .replace(/\[\[DEF_IVR_CHARGES\]\]/g, ObjContent.def_ivr_charges)
    .replace(/\[\[DEF_SMS_CHARGES\]\]/g, ObjContent.def_sms_charges)
    .replace(/\[\[DEF_SMS_RATES\]\]/g, ObjContent.def_sms_rates)
    .replace(/\[\[DEF_DID_RATES\]\]/g, ObjContent.def_did_rates)
    .replace(/\[\[DEF_TFN_RATES\]\]/g, ObjContent.def_tfn_rates)
    .replace(/\[\[DEF_TERM_RATES\]\]/g, ObjContent.def_term_rates)
    .replace(/\[\[DEF_LA_RATES\]\]/g, ObjContent.def_la_rates)
    .replace(/\[\[DEF_IVR_RATES\]\]/g, ObjContent.def_ivr_rates)
    .replace(/\[\[DEF_DID_NRC\]\]/g, ObjContent.def_did_nrc)
    .replace(/\[\[DEF_TFN_NRC\]\]/g, ObjContent.def_tfn_nrc)
    .replace(/\[\[AGENT_ADDRESS\]\]/g, ObjContent.agent_address)
    .replace(
      /\[\[INPUT_LABEL_({[^}]+})\]\]/g,
      (match, label) => `<label>${label.substring(1, label.length - 1)}</label>`
    )
    .replace(
      /\[\[INPUT_TEXTFIELD\]\]/g,
      '<input class="agreement_input_textfield" type="text" onBlur="handleReplaceContentClientInput(this, event)" />'
    )
    .replace(/\[\[INPUT_TEXTFIELD_({[^}]+})\]\]/g, (match, defaultText) => {
      const defaultValue = defaultText.substring(1, defaultText.length - 1);
      return `<input type="text" class="agreement_input_textfield" value="${defaultValue}" onBlur="handleReplaceContentClientInput(this, event)" />`;
    })
    .replace(
      /\[\[INPUT_TEXTAREA\]\]/g,
      '<textarea class="agreement_input_textarea" onBlur="handleReplaceContentClientInput(this, event)"></textarea>'
    )
    .replace(/\[\[INPUT_TEXTAREA_({[^}]+})\]\]/g, (match, defaultText) => {
      const defaultValue = defaultText.substring(1, defaultText.length - 1);
      return `<textarea class="agreement_input_textarea" onBlur="handleReplaceContentClientInput(this, event)">${defaultValue}</textarea>`;
    })
    .replace(
      /\[\[INPUT_CHECKBOX_OPTION_({[^}]+})\]\]/g,
      (match, optionText) => {
        const optionValue = optionText.substring(1, optionText.length - 1);
        return `<input class="agreement_input_checkbox" type="checkbox" value="${optionValue}" onChange="handleReplaceContentClientInput(this, event)" />${optionValue}`;
      }
    )
    .replace(
      /\[\[INPUT_CHECKBOX_CHECKED_OPTION_({[^}]+})\]\]/g,
      (match, optionText) => {
        const optionValue = optionText.substring(1, optionText.length - 1);
        return `<input class="agreement_input_checkbox" type="checkbox" value="${optionValue}" checked onChange="handleReplaceContentClientInput(this, event)" />${optionValue}`;
      }
    )
    .replace(/\[\[INPUT_DROPDOWN_([^[\]]+)\]\]/g, (match, optionsString) => {
      const options = optionsString.split("+");
      let dropdownHtml = `<select class="agreement_input_dropdown" onchange='handleReplaceContentClientInput(this, event)'>`;
      options.forEach((option) => {
        const selected = option.trim().endsWith("(selected)");
        const optionValue = selected
          ? option.trim().slice(0, -10)
          : option.trim();
        dropdownHtml += `<option value="${optionValue}"${
          selected ? " selected" : ""
        }>${optionValue}</option>`;
      });
      dropdownHtml += "</select>";
      return dropdownHtml;
    })
    .replace(/\[\[INPUT_RADIO_([^[\]]+)\]\]/g, (match, optionsString) => {
      const options = optionsString.split("+");
      let radioHtml = "";
      const groupName = `radioGroup_${Math.random().toString(36).substr(2, 9)}`;
      options.forEach((option) => {
        const selected = option.trim().endsWith("(selected)");
        const optionValue = selected
          ? option.trim().slice(0, -10)
          : option.trim();
        radioHtml += `<input class="agreement_input_radio" type="radio" name="${groupName}" value="${optionValue}"${
          selected ? " checked" : ""
        } onChange="handleReplaceContentClientInput(this, event)">${optionValue}<br>`;
      });
      return radioHtml;
    });

  return CreateControl(_strContent);
};

export const REPLACECONTENTCLIENT = (strContent, ObjContent, date) => {
  //let COMPANY_SIGN = `<img src=[[CLIENT_SIGN]] width = '230px' heigth='100px' alt = 'Signature' onerror="this.style.display='none'" />`;
  let COMPANY_SIGN = `<div id='clientsignatory_div'></div>`;
  let VENDOR_SIGN = `<img id = 'imgVendorSig' src=[[IMG_VENDOR_SIGN]] width = '230px' heigth='100px' alt = 'VendorSignature' onerror="this.style.display='none'" />`;
  let CUSTOMER_SIGN = `<img id = 'imgSig' src=[[IMG_AGENT_SIGN]] width = '230px' heigth='100px' alt = 'Signature' onerror="this.style.display='none'" class='clsimgSig'/>`;

  let full_address = ObjContent.client_address;
  if (
    ObjContent.client_city &&
    ObjContent.client_city !== "" &&
    ObjContent.client_city !== "N/A"
  ) {
    full_address += ", " + ObjContent.client_city;
  }
  if (
    ObjContent.client_state &&
    ObjContent.client_state !== "" &&
    ObjContent.client_state !== "N/A"
  ) {
    full_address += ", " + ObjContent.client_state;
  }
  if (
    ObjContent.client_zip &&
    ObjContent.client_zip !== "" &&
    ObjContent.client_zip !== "N/A"
  ) {
    full_address += ", " + ObjContent.client_zip;
  }
  if (
    ObjContent.client_country &&
    ObjContent.client_country !== "" &&
    ObjContent.client_country !== "N/A"
  ) {
    full_address += ", " + ObjContent.client_country;
  }
  let client_title = "";
  if (
    ObjContent.client_title &&
    ObjContent.client_title !== "" &&
    ObjContent.client_title !== "N/A"
  ) {
    client_title = ObjContent.client_title;
  }

  let _strContent = strContent
  .replace(/\[\[SIGNATORY_DETAILS\]\]/g, "<div id='signatory_div'></div>")
    .replace(
      /\[\[AGENT_SIGNOR_TITLE\]\]/g,
      `<label class='lblAgentTitle'>${ObjContent.resellerCompName}</label>`
    )
    .replace(
      /\[\[CLIENT_SIGNOR_TITLE\]\]/g,
      `<label class='lblAgentTitle'>${client_title}</label>`
    )
    .replace(
      /\[\[AGENT_SIGNOR_NAME\]\]/g,
      `<label class='lblAgentName'>${ObjContent.client_name}</label>`
    )
    .replace(
      /\[\[CLIENT_SIGNOR_NAME\]\]/g,
      `<label class='lblAgentName'>${ObjContent.client_name}</label>`
    )
    .replace(/\[\[AGENT_COMPANY_NAME\]\]/g, ObjContent.client_comp_name)
    .replace(/\[\[CLIENT_COMPANY_NAME\]\]/g, ObjContent.client_comp_name)
    .replace(/\[\[CLIENT_SIGNOR_EMAIL\]\]/g, ObjContent.client_email)
    .replace(/\[\[CLIENT_EMAIL\]\]/g, ObjContent.client_email)
    .replace(/\[\[CLIENT_PHONE\]\]/g, ObjContent.client_phone_no)
    .replace(/\[\[CLIENT_SIGNOR_ADDRESS\]\]/g, ObjContent.client_address)
    .replace(/\[\[CLIENT_ADDRESS\]\]/g, ObjContent.client_address)
    .replace(/\[\[CLIENT_CITY\]\]/g, ObjContent.client_city)
    .replace(/\[\[CLIENT_STATE\]\]/g, ObjContent.client_state)
    .replace(/\[\[CLIENT_SIGNOR_STATE\]\]/g, ObjContent.client_state)
    .replace(/\[\[CLIENT_ZIP\]\]/g, ObjContent.client_zip)
    .replace(/\[\[CLIENT_COUNTRY\]\]/g, ObjContent.client_country)
    .replace(/\[\[CLIENT_FULL_ADDRESS\]\]/g, full_address)
    .replace(/\[\[AGENT_EMAIL\]\]/g, ObjContent.client_email)
    .replace(/\[\[AGENT_ADDRESS\]\]/g, ObjContent.agent_address)
    .replace(/\[\[AGENT_PHONENO\]\]/g, ObjContent.client_phone_no)
    .replace(/\[\[AGENT_FAX\]\]/g, ObjContent.agent_fax)
    .replace(/\[\[AGENT_SIGN\]\]/g, CUSTOMER_SIGN)
    .replace(/\[\[CLIENT_SIGN\]\]/g, COMPANY_SIGN)
    .replace(/\[\[CONTRACT_DATE\]\]/g, moment().format("MMMM Do YYYY"))
    .replace(/\[\[VENDOR_SIGNOR_TITLE\]\]/g, ObjContent.vendor_signor_title)
    .replace(/\[\[VENDOR_SIGNOR_NAME\]\]/g, ObjContent.vendor_signor_name)
    .replace(/\[\[VENDOR_SIGNOR_ADDRESS\]\]/g, ObjContent.vendor_signor_address)
    .replace(
      /\[\[VENDOR_SIGNOR_COMPANY_TITLE\]\]/g,
      ObjContent.vendor_signor_comp_title
    )
    .replace(/\[\[VENDOR_SIGNOR_CITY\]\]/g, ObjContent.vendor_signor_city)
    .replace(/\[\[VENDOR_SIGNOR_STATE\]\]/g, ObjContent.vendor_signor_state)
    .replace(/\[\[VENDOR_SIGNOR_ZIP\]\]/g, ObjContent.vendor_signor_zip)
    .replace(
      /\[\[VENDOR_SIGNOR_PHONENO\]\]/g,
      ObjContent.vendor_signor_phone_no
    )
    .replace(/\[\[VENDOR_SIGNOR_FAX\]\]/g, ObjContent.vendor_signor_fax)
    .replace(/\[\[VENDOR_SIGNOR_EMAIL\]\]/g, ObjContent.vendor_signor_email)
    .replace(/\[\[VENDOR_SIGNOR_SIGN\]\]/g, VENDOR_SIGN) // .replace(/\[\[VENDOR_SIGNOR_SIGN\]\]/g, "VENDOR SIGNATURE SHOWN HERE")
    .replace(/\[\[VENDOR_CONTRACT_DATE\]\]/g, moment().format("MMMM Do YYYY"))
    .replace(
      /\[\[VENDOR_SIGNOR_COMPANY_NAME\]\]/g,
      ObjContent.vendor_signor_comp_name
    )
    .replace(
      /\[\[VENDOR_SIGNOR_COMPANY\]\]/g,
      ObjContent.vendor_signor_comp_name
    )
    .replace(
      /\[\[VENDOR_SIGNOR_COMPANY_TITLE\]\]/g,
      ObjContent.vendor_signor_comp_name
    )
    .replace(/\[\[AGENT_COMPANY_NAME\]\]/g, ObjContent.reseller_comp_name)
    .replace(/\[\[DEF_DID_CHARGES\]\]/g, ObjContent.def_did_charges)
    .replace(/\[\[DEF_TFN_CHARGES\]\]/g, ObjContent.def_tfn_charges)
    .replace(/\[\[DEF_TERMINATION_CHARGES\]\]/g, ObjContent.def_term_charges)
    .replace(
      /\[\[DEF_LIVE_AGENT_CHARGES\]\]/g,
      ObjContent.def_live_agent_charges
    )
    .replace(/\[\[DEF_IVR_CHARGES\]\]/g, ObjContent.def_ivr_charges)
    .replace(/\[\[DEF_SMS_CHARGES\]\]/g, ObjContent.def_sms_charges)
    .replace(/\[\[DEF_SMS_RATES\]\]/g, ObjContent.def_sms_rates)
    .replace(/\[\[DEF_DID_RATES\]\]/g, ObjContent.def_did_rates)
    .replace(/\[\[DEF_TFN_RATES\]\]/g, ObjContent.def_tfn_rates)
    .replace(/\[\[DEF_TERM_RATES\]\]/g, ObjContent.def_term_rates)
    .replace(/\[\[DEF_LA_RATES\]\]/g, ObjContent.def_la_rates)
    .replace(/\[\[DEF_IVR_RATES\]\]/g, ObjContent.def_ivr_rates)
    .replace(/\[\[DEF_DID_NRC\]\]/g, ObjContent.def_did_nrc)
    .replace(/\[\[DEF_TFN_NRC\]\]/g, ObjContent.def_tfn_nrc)
    .replace(/\[\[AGENT_ADDRESS\]\]/g, ObjContent.agent_address)
    .replace(
      /\[\[INPUT_LABEL_({[^}]+})\]\]/g,
      (match, label) => `<label>${label.substring(1, label.length - 1)}</label>`
    )
    .replace(
      /\[\[INPUT_TEXTFIELD\]\]/g,
      '<input class="agreement_input_textfield" type="text" onBlur="handleReplaceContentClientInput(this, event)" />'
    )
    .replace(/\[\[INPUT_TEXTFIELD_({[^}]+})\]\]/g, (match, defaultText) => {
      const defaultValue = defaultText.substring(1, defaultText.length - 1);
      return `<input type="text" class="agreement_input_textfield" value="${defaultValue}" onBlur="handleReplaceContentClientInput(this, event)" />`;
    })
    .replace(
      /\[\[INPUT_TEXTAREA\]\]/g,
      '<textarea class="agreement_input_textarea" onBlur="handleReplaceContentClientInput(this, event)"></textarea>'
    )
    .replace(/\[\[INPUT_TEXTAREA_({[^}]+})\]\]/g, (match, defaultText) => {
      const defaultValue = defaultText.substring(1, defaultText.length - 1);
      return `<textarea class="agreement_input_textarea" onBlur="handleReplaceContentClientInput(this, event)">${defaultValue}</textarea>`;
    })
    .replace(
      /\[\[INPUT_CHECKBOX_OPTION_({[^}]+})\]\]/g,
      (match, optionText) => {
        const optionValue = optionText.substring(1, optionText.length - 1);
        return `<input class="agreement_input_checkbox" type="checkbox" value="${optionValue}" onChange="handleReplaceContentClientInput(this, event)" />${optionValue}`;
      }
    )
    .replace(
      /\[\[INPUT_CHECKBOX_CHECKED_OPTION_({[^}]+})\]\]/g,
      (match, optionText) => {
        const optionValue = optionText.substring(1, optionText.length - 1);
        return `<input class="agreement_input_checkbox" type="checkbox" value="${optionValue}" checked onChange="handleReplaceContentClientInput(this, event)" />${optionValue}`;
      }
    )
    .replace(/\[\[INPUT_DROPDOWN_([^[\]]+)\]\]/g, (match, optionsString) => {
      const options = optionsString.split("+");
      let dropdownHtml = `<select class="agreement_input_dropdown" onchange='handleReplaceContentClientInput(this, event)'>`;
      options.forEach((option) => {
        const selected = option.trim().endsWith("(selected)");
        const optionValue = selected
          ? option.trim().slice(0, -10)
          : option.trim();
        dropdownHtml += `<option value="${optionValue}"${
          selected ? " selected" : ""
        }>${optionValue}</option>`;
      });
      dropdownHtml += "</select>";
      return dropdownHtml;
    })
    .replace(/\[\[INPUT_RADIO_([^[\]]+)\]\]/g, (match, optionsString) => {
      const options = optionsString.split("+");
      let radioHtml = "";
      const groupName = `radioGroup_${Math.random().toString(36).substr(2, 9)}`;
      options.forEach((option) => {
        const selected = option.trim().endsWith("(selected)");
        const optionValue = selected
          ? option.trim().slice(0, -10)
          : option.trim();
        radioHtml += `<input class="agreement_input_radio" type="radio" name="${groupName}" value="${optionValue}"${
          selected ? " checked" : ""
        } onChange="handleReplaceContentClientInput(this, event)">${optionValue}<br>`;
      });
      return radioHtml;
    });

  return CreateControl(_strContent);
};

window.handleReplaceContentClientInput = (inputElement, event) => {
  let newValue;
  console.log("handleReplaceContentClientInput: ", inputElement);
  if (inputElement.tagName === "INPUT") {
    if (inputElement.type === "checkbox") {
      newValue = inputElement.checked;
      if (newValue) {
        inputElement.setAttribute("checked", "checked");
      } else {
        inputElement.removeAttribute("checked");
      }
    } else if (inputElement.type === "radio") {
      if (inputElement.checked) {
        const radioGroupName = inputElement.getAttribute("name");
        const radioButtons = document.querySelectorAll(
          `input[type="radio"][name="${radioGroupName}"]`
        );
        radioButtons.forEach((radioButton) => {
          if (radioButton === inputElement) {
            radioButton.setAttribute("checked", "checked");
          } else {
            radioButton.removeAttribute("checked");
          }
        });
      }
    } else {
      newValue = inputElement.value;
      inputElement.setAttribute("value", newValue);
    }
  } else if (inputElement.tagName === "TEXTAREA") {
    newValue = inputElement.value;
    inputElement.innerHTML = newValue;
  } else if (inputElement.tagName === "SELECT") {
    newValue = inputElement.value;
    console.log("newValue: ", newValue, ", inputElement: ", inputElement, ", ");
    const options = inputElement.querySelectorAll("option");
    options.forEach((option) => {
      if (option.value === newValue) {
        option.setAttribute("selected", "true");
      } else {
        option.removeAttribute("selected");
      }
    });
  }
  console.log("handleReplaceContentClientInput newValue: ", newValue);
};

export const REPLACECONTENTCLIENT_old = (strContent, ObjContent, date) => {
  let COMPANY_SIGN = `<img id = 'imgSigCust' src=[[CLIENT_SIGN]] width = '230px' heigth='100px' alt = 'Signature' onerror="this.style.display='none'" class='clsimgSigCus'/>`;
  let VENDOR_SIGN = `<img id = 'imgVendorSig' src=[[IMG_VENDOR_SIGN]] width = '230px' heigth='100px' alt = 'VendorSignature' onerror="this.style.display='none'" />`;
  let CUSTOMER_SIGN = `<img id = 'imgSig' src=[[IMG_AGENT_SIGN]] width = '230px' heigth='100px' alt = 'Signature' onerror="this.style.display='none'" class='clsimgSig'/>`;

  let full_address = ObjContent.client_address;
  if(ObjContent.client_city && ObjContent.client_city !== "" && ObjContent.client_city !== "N/A"){
    full_address += ", " + ObjContent.client_city;
  }
  if(ObjContent.client_state && ObjContent.client_state !== "" && ObjContent.client_state !== "N/A"){
    full_address += ", " + ObjContent.client_state;
  }
  if(ObjContent.client_zip && ObjContent.client_zip !== "" && ObjContent.client_zip !== "N/A"){
    full_address += ", " + ObjContent.client_zip;
  }
  if(ObjContent.client_country && ObjContent.client_country !== "" && ObjContent.client_country !== "N/A"){
    full_address += ", " + ObjContent.client_country;
  }

  let _strContent = strContent
    .replace(/\[\[SIGNATORY_DETAILS\]\]/g, "<div id='signatory_div'></div>")
    .replace(
      /\[\[AGENT_SIGNOR_TITLE\]\]/g,
      `<label class='lblAgentTitle'>${ObjContent.resellerCompName}</label>`
    )
    .replace(
      /\[\[CLIENT_SIGNOR_TITLE\]\]/g,
      `<label class='lblAgentTitle'>${ObjContent.client_title}</label>`
    )
    .replace(
      /\[\[AGENT_SIGNOR_NAME\]\]/g,
      `<label class='lblAgentName'>${ObjContent.client_name}</label>`
    )
    .replace(
      /\[\[CLIENT_SIGNOR_NAME\]\]/g,
      `<label class='lblAgentName'>${ObjContent.client_name}</label>`
    )
    .replace(/\[\[AGENT_COMPANY_NAME\]\]/g, ObjContent.client_comp_name)
    .replace(/\[\[CLIENT_COMPANY_NAME\]\]/g, ObjContent.client_comp_name)
    .replace(/\[\[CLIENT_SIGNOR_EMAIL\]\]/g, ObjContent.client_email)
    .replace(/\[\[CLIENT_EMAIL\]\]/g, ObjContent.client_email)
    .replace(/\[\[CLIENT_SIGNOR_ADDRESS\]\]/g, ObjContent.client_address)
    .replace(/\[\[CLIENT_SIGNOR_CITY\]\]/g, ObjContent.client_city)
    .replace(/\[\[CLIENT_SIGNOR_STATE\]\]/g, ObjContent.client_state)
    .replace(/\[\[CLIENT_SIGNOR_ZIP\]\]/g, ObjContent.client_zip)
    .replace(/\[\[CLIENT_FULL_ADDRESS\]\]/g, full_address)
    .replace(/\[\[AGENT_EMAIL\]\]/g, ObjContent.client_email)
    .replace(/\[\[AGENT_ADDRESS\]\]/g, ObjContent.agent_address)
    .replace(/\[\[AGENT_PHONENO\]\]/g, ObjContent.client_phone_no)
    .replace(/\[\[AGENT_FAX\]\]/g, ObjContent.agent_fax)
    .replace(/\[\[AGENT_SIGN\]\]/g, COMPANY_SIGN)
    .replace(/\[\[CLIENT_SIGN\]\]/g, CUSTOMER_SIGN)
    .replace(/\[\[CONTRACT_DATE\]\]/g, moment().format("MMMM Do YYYY"))
    .replace(/\[\[VENDOR_SIGNOR_TITLE\]\]/g, ObjContent.vendor_signor_title)
    .replace(/\[\[VENDOR_SIGNOR_NAME\]\]/g, ObjContent.vendor_signor_name)
    .replace(/\[\[VENDOR_SIGNOR_ADDRESS\]\]/g, ObjContent.vendor_signor_address)
    .replace(
      /\[\[VENDOR_SIGNOR_COMPANY_TITLE\]\]/g,
      ObjContent.vendor_signor_comp_title
    )
    .replace(/\[\[VENDOR_SIGNOR_CITY\]\]/g, ObjContent.vendor_signor_city)
    .replace(/\[\[VENDOR_SIGNOR_STATE\]\]/g, ObjContent.vendor_signor_state)
    .replace(/\[\[VENDOR_SIGNOR_ZIP\]\]/g, ObjContent.vendor_signor_zip)
    .replace(
      /\[\[VENDOR_SIGNOR_PHONENO\]\]/g,
      ObjContent.vendor_signor_phone_no
    )
    .replace(/\[\[VENDOR_SIGNOR_FAX\]\]/g, ObjContent.vendor_signor_fax)
    .replace(/\[\[VENDOR_SIGNOR_EMAIL\]\]/g, ObjContent.vendor_signor_email)
    .replace(/\[\[VENDOR_SIGNOR_SIGN\]\]/g, VENDOR_SIGN) // .replace(/\[\[VENDOR_SIGNOR_SIGN\]\]/g, "VENDOR SIGNATURE SHOWN HERE")
    .replace(/\[\[VENDOR_CONTRACT_DATE\]\]/g, moment().format("MMMM Do YYYY"))
    .replace(
      /\[\[VENDOR_SIGNOR_COMPANY_NAME\]\]/g,
      ObjContent.vendor_signor_comp_name
    )
    .replace(
      /\[\[VENDOR_SIGNOR_COMPANY\]\]/g,
      ObjContent.vendor_signor_comp_name
    )
    .replace(
      /\[\[VENDOR_SIGNOR_COMPANY_TITLE\]\]/g,
      ObjContent.vendor_signor_comp_name
    )
    .replace(/\[\[AGENT_COMPANY_NAME\]\]/g, ObjContent.reseller_comp_name)
    .replace(/\[\[DEF_DID_CHARGES\]\]/g, ObjContent.def_did_charges)
    .replace(/\[\[DEF_TFN_CHARGES\]\]/g, ObjContent.def_tfn_charges)
    .replace(/\[\[DEF_TERMINATION_CHARGES\]\]/g, ObjContent.def_term_charges)
    .replace(
      /\[\[DEF_LIVE_AGENT_CHARGES\]\]/g,
      ObjContent.def_live_agent_charges
    )
    .replace(/\[\[DEF_IVR_CHARGES\]\]/g, ObjContent.def_ivr_charges)
    .replace(/\[\[DEF_SMS_CHARGES\]\]/g, ObjContent.def_sms_charges)
    .replace(/\[\[DEF_SMS_RATES\]\]/g, ObjContent.def_sms_rates)
    .replace(/\[\[DEF_DID_RATES\]\]/g, ObjContent.def_did_rates)
    .replace(/\[\[DEF_TFN_RATES\]\]/g, ObjContent.def_tfn_rates)
    .replace(/\[\[DEF_TERM_RATES\]\]/g, ObjContent.def_term_rates)
    .replace(/\[\[DEF_LA_RATES\]\]/g, ObjContent.def_la_rates)
    .replace(/\[\[DEF_IVR_RATES\]\]/g, ObjContent.def_ivr_rates)
    .replace(/\[\[DEF_DID_NRC\]\]/g, ObjContent.def_did_nrc)
    .replace(/\[\[DEF_TFN_NRC\]\]/g, ObjContent.def_tfn_nrc)
    .replace(/\[\[AGENT_ADDRESS\]\]/g, ObjContent.agent_address);

  return CreateControl(_strContent);
};

function CreateControl(strMessageScript) {
  try {
    //====================CREATE BUTTON ======================================
    if (strMessageScript === "") {
      return strMessageScript;
    }
    let listControls = "[";
    $.map(strMessageScript.match(/\[\{(.*?)\}\]/g), function (value) {
      if (value !== "" && value !== null) {
        let ControlTag = value
          .replace(/\[/g, "")
          .replace(/\]/g, "")
          .replace(/&quot;/g, '"');

        let _JSON = JSON.parse(ControlTag);
        var ControlType = _JSON.type;

        if (ControlType === "textbox") {
          listControls += ControlTag + ",";

          let required = "";
          if (_JSON.req === "true") {
            required = "required";
          }
          let _Control_HTML = `<input  type="text" style="${_JSON.style}" class="${_JSON.class}" name="${_JSON.id}" id="${_JSON.id}" ${required} />`;

          strMessageScript = strMessageScript.replace(value, _Control_HTML);
        } else if (ControlType === "textarea") {
          listControls += ControlTag + ",";

          let required = "";
          if (_JSON.req === "true") {
            required = "required";
          }
          let _Control_HTML = `<textarea  name="${_JSON.id}" id="${_JSON.id}" class="${_JSON.class}"  style="${_JSON.style}" ${required} ></textarea>`;

          strMessageScript = strMessageScript.replace(value, _Control_HTML);
        } else if (ControlType === "radio") {
          listControls += ControlTag + ",";

          let radioData = _JSON.data.split(",");
          console.log("radioData", radioData[0]);
          let required = "";
          if (_JSON.req === "true") {
            required = "required";
          }
          let _Control_HTML = `<input type="radio" value="${radioData[0]}"  name="${_JSON.name}" id="${_JSON.id}" class="${_JSON.class}"  style="${_JSON.style}" ${required} />`;

          strMessageScript = strMessageScript.replace(value, _Control_HTML);
        } else if (ControlType === "checkbox") {
          listControls += ControlTag + ",";
          let checkboxData = _JSON.data.split(",");

          let _Control_HTML = '<table style="width:100%"><tr>';

          for (let index = 0; index < checkboxData.length; index++) {
            let checkAll = "";
            let checkExemption = "";
            let _Control_ID = "";
            if (
              checkboxData[index].replace(/&#39;/g, "").toLowerCase() ===
              "all of the above"
            ) {
              checkAll = `onClick="checkAll('${_JSON.id}','${
                _JSON.id
              }-${checkboxData[index].replace(/&#39;/g, "")}')"`;
              _Control_ID = `${_JSON.id}-${checkboxData[index].replace(
                /&#39;/g,
                ""
              )}`;
            } else {
              checkExemption = `onClick="checkExemption('${_JSON.id}','clsExcemtion${index}')"`;
              _Control_ID = _JSON.id;
            }

            let labelText =
              _JSON.showtitle.toLowerCase() === "yes"
                ? checkboxData[index].replace(/&#39;/g, "")
                : "";
            if (checkExemption !== "") {
              let required = "";
              if (_JSON.req === "true") {
                required = "required";
              }
              _Control_HTML += `<td><label style='margin:10px'><input style='margin:5px' ${checkExemption}  type="checkbox" name="${_Control_ID}" class="clsExcemtion${index}" id="${_Control_ID}" ${required} value="${checkboxData[
                index
              ].replace(/&#39;/g, "")}"  />
            ${labelText}
            </label></td>`;
            } else {
              _Control_HTML += `<td><label style='margin:10px'><input style='margin:5px'  ${checkAll} type="checkbox" name="${_Control_ID}" id="${_Control_ID}" value="${checkboxData[
                index
              ].replace(/&#39;/g, "")}"  />
            ${labelText}
            </label></td>`;
            }
            if (index % 2) {
              _Control_HTML += "</tr><tr>";
            }
          }
          _Control_HTML += "</table>";

          strMessageScript = strMessageScript.replace(value, _Control_HTML);
        }
      }
    });
    listControls = listControls.replace(/,\s*$/, "") + "]";

    strMessageScript += `<input type='hidden' id='hdlistControl' value='${listControls}' />`;
    return strMessageScript;
  } catch (error) {
    return strMessageScript;
  }
}
